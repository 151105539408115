export const inventoryTypes = {
    ADD_SINGLE_PRODUCT_REQUEST: "ADD_SINGLE_PRODUCT_REQUEST",
    ADD_SINGLE_PRODUCT_SUCCESS: "ADD_SINGLE_PRODUCT_SUCCESS",
    ADD_SINGLE_PRODUCT_FAILURE: "ADD_SINGLE_PRODUCT_FAILURE",
    UPDATE_SINGLE_PRODUCT_REQUEST: "UPDATE_SINGLE_PRODUCT_REQUEST",
    UPDATE_SINGLE_PRODUCT_SUCCESS: "UPDATE_SINGLE_PRODUCT_SUCCESS",
    UPDATE_SINGLE_PRODUCT_FAILURE: "UPDATE_SINGLE_PRODUCT_FAILURE",
    ADD_MULTIPLE_PRODUCTS_REQUEST: "ADD_MULTIPLE_PRODUCTS_REQUEST",
    ADD_MULTIPLE_PRODUCTS_SUCCESS: "ADD_MULTIPLE_PRODUCTS_SUCCESS",
    ADD_MULTIPLE_PRODUCTS_FAILURE: "ADD_MULTIPLE_PRODUCTS_FAILURE",
    FETCH_SINGLE_INVENTORY_REQUEST: "FETCH_SINGLE_INVENTORY_REQUEST",
    FETCH_SINGLE_INVENTORY_SUCCESS: "FETCH_SINGLE_INVENTORY_SUCCESS",
    FETCH_SINGLE_INVENTORY_FAILURE: "FETCH_SINGLE_INVENTORY_FAILURE",
    FETCH_GLOBAL_PRODUCT_LIST_REQUEST: "FETCH_GLOBAL_PRODUCT_LIST_REQUEST",
    FETCH_GLOBAL_PRODUCT_LIST_SUCCESS: "FETCH_GLOBAL_PRODUCT_LIST_SUCCESS",
    FETCH_GLOBAL_PRODUCT_LIST_FAILURE: "FETCH_GLOBAL_PRODUCT_LIST_FAILURE",
    FETCH_GLOBAL_PRODUCT_SALES_REQUEST: "FETCH_GLOBAL_PRODUCT_SALES_REQUEST",
    FETCH_GLOBAL_PRODUCT_SALES_SUCCESS: "FETCH_GLOBAL_PRODUCT_SALES_SUCCESS",
    FETCH_GLOBAL_PRODUCT_SALES_FAILURE: "FETCH_GLOBAL_PRODUCT_SALES_FAILURE",
    FETCH_GLOBAL_PRODUCT_LIST_DROPDOWN_REQUEST: "FETCH_GLOBAL_PRODUCT_LIST_DROPDOWN_REQUEST",
    FETCH_GLOBAL_PRODUCT_LIST_DROPDOWN_SUCCESS: "FETCH_GLOBAL_PRODUCT_LIST_DROPDOWN_SUCCESS",
    FETCH_GLOBAL_PRODUCT_LIST_DROPDOWN_FAILURE: "FETCH_GLOBAL_PRODUCT_LIST_DROPDOWN_FAILURE",
    FETCH_STOCK_LIST_REQUEST: "FETCH_STOCK_LIST_REQUEST",
    FETCH_STOCK_LIST_SUCCESS: "FETCH_STOCK_LIST_SUCCESS",
    FETCH_STOCK_LIST_FAILURE: "FETCH_STOCK_LIST_FAILURE",
    ADD_PRODUCT_TO_STOCK_COUNT_REQUEST: "ADD_PRODUCT_TO_STOCK_COUNT_REQUEST",
    ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS: "ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS",
    ADD_PRODUCT_TO_STOCK_COUNT_FAILURE: "ADD_PRODUCT_TO_STOCK_COUNT_FAILURE",
    ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST: "ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST",
    ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS: "ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS",
    ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE: "ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE",
    FETCH_WAREHOUSE_LIST_REQUEST: "FETCH_WAREHOUSE_LIST_REQUEST",
    FETCH_WAREHOUSE_LIST_SUCCESS: "FETCH_WAREHOUSE_LIST_SUCCESS",
    FETCH_WAREHOUSE_LIST_FAILURE: "FETCH_WAREHOUSE_LIST_FAILURE",
    FETCH_WAREHOUSE_SEARCH_LIST_REQUEST: "FETCH_WAREHOUSE_SEARCH_LIST_REQUEST",
    FETCH_WAREHOUSE_SEARCH_LIST_SUCCESS: "FETCH_WAREHOUSE_SEARCH_LIST_SUCCESS",
    FETCH_WAREHOUSE_SEARCH_LIST_FAILURE: "FETCH_WAREHOUSE_SEARCH_LIST_FAILURE",
    FETCH_LIST_STOCK_WAREHOUSE_REQUEST: "FETCH_LIST_STOCK_WAREHOUSE_REQUEST",
    FETCH_LIST_STOCK_WAREHOUSE_SUCCESS: "FETCH_LIST_STOCK_WAREHOUSE_SUCCESS",
    FETCH_LIST_STOCK_WAREHOUSE_FAILURE: "FETCH_LIST_STOCK_WAREHOUSE_FAILURE",
    FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST: "FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST",
    FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS: "FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS",
    FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE: "FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE",
    FETCH_SINGLE_WAREHOUSE_LIST_REQUEST: "FETCH_SINGLE_WAREHOUSE_LIST_REQUEST",
    FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS: "FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS",
    FETCH_SINGLE_WAREHOUSE_LIST_FAILURE: "FETCH_SINGLE_WAREHOUSE_LIST_FAILURE",
    CREATE_WAREHOUSE_REQUEST: "CREATE_WAREHOUSE_REQUEST",
    CREATE_WAREHOUSE_SUCCESS: "CREATE_WAREHOUSE_SUCCESS",
    CREATE_WAREHOUSE_FAILURE: "CREATE_WAREHOUSE_FAILURE",
    UPDATE_WAREHOUSE_REQUEST: "UPDATE_WAREHOUSE_REQUEST",
    UPDATE_WAREHOUSE_SUCCESS: "UPDATE_WAREHOUSE_SUCCESS",
    UPDATE_WAREHOUSE_FAILURE: "UPDATE_WAREHOUSE_FAILURE",
    DELETE_WAREHOUSE_REQUEST: "DELETE_WAREHOUSE_REQUEST",
    DELETE_WAREHOUSE_SUCCESS: "DELETE_WAREHOUSE_SUCCESS",
    DELETE_WAREHOUSE_FAILURE: "DELETE_WAREHOUSE_FAILURE",
    FETCH_SEARCH_INVENTORY_LISTING_REQUEST: "FETCH_SEARCH_INVENTORY_LISTING_REQUEST",
    FETCH_SEARCH_INVENTORY_LISTING_SUCCESS: "FETCH_SEARCH_INVENTORY_LISTING_SUCCESS",
    FETCH_SEARCH_INVENTORY_LISTING_FAILURE: "FETCH_SEARCH_INVENTORY_LISTING_FAILURE",
    FETCH_WAREHOUSES_REQUEST: "FETCH_WAREHOUSES_REQUEST",
    FETCH_WAREHOUSES_SUCCESS: "FETCH_WAREHOUSES_SUCCESS",
    FETCH_WAREHOUSES_FAILURE: "FETCH_WAREHOUSES_FAILURE",
    FETCH_INVENTORY_LIST_REQUEST: "FETCH_INVENTORY_LIST_REQUEST",
    FETCH_INVENTORY_LIST_SUCCESS: "FETCH_INVENTORY_LIST_SUCCESS",
    FETCH_INVENTORY_LIST_FAILURE: "FETCH_INVENTORY_LIST_FAILURE",
    FETCH_INVENTORY_LIST_NO_VARIANT_REQUEST: "FETCH_INVENTORY_LIST_NO_VARIANT_REQUEST",
    FETCH_INVENTORY_LIST_NO_VARIANT_SUCCESS: "FETCH_INVENTORY_LIST_NO_VARIANT_SUCCESS",
    FETCH_INVENTORY_LIST_NO_VARIANT_FAILURE: "FETCH_INVENTORY_LIST_NO_VARIANT_FAILURE",
    FETCH_WAREHOUSE_GLOBAL_PRODUCT_REQUEST: "FETCH_WAREHOUSE_GLOBAL_PRODUCT_REQUEST",
    FETCH_WAREHOUSE_GLOBAL_PRODUCT_SUCCESS: "FETCH_WAREHOUSE_GLOBAL_PRODUCT_SUCCESS",
    FETCH_WAREHOUSE_GLOBAL_PRODUCT_FAILURE: "FETCH_WAREHOUSE_GLOBAL_PRODUCT_FAILURE",
    FETCH_TRANSFER_STOCKS_REQUEST: "FETCH_TRANSFER_STOCKS_REQUEST",
    FETCH_TRANSFER_STOCKS_SUCCESS: "FETCH_TRANSFER_STOCKS_SUCCESS",
    FETCH_TRANSFER_STOCKS_FAILURE: "FETCH_TRANSFER_STOCKS_FAILURE",
    TRANSFER_STOCKS_REQUEST: "TRANSFER_STOCKS_REQUEST",
    TRANSFER_STOCKS_SUCCESS: "TRANSFER_STOCKS_SUCCESS",
    TRANSFER_STOCKS_FAILURE: "TRANSFER_STOCKS_FAILURE",
    TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST: "TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST",
    TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS: "TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS",
    TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE: "TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE",
    FETCH_SEARCH_PRODUCT_LISTING_REQUEST: "FETCH_SEARCH_PRODUCT_LISTING_REQUEST",
    FETCH_SEARCH_PRODUCT_LISTING_SUCCESS: "FETCH_SEARCH_PRODUCT_LISTING_SUCCESS",
    FETCH_SEARCH_PRODUCT_LISTING_FAILURE: "FETCH_SEARCH_PRODUCT_LISTING_FAILURE",
    FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST: "FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST",
    FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS: "FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS",
    FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE: "FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE",
    FETCH_INVENTORY_HISTORY_REQUEST: "FETCH_INVENTORY_HISTORY_REQUEST",
    FETCH_INVENTORY_HISTORY_SUCCESS: "FETCH_INVENTORY_HISTORY_SUCCESS",
    FETCH_INVENTORY_HISTORY_FAILURE: "FETCH_INVENTORY_HISTORY_FAILURE",
    FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST: "FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST",
    FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS: "FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS",
    FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE: "FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE",
    FETCH_INVENTORY_WAREHOUSE_REQUEST: "FETCH_INVENTORY_WAREHOUSE_REQUEST",
    FETCH_INVENTORY_WAREHOUSE_SUCCESS: "FETCH_INVENTORY_WAREHOUSE_SUCCESS",
    FETCH_INVENTORY_WAREHOUSE_FAILURE: "FETCH_INVENTORY_WAREHOUSE_FAILURE",
    FETCH_INVENTORY_ORDERS_REQUEST: "FETCH_INVENTORY_ORDERS_REQUEST",
    FETCH_INVENTORY_ORDERS_SUCCESS: "FETCH_INVENTORY_ORDERS_SUCCESS",
    FETCH_INVENTORY_ORDERS_FAILURE: "FETCH_INVENTORY_ORDERS_FAILURE",
    FETCH_SINGLE_STOCK_REQUEST: "FETCH_SINGLE_STOCK_REQUEST",
    FETCH_SINGLE_STOCK_SUCCESS: "FETCH_SINGLE_STOCK_SUCCESS",
    FETCH_SINGLE_STOCK_FAILURE: "FETCH_SINGLE_STOCK_FAILURE",
    UPDATE_STOCK_REQUEST: "UPDATE_STOCK_REQUEST",
    UPDATE_STOCK_SUCCESS: "UPDATE_STOCK_SUCCESS",
    UPDATE_STOCK_FAILURE: "UPDATE_STOCK_FAILURE",
    FETCH_SINGLE_TRANSFER_STOCK_REQUEST: "FETCH_SINGLE_TRANSFER_STOCK_REQUEST",
    FETCH_SINGLE_TRANSFER_STOCK_SUCCESS: "FETCH_SINGLE_TRANSFER_STOCK_SUCCESS",
    FETCH_SINGLE_TRANSFER_STOCK_FAILURE: "FETCH_SINGLE_TRANSFER_STOCK_FAILURE",
    FETCH_STOCK_ADJUSTMENT_LIST_REQUEST: "FETCH_STOCK_ADJUSTMENT_LIST_REQUEST",
    FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS: "FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS",
    FETCH_STOCK_ADJUSTMENT_LIST_FAILURE: "FETCH_STOCK_ADJUSTMENT_LIST_FAILURE",
    FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST: "FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST",
    FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS: "FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS",
    FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE: "FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE",
    ADD_NEW_STOCK_ADJUSTMENT_REQUEST: "ADD_NEW_STOCK_ADJUSTMENT_REQUEST",
    ADD_NEW_STOCK_ADJUSTMENT_SUCCESS: "ADD_NEW_STOCK_ADJUSTMENT_SUCCESS",
    ADD_NEW_STOCK_ADJUSTMENT_FAILURE: "ADD_NEW_STOCK_ADJUSTMENT_FAILURE",
    ADD_INVENTORY_SETTINGS_REQUEST: "ADD_INVENTORY_SETTINGS_REQUEST",
    ADD_INVENTORY_SETTINGS_SUCCESS: "ADD_INVENTORY_SETTINGS_SUCCESS",
    ADD_INVENTORY_SETTINGS_FAILURE: "ADD_INVENTORY_SETTINGS_FAILURE",
    ADD_PRODUCT_VENDOR_REQUEST: "ADD_PRODUCT_VENDOR_REQUEST",
    ADD_PRODUCT_VENDOR_SUCCESS: "ADD_PRODUCT_VENDOR_SUCCESS",
    ADD_PRODUCT_VENDOR_FAILURE: "ADD_PRODUCT_VENDOR_FAILURE",
    UPDATE_INVENTORY_SETTINGS_REQUEST: "UPDATE_INVENTORY_SETTINGS_REQUEST",
    UPDATE_INVENTORY_SETTINGS_SUCCESS: "UPDATE_INVENTORY_SETTINGS_SUCCESS",
    UPDATE_INVENTORY_SETTINGS_FAILURE: "UPDATE_INVENTORY_SETTINGS_FAILURE",
    FETCH_INVENTORY_SETTINGS_REQUEST: "FETCH_INVENTORY_SETTINGS_REQUEST",
    FETCH_INVENTORY_SETTINGS_SUCCESS: "FETCH_INVENTORY_SETTINGS_SUCCESS",
    FETCH_INVENTORY_SETTINGS_FAILURE: "FETCH_INVENTORY_SETTINGS_FAILURE",
    FETCH_SINGLE_SUPPLIED_STOCK_REQUEST: "FETCH_SINGLE_SUPPLIED_STOCK_REQUEST",
    FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS: "FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS",
    FETCH_SINGLE_SUPPLIED_STOCK_FAILURE: "FETCH_SINGLE_SUPPLIED_STOCK_FAILURE",
    FETCH_SUPPLIED_STOCK_LIST_REQUEST: "FETCH_SUPPLIED_STOCK_LIST_REQUEST",
    FETCH_SUPPLIED_STOCK_LIST_SUCCESS: "FETCH_SUPPLIED_STOCK_LIST_SUCCESS",
    FETCH_SUPPLIED_STOCK_LIST_FAILURE: "FETCH_SUPPLIED_STOCK_LIST_FAILURE",
    ADD_SUPPLIED_STOCK_REQUEST: "ADD_SUPPLIED_STOCK_REQUEST",
    ADD_SUPPLIED_STOCK_SUCCESS: "ADD_SUPPLIED_STOCK_SUCCESS",
    ADD_SUPPLIED_STOCK_FAILURE: "ADD_SUPPLIED_STOCK_FAILURE",
    UPDATE_SUPPLIED_STOCK_REQUEST: "UPDATE_SUPPLIED_STOCK_REQUEST",
    UPDATE_SUPPLIED_STOCK_SUCCESS: "UPDATE_SUPPLIED_STOCK_SUCCESS",
    UPDATE_SUPPLIED_STOCK_FAILURE: "UPDATE_SUPPLIED_STOCK_FAILURE",
    FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST: "FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST",
    FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS: "FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS",
    FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE: "FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE",
    // =================================================================================================
    FETCH_TOP_PERFORMING_PRODUCTS_REQUEST: "FETCH_TOP_PERFORMING_PRODUCTS_REQUEST",
    FETCH_TOP_PERFORMING_PRODUCTS_SUCCESS: "FETCH_TOP_PERFORMING_PRODUCTS_SUCCESS",
    FETCH_TOP_PERFORMING_PRODUCTS_FAILURE: "FETCH_TOP_PERFORMING_PRODUCTS_FAILURE",
    FETCH_LEAST_PERFORMING_PRODUCTS_REQUEST: "FETCH_LEAST_PERFORMING_PRODUCTS_REQUEST",
    FETCH_LEAST_PERFORMING_PRODUCTS_SUCCESS: "FETCH_LEAST_PERFORMING_PRODUCTS_SUCCESS",
    FETCH_LEAST_PERFORMING_PRODUCTS_FAILURE: "FETCH_LEAST_PERFORMING_PRODUCTS_FAILURE",
    FETCH_LOCATION_STOCK_LEVELS_REQUEST: "FETCH_LOCATION_STOCK_LEVELS_REQUEST",
    FETCH_LOCATION_STOCK_LEVELS_SUCCESS: "FETCH_LOCATION_STOCK_LEVELS_SUCCESS",
    FETCH_LOCATION_STOCK_LEVELS_FAILURE: "FETCH_LOCATION_STOCK_LEVELS_FAILURE",
    FETCH_PRODUCT_SUPPLIERS_REQUEST: "FETCH_PRODUCT_SUPPLIERS_REQUEST",
    FETCH_PRODUCT_SUPPLIERS_SUCCESS: "FETCH_PRODUCT_SUPPLIERS_SUCCESS",
    FETCH_PRODUCT_SUPPLIERS_FAILURE: "FETCH_PRODUCT_SUPPLIERS_FAILURE",
    FETCH_INVENTORY_GROWTH_RATE_REQUEST: "FETCH_INVENTORY_GROWTH_RATE_REQUEST",
    FETCH_INVENTORY_GROWTH_RATE_SUCCESS: "FETCH_INVENTORY_GROWTH_RATE_SUCCESS",
    FETCH_INVENTORY_GROWTH_RATE_FAILURE: "FETCH_INVENTORY_GROWTH_RATE_FAILURE",
    FETCH_GMW_VALUES_REQUEST: "FETCH_GMW_VALUES_REQUEST",
    FETCH_GMW_VALUES_SUCCESS: "FETCH_GMW_VALUES_SUCCESS",
    FETCH_GMW_VALUES_FAILURE: "FETCH_GMW_VALUES_FAILURE",
    FETCH_SINGLE_PRODUCT_GMW_REQUEST: "FETCH_SINGLE_PRODUCT_GMW_REQUEST",
    FETCH_SINGLE_PRODUCT_GMW_SUCCESS: "FETCH_SINGLE_PRODUCT_GMW_SUCCESS",
    FETCH_SINGLE_PRODUCT_GMW_FAILURE: "FETCH_SINGLE_PRODUCT_GMW_FAILURE",
    FETCH_SINGLE_PRODUCT_STOCK_LEVELS_REQUEST: "FETCH_SINGLE_PRODUCT_STOCK_LEVELS_REQUEST",
    FETCH_SINGLE_PRODUCT_STOCK_LEVELS_SUCCESS: "FETCH_SINGLE_PRODUCT_STOCK_LEVELS_SUCCESS",
    FETCH_SINGLE_PRODUCT_STOCK_LEVELS_FAILURE: "FETCH_SINGLE_PRODUCT_STOCK_LEVELS_FAILURE",
    FETCH_SINGLE_PRODUCT_BOM_REQUEST: "FETCH_SINGLE_PRODUCT_BOM_REQUEST",
    FETCH_SINGLE_PRODUCT_BOM_SUCCESS: "FETCH_SINGLE_PRODUCT_BOM_SUCCESS",
    FETCH_SINGLE_PRODUCT_BOM_FAILURE: "FETCH_SINGLE_PRODUCT_BOM_FAILURE",
    FETCH_SALES_ORDERS_REQUEST: "FETCH_SALES_ORDERS_REQUEST",
    FETCH_SALES_ORDERS_SUCCESS: "FETCH_SALES_ORDERS_SUCCESS",
    FETCH_SALES_ORDERS_FAILURE: "FETCH_SALES_ORDERS_FAILURE",
    FETCH_PRODUCTS_ANALYTICS_REQUEST: "FETCH_PRODUCTS_ANALYTICS_REQUEST",
    FETCH_PRODUCTS_ANALYTICS_SUCCESS: "FETCH_PRODUCTS_ANALYTICS_SUCCESS",
    FETCH_PRODUCTS_ANALYTICS_FAILURE: "FETCH_PRODUCTS_ANALYTICS_FAILURE",
    FETCH_EVENTS_REQUEST: "FETCH_EVENTS_REQUEST",
    FETCH_EVENTS_SUCCESS: "FETCH_EVENTS_SUCCESS",
    FETCH_EVENTS_FAILURE: "FETCH_EVENTS_FAILURE",
    FETCH_INVENTORY_INCIDENTS_REQUEST: "FETCH_INVENTORY_INCIDENTS_REQUEST",
    FETCH_INVENTORY_INCIDENTS_SUCCESS: "FETCH_INVENTORY_INCIDENTS_SUCCESS",
    FETCH_INVENTORY_INCIDENTS_FAILURE: "FETCH_INVENTORY_INCIDENTS_FAILURE",
    FETCH_INVENTORY_INCIDENTS_STOCKING_REQUEST: "FETCH_INVENTORY_INCIDENTS_STOCKING_REQUEST",
    FETCH_INVENTORY_INCIDENTS_STOCKING_SUCCESS: "FETCH_INVENTORY_INCIDENTS_STOCKING_SUCCESS",
    FETCH_INVENTORY_INCIDENTS_STOCKING_FAILURE: "FETCH_INVENTORY_INCIDENTS_STOCKING_FAILURE",
    FETCH_INVENTORY_INCIDENTS_OVER_STOCK_REQUEST: "FETCH_INVENTORY_INCIDENTS_OVER_STOCK_REQUEST",
    FETCH_INVENTORY_INCIDENTS_OVER_STOCK_SUCCESS: "FETCH_INVENTORY_INCIDENTS_OVER_STOCK_SUCCESS",
    FETCH_INVENTORY_INCIDENTS_OVER_STOCK_FAILURE: "FETCH_INVENTORY_INCIDENTS_OVER_STOCK_FAILURE",
    FETCH_SINGLE_INVENTORY_INCIDENT_REQUEST: "FETCH_SINGLE_INVENTORY_INCIDENT_REQUEST",
    FETCH_SINGLE_INVENTORY_INCIDENT_SUCCESS: "FETCH_SINGLE_INVENTORY_INCIDENT_SUCCESS",
    FETCH_SINGLE_INVENTORY_INCIDENT_FAILURE: "FETCH_SINGLE_INVENTORY_INCIDENT_FAILURE",
    ADD_INVENTORY_INCIDENT_COMMENT_REQUEST: "ADD_INVENTORY_INCIDENT_COMMENT_REQUEST",
    ADD_INVENTORY_INCIDENT_COMMENT_SUCCESS: "ADD_INVENTORY_INCIDENT_COMMENT_SUCCESS",
    ADD_INVENTORY_INCIDENT_COMMENT_FAILURE: "ADD_INVENTORY_INCIDENT_COMMENT_FAILURE",
    FETCH_INVENTORY_INCIDENT_COMMENT_REQUEST: "FETCH_INVENTORY_INCIDENT_COMMENT_REQUEST",
    FETCH_INVENTORY_INCIDENT_COMMENT_SUCCESS: "FETCH_INVENTORY_INCIDENT_COMMENT_SUCCESS",
    FETCH_INVENTORY_INCIDENT_COMMENT_FAILURE: "FETCH_INVENTORY_INCIDENT_COMMENT_FAILURE",
    FETCH_SALES_BREAKDOWN_REQUEST: "FETCH_SALES_BREAKDOWN_REQUEST",
    FETCH_SALES_BREAKDOWN_SUCCESS: "FETCH_SALES_BREAKDOWN_SUCCESS",
    FETCH_SALES_BREAKDOWN_FAILURE: "FETCH_SALES_BREAKDOWN_FAILURE",
    FETCH_INVENTORY_SEARCH_LIST_REQUEST: "FETCH_INVENTORY_SEARCH_LIST_REQUEST",
    FETCH_INVENTORY_SEARCH_LIST_SUCCESS: "FETCH_INVENTORY_SEARCH_LIST_SUCCESS",
    FETCH_INVENTORY_SEARCH_LIST_FAILURE: "FETCH_INVENTORY_SEARCH_LIST_FAILURE",
    FETCH_EXPIRATION_LIST_REQUEST: "FETCH_EXPIRATION_LIST_REQUEST",
    FETCH_EXPIRATION_LIST_SUCCESS: "FETCH_EXPIRATION_LIST_SUCCESS",
    FETCH_EXPIRATION_LIST_FAILURE: "FETCH_EXPIRATION_LIST_FAILURE",
    FETCH_STOCK_REPLISHMENT_LIST_REQUEST: "FETCH_STOCK_REPLISHMENT_LIST_REQUEST",
    FETCH_STOCK_REPLISHMENT_LIST_SUCCESS: "FETCH_STOCK_REPLISHMENT_LIST_SUCCESS",
    FETCH_STOCK_REPLISHMENT_LIST_FAILURE: "FETCH_STOCK_REPLISHMENT_LIST_FAILURE",
    CLOSE_INVENTORY_INCIDENT_REQUEST: "CLOSE_INVENTORY_INCIDENT_REQUEST",
    CLOSE_INVENTORY_INCIDENT_SUCCESS: "CLOSE_INVENTORY_INCIDENT_SUCCESS",
    CLOSE_INVENTORY_INCIDENT_FAILURE: "CLOSE_INVENTORY_INCIDENT_FAILURE",
    RECENT_INVENTORY_INCIDENT_REQUEST: "RECENT_INVENTORY_INCIDENT_REQUEST",
    RECENT_INVENTORY_INCIDENT_SUCCESS: "RECENT_INVENTORY_INCIDENT_SUCCESS",
    RECENT_INVENTORY_INCIDENT_FAILURE: "RECENT_INVENTORY_INCIDENT_FAILURE",
    VALIDATE_BATCHID_REQUEST: "VALIDATE_BATCHID_REQUEST",
    VALIDATE_BATCHID_SUCCESS: "VALIDATE_BATCHID_SUCCESS",
    VALIDATE_BATCHID_FAILURE: "VALIDATE_BATCHID_FAILURE",
    ADD_FORECAST_EVENT_REQUEST: "ADD_FORECAST_EVENT_REQUEST",
    ADD_FORECAST_EVENT_SUCCESS: "ADD_FORECAST_EVENT_SUCCESS",
    ADD_FORECAST_EVENT_FAILURE: "ADD_FORECAST_EVENT_FAILURE",
    UPDATE_FORECAST_EVENT_REQUEST: "UPDATE_FORECAST_EVENT_REQUEST",
    UPDATE_FORECAST_EVENT_SUCCESS: "UPDATE_FORECAST_EVENT_SUCCESS",
    UPDATE_FORECAST_EVENT_FAILURE: "UPDATE_FORECAST_EVENT_FAILURE",
    DELETE_FORECAST_EVENT_REQUEST: "DELETE_FORECAST_EVENT_REQUEST",
    DELETE_FORECAST_EVENT_SUCCESS: "DELETE_FORECAST_EVENT_SUCCESS",
    DELETE_FORECAST_EVENT_FAILURE: "DELETE_FORECAST_EVENT_FAILURE",
    IMPORT_SYNCED_PRODUCT_REQUEST: "IMPORT_SYNCED_PRODUCT_REQUEST",
    IMPORT_SYNCED_PRODUCT_SUCCESS: "IMPORT_SYNCED_PRODUCT_SUCCESS",
    IMPORT_SYNCED_PRODUCT_FAILURE: "IMPORT_SYNCED_PRODUCT_FAILURE",
    FETCH_ARCHIVE_PRODUCTS_REQUEST: "FETCH_ARCHIVE_PRODUCTS_REQUEST",
    FETCH_ARCHIVE_PRODUCTS_SUCCESS: "FETCH_ARCHIVE_PRODUCTS_SUCCESS",
    FETCH_ARCHIVE_PRODUCTS_FAILURE: "FETCH_ARCHIVE_PRODUCTS_FAILURE",
    FETCH_ONGOING_SYNC_CONNECTIONS_REQUEST: "FETCH_ONGOING_SYNC_CONNECTIONS_REQUEST",
    FETCH_ONGOING_SYNC_CONNECTIONS_SUCCESS: "FETCH_ONGOING_SYNC_CONNECTIONS_SUCCESS",
    FETCH_ONGOING_SYNC_CONNECTIONS_FAILURE: "FETCH_ONGOING_SYNC_CONNECTIONS_FAILURE",
    CREATE_SINGLE_BUNDLE_REQUEST: "CREATE_SINGLE_BUNDLE_REQUEST",
    CREATE_SINGLE_BUNDLE_SUCCESS: "CREATE_SINGLE_BUNDLE_SUCCESS",
    CREATE_SINGLE_BUNDLE_FAILURE: "CREATE_SINGLE_BUNDLE_FAILURE",
    CREATE_SINGLE_BUNDLE_FROM_PRODUCT_REQUEST: "CREATE_SINGLE_BUNDLE_FROM_PRODUCT_REQUEST",
    CREATE_SINGLE_BUNDLE_FROM_PRODUCT_SUCCESS: "CREATE_SINGLE_BUNDLE_FROM_PRODUCT_SUCCESS",
    CREATE_SINGLE_BUNDLE_FROM_PRODUCT_FAILURE: "CREATE_SINGLE_BUNDLE_FROM_PRODUCT_FAILURE",
    UPDATE_SINGLE_BUNDLE_REQUEST: "UPDATE_SINGLE_BUNDLE_REQUEST",
    UPDATE_SINGLE_BUNDLE_SUCCESS: "UPDATE_SINGLE_BUNDLE_SUCCESS",
    UPDATE_SINGLE_BUNDLE_FAILURE: "UPDATE_SINGLE_BUNDLE_FAILURE",
    FETCH_LIST_BUNDLE_REQUEST: "FETCH_LIST_BUNDLE_REQUEST",
    FETCH_LIST_BUNDLE_SUCCESS: "FETCH_LIST_BUNDLE_SUCCESS",
    FETCH_LIST_BUNDLE_FAILURE: "FETCH_LIST_BUNDLE_FAILURE",
    FETCH_BUNDLE_DETAILS_REQUEST: "FETCH_BUNDLE_DETAILS_REQUEST",
    FETCH_BUNDLE_DETAILS_SUCCESS: "FETCH_BUNDLE_DETAILS_SUCCESS",
    FETCH_BUNDLE_DETAILS_FAILURE: "FETCH_BUNDLE_DETAILS_FAILURE",
    CREATE_MULTIPLE_BUNDLES_REQUEST: "CREATE_MULTIPLE_BUNDLES_REQUEST",
    CREATE_MULTIPLE_BUNDLES_SUCCESS: "CREATE_MULTIPLE_BUNDLES_SUCCESS",
    CREATE_MULTIPLE_BUNDLES_FAILURE: "CREATE_MULTIPLE_BUNDLES_FAILURE",
    SET_ARCHIVE_STATUS_REQUEST: "SET_ARCHIVE_STATUS_REQUEST",
    SET_ARCHIVE_STATUS_SUCCESS: "SET_ARCHIVE_STATUS_SUCCESS",
    SET_ARCHIVE_STATUS_FAILURE: "SET_ARCHIVE_STATUS_FAILURE",
    FETCH_WAREHOUSE_STATS_REQUEST: "FETCH_WAREHOUSE_STATS_REQUEST",
    FETCH_WAREHOUSE_STATS_SUCCESS: "FETCH_WAREHOUSE_STATS_SUCCESS",
    FETCH_WAREHOUSE_STATS_FAILURE: "FETCH_WAREHOUSE_STATS_FAILURE",
    FETCH_TOTAL_STOCK_VALUE_REQUEST: "FETCH_TOTAL_STOCK_VALUE_REQUEST",
    FETCH_TOTAL_STOCK_VALUE_SUCCESS: "FETCH_TOTAL_STOCK_VALUE_SUCCESS",
    FETCH_TOTAL_STOCK_VALUE_FAILURE: "FETCH_TOTAL_STOCK_VALUE_FAILURE",
    FETCH_IN_STOCK_VALUE_REQUEST: "FETCH_IN_STOCK_VALUE_REQUEST",
    FETCH_IN_STOCK_VALUE_SUCCESS: "FETCH_IN_STOCK_VALUE_SUCCESS",
    FETCH_IN_STOCK_VALUE_FAILURE: "FETCH_IN_STOCK_VALUE_FAILURE",
    FETCH_RE_STOCK_VALUE_REQUEST: "FETCH_RE_STOCK_VALUE_REQUEST",
    FETCH_RE_STOCK_VALUE_SUCCESS: "FETCH_RE_STOCK_VALUE_SUCCESS",
    FETCH_RE_STOCK_VALUE_FAILURE: "FETCH_RE_STOCK_VALUE_FAILURE",
    FETCH_OUT_STOCK_VALUE_REQUEST: "FETCH_OUT_STOCK_VALUE_REQUEST",
    FETCH_OUT_STOCK_VALUE_SUCCESS: "FETCH_OUT_STOCK_VALUE_SUCCESS",
    FETCH_OUT_STOCK_VALUE_FAILURE: "FETCH_OUT_STOCK_VALUE_FAILURE",
    FETCH_AVG_COST_VALUE_REQUEST: "FETCH_AVG_COST_VALUE_REQUEST",
    FETCH_AVG_COST_VALUE_SUCCESS: "FETCH_AVG_COST_VALUE_SUCCESS",
    FETCH_AVG_COST_VALUE_FAILURE: "FETCH_AVG_COST_VALUE_FAILURE",
    EDIT_BUNDLE_REQUEST: "EDIT_BUNDLE_REQUEST",
    EDIT_BUNDLE_SUCCESS: "EDIT_BUNDLE_SUCCESS",
    EDIT_BUNDLE_FAILURE: "EDIT_BUNDLE_FAILURE",
    CREATE_SKU_MAPPING_REQUEST: "CREATE_SKU_MAPPING_REQUEST",
    CREATE_SKU_MAPPING_SUCCESS: "CREATE_SKU_MAPPING_SUCCESS",
    CREATE_SKU_MAPPING_FAILURE: "CREATE_SKU_MAPPING_FAILURE",
    UPDATE_SKU_MAPPING_REQUEST: "UPDATE_SKU_MAPPING_REQUEST",
    UPDATE_SKU_MAPPING_SUCCESS: "UPDATE_SKU_MAPPING_SUCCESS",
    UPDATE_SKU_MAPPING_FAILURE: "UPDATE_SKU_MAPPING_FAILURE",
    FETCH_MAPPED_SKU_LIST_REQUEST: "FETCH_MAPPED_SKU_LIST_REQUEST",
    FETCH_MAPPED_SKU_LIST_SUCCESS: "FETCH_MAPPED_SKU_LIST_SUCCESS",
    FETCH_MAPPED_SKU_LIST_FAILURE: "FETCH_MAPPED_SKU_LIST_FAILURE",
    FETCH_SINGLE_MAPPED_SKU_REQUEST: "FETCH_SINGLE_MAPPED_SKU_REQUEST",
    FETCH_SINGLE_MAPPED_SKU_SUCCESS: "FETCH_SINGLE_MAPPED_SKU_SUCCESS",
    FETCH_SINGLE_MAPPED_SKU_FAILURE: "FETCH_SINGLE_MAPPED_SKU_FAILURE",
    FETCH_SKU_MAPPING_PRODUCT_LIST_REQUEST: "FETCH_SKU_MAPPING_PRODUCT_LIST_REQUEST",
    FETCH_SKU_MAPPING_PRODUCT_LIST_SUCCESS: "FETCH_SKU_MAPPING_PRODUCT_LIST_SUCCESS",
    FETCH_SKU_MAPPING_PRODUCT_LIST_FAILURE: "FETCH_SKU_MAPPING_PRODUCT_LIST_FAILURE",
    FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_REQUEST: "FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_REQUEST",
    FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_SUCCESS: "FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_SUCCESS",
    FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_FAILURE: "FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_FAILURE",
    EXPORT_IN_STOCK_LIST_REQUEST: "EXPORT_IN_STOCK_LIST_REQUEST",
    EXPORT_IN_STOCK_LIST_SUCCESS: "EXPORT_IN_STOCK_LIST_SUCCESS",
    EXPORT_IN_STOCK_LIST_FAILURE: "EXPORT_IN_STOCK_LIST_FAILURE",
    EXPORT_OUT_STOCK_LIST_REQUEST: "EXPORT_OUT_STOCK_LIST_REQUEST",
    EXPORT_OUT_STOCK_LIST_SUCCESS: "EXPORT_OUT_STOCK_LIST_SUCCESS",
    EXPORT_OUT_STOCK_LIST_FAILURE: "EXPORT_OUT_STOCK_LIST_FAILURE",
    EXPORT_INVENTORY_LIST_REQUEST: "EXPORT_INVENTORY_LIST_REQUEST",
    EXPORT_INVENTORY_LIST_SUCCESS: "EXPORT_INVENTORY_LIST_SUCCESS",
    EXPORT_INVENTORY_LIST_FAILURE: "EXPORT_INVENTORY_LIST_FAILURE",
    EXPORT_AVG_COST_PER_SKU_REQUEST: "EXPORT_AVG_COST_PER_SKU_REQUEST",
    EXPORT_AVG_COST_PER_SKU_SUCCESS: "EXPORT_AVG_COST_PER_SKU_SUCCESS",
    EXPORT_AVG_COST_PER_SKU_FAILURE: "EXPORT_AVG_COST_PER_SKU_FAILURE",
    FETCH_INVENTORY_SNAPSHOT_LIST_REQUEST: "FETCH_INVENTORY_SNAPSHOT_LIST_REQUEST",
    FETCH_INVENTORY_SNAPSHOT_LIST_SUCCESS: "FETCH_INVENTORY_SNAPSHOT_LIST_SUCCESS",
    FETCH_INVENTORY_SNAPSHOT_LIST_FAILURE: "FETCH_INVENTORY_SNAPSHOT_LIST_FAILURE",
    FETCH_GROUP_INVENTORY_SNAPSHOT_REQUEST: "FETCH_GROUP_INVENTORY_SNAPSHOT_REQUEST",
    FETCH_GROUP_INVENTORY_SNAPSHOT_SUCCESS: "FETCH_GROUP_INVENTORY_SNAPSHOT_SUCCESS",
    FETCH_GROUP_INVENTORY_SNAPSHOT_FAILURE: "FETCH_GROUP_INVENTORY_SNAPSHOT_FAILURE",
    FETCH_GROUP_SNAPSHOT_EXPORT_REQUEST: "FETCH_GROUP_SNAPSHOT_EXPORT_REQUEST",
    FETCH_GROUP_SNAPSHOT_EXPORT_SUCCESS: "FETCH_GROUP_SNAPSHOT_EXPORT_SUCCESS",
    FETCH_GROUP_SNAPSHOT_EXPORT_FAILURE: "FETCH_GROUP_SNAPSHOT_EXPORT_FAILURE",
    ADD_NEW_RAW_MATERIAL_REQUEST: "ADD_NEW_RAW_MATERIAL_REQUEST",
    ADD_NEW_RAW_MATERIAL_SUCCESS: "ADD_NEW_RAW_MATERIAL_SUCCESS",
    ADD_NEW_RAW_MATERIAL_FAILURE: "ADD_NEW_RAW_MATERIAL_FAILURE",
    FETCH_PRODUCT_REQUEST: "FETCH_PRODUCT_REQUEST",
    FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
    FETCH_PRODUCT_FAILURE: "FETCH_PRODUCT_FAILURE",
    FETCH_INVENTORY_LIST_FOR_ROUTE_REQUEST: "FETCH_INVENTORY_LIST_FOR_ROUTE_REQUEST",
    FETCH_INVENTORY_LIST_FOR_ROUTE_SUCCESS: "FETCH_INVENTORY_LIST_FOR_ROUTE_SUCCESS",
    FETCH_INVENTORY_LIST_FOR_ROUTE_FAILURE: "FETCH_INVENTORY_LIST_FOR_ROUTE_FAILURE",
};
