import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const workforceService = {
    getAiAgents,
    getAiTeammates,
    getSingleAiTeammate,
    createAiTeammate,
    getTaskList,
    getSingleTask,
    updateTaskSalesOrder,
    approveTaskStep,
    retryTaskStep,
    giveTaskStepFeedback,
    getTaskAnalytics,
    updateAiTeammate,
    testAiAgent,
    getSingleAiAgentTasks,
    getSingleAiAgentTaskStats,
    createCampaignComment,
    updateCampaignComment,
    getCampaignComments,
    getCampaignCommentReplies,
    createSpsCommerceConnection,
    getSingleCampaign,
    getCampaignErrors,
    correctCampaignError,
    getAiNotifications,
    markAllAiNotificationsAsRead,
};

async function getAiAgents() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/ai/agents`, requestOptions);
    return res;
}

async function getAiTeammates(page: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/list?page=${page}${
            search ? `&search=${search}` : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleAiTeammate(aiTeammateId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/single/${aiTeammateId}`,
        requestOptions
    );
    return res;
}

async function createAiTeammate(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/create`,
        requestOptions
    );

    return res;
}

async function updateAiTeammate(
    data: Record<string, any> | Array<Record<string, any>>,
    aiTeammateId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/update/single/${aiTeammateId}`,
        requestOptions
    );

    return res;
}

async function getTaskList(
    page?: string | number,
    search?: string,
    taskStatus?: string,
    assignedTo?: string,
    taskType?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/tasks/list?${page ? `&page=${page}` : ""}${
            search ? `&search=${search}` : ""
        }${taskStatus ? `&status=${taskStatus}` : ""}${assignedTo ? `&agentId=${assignedTo}` : ""}${
            taskType ? `&taskType=${taskType}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function getSingleTask(taskId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/tasks/single/${taskId}`,
        requestOptions
    );

    return res;
}

async function updateTaskSalesOrder(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/edit`,
        requestOptions
    );

    return res;
}

async function approveTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/state`,
        requestOptions
    );

    return res;
}

async function retryTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/retry`,
        requestOptions
    );

    return res;
}

async function giveTaskStepFeedback(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/feedback`,
        requestOptions
    );

    return res;
}

async function getTaskAnalytics() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/tasks/dashboard-analytics`,
        requestOptions
    );

    return res;
}

async function testAiAgent(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/test`,
        requestOptions
    );

    return res;
}

async function getSingleAiAgentTasks(
    taskId: string,
    page?: string | number,
    search?: string,
    status?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/single/tasks/list/${taskId}?${
            page ? `&page=${page}` : ""
        }${search ? `&search=${search}` : ""}${status ? `&status=${status}` : ""}`,
        requestOptions
    );

    return res;
}

async function getSingleAiAgentTaskStats(
    taskId: string,
    startDate?: string,
    endDate?: string,
    dateRange?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/single/tasks/stats/${taskId}?${
            startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
        }${dateRange && startDate && endDate ? `&dateRange=${dateRange}` : ""}`,
        requestOptions
    );

    return res;
}

async function getCampaignComments(
    campaignId: string,
    section?: number,
    isResolvedTab?: boolean,
    isMentionTab?: boolean
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/${campaignId}/comments?${
            section ? `section=${section}` : ""
        }${isResolvedTab ? `&isResolved=${isResolvedTab}` : ""}${
            isMentionTab ? `&mentionsOnly=${isMentionTab}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function getCampaignCommentReplies(campaignId: string, commentId?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/${campaignId}/comments/${commentId}`,
        requestOptions
    );

    return res;
}

async function getSingleCampaign(campaignId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/${campaignId}`,
        requestOptions
    );

    return res;
}

async function getCampaignErrors() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/tasks-requiring-attention`,
        requestOptions
    );

    return res;
}

async function correctCampaignError(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/correct-product-matching`,
        requestOptions
    );

    return res;
}

async function getAiNotifications(page?: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/anomaly?${page ? `&page=${page}` : ""}${
            search ? `&search=${search}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function markAllAiNotificationsAsRead(
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/anomaly/mark-all-as-read`,
        requestOptions
    );

    return res;
}

async function createCampaignComment(
    campaignId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/${campaignId}/comments`,
        requestOptions
    );

    return res;
}

async function updateCampaignComment(
    campaignId: string,
    commentId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/${campaignId}/comments/${commentId}`,
        requestOptions
    );

    return res;
}

async function createSpsCommerceConnection(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/sps-commerce/create`,
        requestOptions
    );

    return res;
}
