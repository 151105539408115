export const trackstarList = [
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_1_ibzhyz.svg",
        imageName: "AMS Fulfillment",
        title: "AMS Fulfillment",
        content:
            "Connecting your AMS Fulfillment account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "ams",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_y5us0d.svg",
        imageName: "AMWare",
        title: "AMWare",
        content:
            "Connecting your AMWare account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "amware",
    },
    // {
    //     imageSrc:
    //         "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_2_znscbt.svg",
    //     imageName: "Amazon",
    //     title: "Amazon",
    //     content:
    //         "Connecting your Amazon account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
    //     connected: false,
    //     trackstarIntegration: "fba",
    // },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_3_c5qrjz.svg",
        imageName: "BlueBox",
        title: "BlueBox",
        content:
            "Connecting your BlueBox account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "bluebox",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_4_fitypl.svg",
        imageName: "Byrd Fulfillment",
        title: "Byrd Fulfillment",
        content:
            "Connecting your Byrd Fulfillment account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "byrd",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_5_yfxapr.svg",
        imageName: "Canary7",
        title: "Canary7",
        content:
            "Connecting your Canary7 account allows Synth to get and analyze supply chain-related information from your warehouse, enabling AI-driven insights for inventory movement and stock optimization.",
        connected: false,
        trackstarIntegration: "canary7",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_6_w7wc36.svg",
        imageName: "Camelot",
        title: "Camelot",
        content:
            "Connecting your Camelot account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "camelot",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_7_d0ihkw.svg",
        imageName: "DCL",
        title: "DCL",
        content:
            "Connecting your DCL account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "dcl",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_8_k5q3v1.svg",
        imageName: "Davinci",
        title: "Davinci",
        content:
            "Connecting your Davinci account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "davinci",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_9_ousjad.svg",
        imageName: "Dear Systems (Cin7 Core)",
        title: "Dear Systems (Cin7 Core)",
        content:
            "Connecting your Dear Systems (Cin7 Core) account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "dear-systems",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_10_btk1ni.svg",
        imageName: "Deposco",
        title: "Deposco",
        content:
            "Connecting your Deposco account allows Synth to get and analyze supply chain-related information, enhancing warehouse visibility and automating stock replenishment.",
        connected: false,
        trackstarIntegration: "deposco",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_11_sjf1vc.svg",
        imageName: "Distribution Management",
        title: "Distribution Management",
        content:
            "Connecting your Distribution Management account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "distribution-management",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_12_jogksl.svg",
        imageName: "Elite Ops",
        title: "Elite Ops",
        content:
            "Connecting your Elite Ops account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "elite-ops",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_13_qeetil.svg",
        imageName: "Excelsior Integrated",
        title: "Excelsior Integrated",
        content:
            "Connecting your Excelsior Integrated account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "excelsior",
    },
    // {
    //     imageSrc:
    //         "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_14_ohwwhi.svg",
    //     imageName: "Extensiv (3PL Central)",
    //     title: "Extensiv (3PL Central)",
    //     content:
    //         "Connecting your Extensiv (3PL Central) account allows Synth to get and analyze supply chain-related information from multiple fulfillment centers, ensuring accurate inventory management.",
    //     connected: false,
    //     trackstarIntegration: "extensiv-3pl-central",
    // },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_15_w5rhms.svg",
        imageName: "FFE Solutions Group",
        title: "FFE Solutions Group",
        content:
            "Connecting your FFE Solutions Group account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "ffe",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_16_qu8zzf.svg",
        imageName: "Finale",
        title: "Finale",
        content:
            "Connecting your Finale account allows Synth to get and analyze supply chain-related information, automating stock tracking and optimizing warehouse operations.",
        connected: false,
        trackstarIntegration: "finale",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_17_zcsegh.svg",
        imageName: "Flat Fee Shipping",
        title: "Flat Fee Shipping",
        content:
            "Connecting your Flat Fee Shipping account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "flat-fee-shipping",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_18_ioeu0x.svg",
        imageName: "Flexport",
        title: "Flexport",
        content:
            "Connecting your Flexport account allows Synth to get and analyze supply chain-related information, enhancing warehouse visibility and automating stock replenishment.",
        connected: false,
        trackstarIntegration: "flexport",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_19_jsdzbs.svg",
        imageName: "Flowspace",
        title: "Flowspace",
        content:
            "Connecting your Flowspace account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "flowspace",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_20_mfgczd.svg",
        imageName: "Fulfillment.com",
        title: "Fulfillment.com",
        content:
            "Connecting your Fulfillment.com account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "fulfillment",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_21_wl0uwe.svg",
        imageName: "ICreate Fashion",
        title: "ICreate Fashion",
        content:
            "Connecting your ICreate Fashion account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "icreate",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_23_ojggv3.svg",
        imageName: "Infoplus",
        title: "Infoplus",
        content:
            "Connecting your Infoplus account allows Synth to get and analyze supply chain-related information, ensuring smooth warehouse workflows and optimized order processing.",
        connected: false,
        trackstarIntegration: "infoplus",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_22_pxw5kz.svg",
        imageName: "Its Here",
        title: "Its Here",
        content:
            "Connecting your Its Here account allows Synth to get and analyze supply chain-related information, providing real-time inventory tracking and warehouse efficiency insights.",
        connected: false,
        trackstarIntegration: "itshere",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_24_maj0zi.svg",
        imageName: "Jazz Central",
        title: "Jazz Central",
        content:
            "Connecting your Jazz Central account allows Synth to get and analyze supply chain-related information, improving warehouse task automation and stock visibility.",
        connected: false,
        trackstarIntegration: "jazz-central",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_25_bzqfjv.svg",
        imageName: "Linnworks",
        title: "Linnworks",
        content:
            "Connecting your Linnworks account allows Synth to get and analyze supply chain-related information, syncing sales orders with warehouse stock for better inventory accuracy.",
        connected: false,
        trackstarIntegration: "linnworks",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_27_echcue.svg",
        imageName: "Logiwa",
        title: "Logiwa",
        content:
            "Connecting your Logiwa account allows Synth to get and analyze supply chain-related information, automating warehouse operations and optimizing fulfillment speed.",
        connected: false,
        trackstarIntegration: "logiwa",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_27_echcue.svg",
        imageName: "Logiwa IO",
        title: "Logiwa IO",
        content:
            "Connecting your Logiwa IO account allows Synth to get and analyze supply chain-related information, ensuring accurate order management and real-time stock updates.",
        connected: false,
        trackstarIntegration: "logiwa-io",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_28_hcvumd.svg",
        imageName: "MasonHub",
        title: "MasonHub",
        content:
            "Connecting your MasonHub account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "masonhub",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_29_f0rusp.svg",
        imageName: "Mayple",
        title: "Mayple",
        content:
            "Connecting your Mayple account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "mayple",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_30_zqo1fo.svg",
        imageName: "Mintsoft",
        title: "Mintsoft",
        content:
            "Connecting your Mintsoft account allows Synth to get and analyze supply chain-related information, automating fulfillment workflows and warehouse logistics.",
        connected: false,
        trackstarIntegration: "mintsoft",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_31_uogekx.svg",
        imageName: "NFI Industires",
        title: "NFI Industires",
        content:
            "Connecting your NFI Industires account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "nfi",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_32_pxp52a.svg",
        imageName: "NLRP",
        title: "NLRP",
        content:
            "Connecting your NLRP account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "nlrp",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_33_dz08vm.svg",
        imageName: "Nimble",
        title: "Nimble",
        content:
            "Connecting your Nimble account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "nimble",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_34_n5ygaw.svg",
        imageName: "Ongoing",
        title: "Ongoing",
        content:
            "Connecting your Ongoing account allows Synth to get and analyze supply chain-related information, improving stock movement tracking and warehouse operations.",
        connected: false,
        trackstarIntegration: "ongoing",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_35_yftvkq.svg",
        imageName: "Packiyo",
        title: "Packiyo",
        content:
            "Connecting your Packiyo account allows Synth to get and analyze supply chain-related information, ensuring accurate picking, packing, and inventory tracking.",
        connected: false,
        trackstarIntegration: "packiyo",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_36_zpqk24.svg",
        imageName: "RJW",
        title: "RJW",
        content:
            "Connecting your RJW account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "rjw",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_37_vth0br.svg",
        imageName: "SKUSavvy",
        title: "SKUSavvy",
        content:
            "Connecting your SKUSavvy account allows Synth to get and analyze supply chain-related information, ensuring inventory accuracy and demand forecasting.",
        connected: false,
        trackstarIntegration: "skusavvy",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_38_og5wla.svg",
        imageName: "SKUVault (Linnworks Core)",
        title: "SKUVault (Linnworks Core)",
        content:
            "Connecting your SKUVault (Linnworks Core) account allows Synth to get and analyze supply chain-related information, improving stock tracking and automated reordering.",
        connected: false,
        trackstarIntegration: "skuvault",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_39_ega1he.svg",
        imageName: "ShipBob",
        title: "ShipBob",
        content:
            "Connecting your ShipBob account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment.",
        connected: false,
        trackstarIntegration: "shipbob",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_40_abiknp.svg",
        imageName: "ShipHero",
        title: "ShipHero",
        content:
            "Connecting your ShipHero account allows Synth to get and analyze supply chain-related information, automating warehouse fulfillment and order processing.",
        connected: false,
        trackstarIntegration: "shiphero",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_41_swn70u.svg",
        imageName: "ShipOffers",
        title: "ShipOffers",
        content:
            "Connecting your ShipOffers account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "shipoffers",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_42_sp38fi.svg",
        imageName: "ShipStation",
        title: "ShipStation",
        content:
            "Connecting your ShipStation account allows Synth to get and analyze supply chain-related information, ensuring fast and accurate inventory synchronization.",
        connected: false,
        trackstarIntegration: "shipstation",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_43_xdwueb.svg",
        imageName: "ShipStream",
        title: "ShipStream",
        content:
            "Connecting your ShipStream account allows Synth to get and analyze supply chain-related information, improving warehouse efficiency and order accuracy.",
        connected: false,
        trackstarIntegration: "shipstream",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_44_q0dnup.svg",
        imageName: "Shipfusion",
        title: "Shipfusion",
        content:
            "Connecting your Shipfusion account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "shipfusion",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_45_tdznsh.svg",
        imageName: "Shipmonk",
        title: "Shipmonk",
        content:
            "Connecting your Shipmonk account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "shipmonk",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_46_xsyxri.svg",
        imageName: "Shipping-and-Handling",
        title: "Shipping-and-Handling",
        content:
            "Connecting your Shipping-and-Handling account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "shipping-and-handling",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_47_hj5a9e.svg",
        imageName: "ShippingTree",
        title: "ShippingTree",
        content:
            "Connecting your ShippingTree account allows Synth to get and analyze supply chain-related information, enhancing warehouse visibility and automating stock replenishment.",
        connected: false,
        trackstarIntegration: "shipping-tree",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_48_ks4ww8.svg",
        imageName: "Shipwire",
        title: "Shipwire",
        content:
            "Connecting your Shipwire account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "shipwire",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_49_lxhx2c.svg",
        imageName: "Smart Warehousing",
        title: "Smart Warehousing",
        content:
            "Connecting your Smart Warehousing account allows Synth to get and analyze supply chain-related information, providing real-time stock updates and warehouse insights.",
        connected: false,
        trackstarIntegration: "smart-warehousing",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_50_ycmnzu.svg",
        imageName: "Sojo",
        title: "Sojo",
        content:
            "Connecting your Sojo account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "sojo",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_51_rewuje.svg",
        imageName: "Stord",
        title: "Stord",
        content:
            "Connecting your Stord account allows Synth to get and analyze supply chain-related information, enhancing warehouse visibility and automating stock replenishment.",
        connected: false,
        trackstarIntegration: "stord",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_52_bpy6ji.svg",
        imageName: "Teapplix",
        title: "Teapplix",
        content:
            "Connecting your Teapplix account allows Synth to get and analyze supply chain-related information, optimizing order fulfillment and warehouse workflows.",
        connected: false,
        trackstarIntegration: "teapplix",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_53_rqsmdg.svg",
        imageName: "The Fulfillment Lab",
        title: "The Fulfillment Lab",
        content:
            "Connecting your The Fulfillment Lab account allows Synth to get and analyze supply chain-related information from your warehouse operations, improving order accuracy and fulfillment speed.",
        connected: false,
        trackstarIntegration: "fulfillment-lab",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_54_ukufvq.svg",
        imageName: "Tiktok Shop",
        title: "Tiktok Shop",
        content:
            "Connecting your Tiktok Shop account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "tiktok",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_55_kibxgw.svg",
        imageName: "Unleashed",
        title: "Unleashed",
        content:
            "Connecting your Unleashed account allows Synth to get and analyze supply chain-related information, ensuring real-time inventory visibility and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "unleashed",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_56_sp5or7.svg",
        imageName: "Veeqo",
        title: "Veeqo",
        content:
            "Connecting your Veeqo account allows Synth to get and analyze supply chain-related information, syncing multi-location stock data for optimized fulfillment.",
        connected: false,
        trackstarIntegration: "veeqo",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_57_u6zv8j.svg",
        imageName: "Veracore",
        title: "Veracore",
        content:
            "Connecting your Veracore account allows Synth to get and analyze supply chain-related information, automating warehouse workflows and order management.",
        connected: false,
        trackstarIntegration: "veracore",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_58_ui6gls.svg",
        imageName: "Vertical Cold",
        title: "Vertical Cold",
        content:
            "Connecting your Vertical Cold account allows Synth to get and analyze supply chain-related information, ensuring real-time stock updates and accurate demand forecasting.",
        connected: false,
        trackstarIntegration: "vertical-cold",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_59_giyfox.svg",
        imageName: "Walmart",
        title: "Walmart",
        content:
            "Connecting your Walmart account allows Synth to get and analyze supply chain-related information, enhancing warehouse visibility and automating stock replenishment.",
        connected: false,
        trackstarIntegration: "walmart",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_60_fcjtg1.svg",
        imageName: "Whiplash",
        title: "Whiplash",
        content:
            "Connecting your Whiplash account allows Synth to get and analyze supply chain-related information from your warehouse operations, helping to streamline inventory tracking and order fulfillment",
        connected: false,
        trackstarIntegration: "whiplash",
    },
    {
        imageSrc:
            "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_61_qfz3de.svg",
        imageName: "Zenventory",
        title: "Zenventory",
        content:
            "Connecting your Zenventory account allows Synth to get and analyze supply chain-related information, improving stock visibility and warehouse efficiency.",
        connected: false,
        trackstarIntegration: "zenventory",
    },
    //for testing
    // {
    //     imageSrc:
    //         "https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_61_qfz3de.svg",
    //     imageName: "Sandox",
    //     title: "Sandbox",
    //     content:
    //         "Connecting your Sandbox account allows Synth to get and analyze supply chain-related information, improving stock visibility and warehouse efficiency.",
    //     connected: false,
    //     trackstarIntegration: "sandbox",
    // },
];
