import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";
import moment from "moment";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetNonFinishedGoodsForecast = () => {
    const dispatch = useAppDispatch();
    // const [configTableData, setConfigTableData] = useState<{ [key: string]: any }[]>([]);
    const [configTableData, setConfigTableData] = useState<{ [key: string]: any }[]>([]);
    const [nonFinishedGoodsForecastData, setNonFinishedGoodsForecastData] = useState<
        { [key: string]: any }[]
    >([]);
    const [productIds, setProductIds] = useState<string[]>([]);
    const [channel, setChannel] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [dateRange, setDateRange] = useState<any>("yearly");
    const [endDate, setEndDate] = useState<string>("");
    const [startDate, setStartDate] = useState<any>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const handleCategoryChange = (value) => {
        setCategory(value);
    };

    const handleChannelChange = (value) => {
        setChannel(value);
    };
    const handleProductChange = (value) => {
        setProductIds(value);
    };
    const handleDateRangeChange = (period) => {
        setDateRange(period === "yearDropdown" ? "custom" : period);
        // setStartDate("");
        // setEndDate("");
    };
    // const handleStartAndEndDateChange = (customDate) => {
    //     setStartDate(customDate[0].toISOString());
    //     setEndDate(customDate[1].toISOString());
    //     setDateRange("custom");
    // };

    const {
        fetchingNonFinishedGoodsForecast,
        fetchNonFinishedGoodsForecastSuccess,
        fetchNonFinishedGoodsForecastFailure,
    } = useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchNonFinishedGoodsForecastSuccess)) {
            setNonFinishedGoodsForecastData(fetchNonFinishedGoodsForecastSuccess?.inventories);
            // setConfigTableData(fetchedDashboardConfigListSuccess?.inventories);
            setPagination({
                currentPage: fetchNonFinishedGoodsForecastSuccess?.pagination?.current,
                noOfPages: fetchNonFinishedGoodsForecastSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchNonFinishedGoodsForecastSuccess]);

    useEffect(() => {
        dispatch(
            dashboardActions?.getNonFinishGoodsForecast(
                updatedPage,
                dateRange,
                productIds?.join(","),
                channel,
                category
            )
        );
    }, [dispatch, updatedPage, dateRange, productIds, channel, category]);

    return {
        data: nonFinishedGoodsForecastData,
        isFetching: fetchingNonFinishedGoodsForecast,
        error: fetchNonFinishedGoodsForecastFailure,
        handleCategoryChange,
        handleProductChange,
        updatedPage,
        setUpdatedPage,
        pagination,
        setPagination,
        handleDateRangeChange,
        handleChannelChange,
        productIds,
    };
};

export default useGetNonFinishedGoodsForecast;
