import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, set, sortBy, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { useSearchParams } from "react-router-dom";

const useGetProductListForRoute = (scrollContent?: HTMLElement, defaultCategory?: string) => {
    const dispatch = useAppDispatch();

    const {
        fetchedInventoryListForRouteSuccess,
        fetchedInventoryListForRouteFailure,
        fetchingInventoryListForRoute,
    } = useAppSelector((state) => state.inventory);

    const [productList, setProductList] = useState<{ [key: string]: any }[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [refetch, setRefetch] = useState<boolean>(false);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [tracking, setTracking] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("");
    const [rawProductData, setRawData] = useState([]);
    const [productCategory, setProductCategory] = useState<string>(() => defaultCategory || "");
    const [selectedProduct, setSelectedProduct] = useState<{ name?: string; value: string }>({
        name: "",
        value: "",
    });
    const [channel, setChannel] = useState<string>("");

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearch(e.target.value);
        setUpdatedPage(1);
    }, 1000);

    useEffect(() => {
        setUpdatedPage(1);
        setProductList([]);
    }, [debouncedSearch, productCategory, channel, tracking, refetch]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchInventoryListForRoute(
                10,
                updatedPage,
                debouncedSearch,
                productCategory,
                channel,
                tracking
            )
        );
        return () => {
            dispatch(inventoryActions.resetFetchInventoryListForRoute());
        };
    }, [debouncedSearch, productCategory, channel, tracking, refetch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedInventoryListForRouteSuccess)) {
            setProductList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedInventoryListForRouteSuccess.inventory?.data], "_id")
                    : uniqBy(
                          [...prev, ...fetchedInventoryListForRouteSuccess?.inventory?.data],
                          "_id"
                      )
            );
            setPagination({
                currentPage: fetchedInventoryListForRouteSuccess.pagination?.current,
                noOfPages: fetchedInventoryListForRouteSuccess.pagination?.number_of_pages,
            });
            setTotal(fetchedInventoryListForRouteSuccess?.total);
            setRawData(fetchedInventoryListForRouteSuccess.inventory?.data);
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchInventoryListForRoute());
        }
    }, [
        dispatch,
        fetchedInventoryListForRouteSuccess,
        updatedPage,
        debouncedSearch,
        productCategory,
        channel,
        tracking,
        refetch,
    ]);

    return {
        data: productList,
        rawData: rawProductData || [],
        setProductList,
        setUpdatedPage,
        pagination,
        setPagination,
        debouncedSearch,
        search,
        setSearch,
        setDebouncedSearch,
        productCategory,
        handleDebouncedChange,
        handleSearch,
        selectedProduct,
        isLoadingMore,
        updatedPage,
        channel,
        total,
        setTracking,
        setRefetch,
        setChannel,
        setProductCategory,
        isFetching: fetchingInventoryListForRoute,
    };
};

export default useGetProductListForRoute;
