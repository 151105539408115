import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import { getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetSingleProductBom from "hooks/useGetSingleProductBom";
import { debounce, truncate, unionBy, uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { inventoryActions, productionActions } from "redux/Ldb/actions";
import { AccountPermissions } from "types/permissions";

const Recipes = () => {
    const { data } = useGetSingleProductBom();
    const { productId } = useParams();
    const [addSelectedProduct, setAddSelectedProduct] = useState<any>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [productModal, setProductModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [dashboardContent, setDashboardContent] = useState<any>();
    const [debouncedProductSearch, setDebouncedProductSearch] = useState<string>("");
    const [product, setProduct] = useState<{ [key: string]: any }[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { updatingSingleProduct, updatedSingleProductSuccess } = useAppSelector(
        (state) => state.inventory
    );

    const { fetchingNonFinishedInventory, fetchedNonFinishedInventorySuccess } = useAppSelector(
        (state) => state.production
    );

    const dispatch = useAppDispatch();

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setPage(() => page + 1);
                    setLoadMore(true);
                }
            ),
        [dashboardContent, paginate]
    );

    const handleInputChange = (e, idx) => {
        const { name, value } = e.target;
        const copySelectedProduct = [...addSelectedProduct];
        copySelectedProduct[idx] = {
            ...copySelectedProduct[idx],
            [name]: value,
        };
        setAddSelectedProduct(copySelectedProduct);
    };

    const saveRecipe = () => {
        const copySelectProduct = [...addSelectedProduct];

        const getProductIds = copySelectProduct?.map((item) => {
            const { _id, inventoryId, productQuantity, scrap } = item;
            return {
                inventoryId: inventoryId || _id,
                quantity: productQuantity,
                scrap,
            };
        });

        const body = {
            // "bomName": "",
            billOfMaterialIds: getProductIds,
        };

        dispatch(inventoryActions.updateSingleProduct(body, productId));
    };

    const handleProductDebouncedChange = debounce((e) => {
        setDebouncedProductSearch(e.target.value);
    }, 1500);

    const handleSelectAllProducts = () => {
        if (product?.length === selectedProduct?.length) {
            setSelectedProduct([]);
        } else {
            setSelectedProduct(product);
        }
    };

    const handleSelectProduct = (item) => {
        const checkIfSelected = selectedProduct?.findIndex((prod) => prod?._id === item?._id);

        if (checkIfSelected === -1) {
            setSelectedProduct((prev) => {
                const selected = [...prev];
                selected.push(item);
                return selected;
            });
        } else {
            const copySelectedProduct = [...selectedProduct];
            const filter = copySelectedProduct?.filter((prod) => prod?._id !== item?._id);
            setSelectedProduct(filter);
        }
    };

    const handleAddButton = () => {
        const copySelectedProduct = unionBy([...addSelectedProduct, ...selectedProduct], "_id");
        const addQuantity = copySelectedProduct?.map((item) => {
            return {
                ...item,
                ...(item?._id && { inventoryId: item?._id }),
                ...{ productQuantity: item?.productQuantity },
                ...{ scrap: item?.scrap },
            };
        });
        setSelectedProduct([...addQuantity]);
        setAddSelectedProduct([...addQuantity]);
        setProductModal(false);
    };

    const calcTotalQuantity = (quantity, scrap) => {
        console.log(quantity, scrap);
        const quantityNum = Number(quantity);
        const scrapNum = Number(scrap) || 0;
        const percentage = (quantityNum * scrapNum) / 100;
        const totalQuantity = percentage + Number(quantity);
        return Number.isInteger(totalQuantity) ? totalQuantity : totalQuantity.toFixed(2);
    };
    
    const recipesTableHeader = [
        { title: "Products", widthClass: "w-[37%]" },
        {
            title: (
                <div className="flex items-center space-x-1.5">
                    <span className="text-xs">Quantity</span>
                    <span className="text-[10px] text-p-50 px-1.5 flex items-center justify-center h-[20px] border border-p-50 rounded-xl">
                        Editable
                    </span>
                </div>
            ),
            widthClass: "w-[12.1%]",
        },
        {
            title: (
                <div className="flex items-center space-x-1.5">
                    <span className="text-xs">Scrap (%)</span>
                    <span className="text-[10px] text-p-50 px-1.5 flex items-center justify-center h-[20px] border border-p-50 rounded-xl">
                        Editable
                    </span>
                </div>
            ),
            widthClass: "w-[12.1%]",
        },
        { title: "Total Quantity", widthClass: "w-[10.5%]" },
        { title: "UOM", widthClass: "w-[4.8%]" },
        { title: "Unit cost", widthClass: "w-[7.1%]" },
        { title: "Total cost", widthClass: "w-[7.6%]" },
        { title: "", widthClass: "w-[4%]" },
    ];

    const recipesTableBody = addSelectedProduct?.map((product, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        {product?.productImageDetails?.productAvatar ||
                        product?.productImageDetails?.productImages?.[0] ? (
                            <VendorAvatar
                                imageSrc={
                                    product?.productImageDetails?.productAvatar ||
                                    product?.productImageDetails?.productImages?.[0]
                                }
                                size={32}
                                name={product?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-sm text-slate-500"></i>
                            </div>
                        )}
                        <div className="font-rocGroteskMedium">
                            <span className="block text-sm">
                                {truncate(product?.productName, { length: 25 })}
                            </span>
                            <span className="block text-slate-500 text-sm">{product?.sku}</span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 w-full`}
                >
                    <TextInput
                        name="productQuantity"
                        type="number"
                        value={product?.productQuantity ?? ""}
                        onChange={(e) => handleInputChange(e, idx)}
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        inputClassName={"!text-center !min-w-[84px] !w-[84px] "}
                        min={0}
                        step="any"
                        inputContainerClassname={
                            "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !text-center !px-0 !py-0 !w-auto !h-[30px] !bg-white !border-g-60"
                        }
                        required={true}
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 w-full`}
                >
                    <TextInput
                        name="scrap"
                        type="number"
                        value={product?.scrap}
                        required={true}
                        onChange={(e) => handleInputChange(e, idx)}
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        inputClassName={"!text-center !min-w-[84px] !w-[84px] "}
                        min={0}
                        step="any"
                        inputContainerClassname={
                            "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !text-center !px-0 !py-0 !w-auto !h-[30px] !bg-white !border-g-60"
                        }
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    {product?.productQuantity && (
                        <span>
                            {calcTotalQuantity(product?.productQuantity, product?.scrap || 0)}
                        </span>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{product?.productStockDetails?.unitOfMeasurement}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>
                        {product?.unitCost?.amount
                            ? `${getCurrencyFromCurrencyCode(
                                  product?.unitCost?.currency
                              )}${product?.unitCost?.amount?.toFixed(2)}`
                            : "N/A"}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    {product?.productQuantity && product?.unitCost?.amount && (
                        <span>
                            {`${getCurrencyFromCurrencyCode(product?.unitCost?.currency)}${
                                Number.isInteger(
                                    product?.productQuantity * product?.unitCost?.amount
                                )
                                    ? product?.productQuantity * product?.unitCost?.amount
                                    : (
                                          product?.productQuantity * product?.unitCost?.amount
                                      ).toFixed(2)
                            }`}
                        </span>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <i
                        onClick={() => {
                            const addSelectedProductCopy = [...addSelectedProduct];
                            addSelectedProductCopy.splice(idx, 1);
                            setAddSelectedProduct(addSelectedProductCopy);
                        }}
                        className="ri-delete-bin-line text-red-500 cursor-pointer"
                    ></i>
                </div>
            ),
        },
    ]);

    const tableModalHeader = [
        {
            title: "",
            widthClass: "w-[5%] !font-rocGroteskMedium  !border-r !border-slate-200",
            itemClass: "!py-4",
            onSelectAll: () => {
                handleSelectAllProducts();
            },
            isAllChecked: selectedProduct?.length === product?.length,
        },
        {
            title: "Product name",
            widthClass: "w-[95%] !font-rocGroteskMedium  !border-slate-200",
        },
    ];

    const tableModalBody = product?.map((item, idx) => {
        const isSelected = selectedProduct?.filter((prod) => prod?._id === item?._id);
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                    ></div>
                ),
                isItemChecked: isSelected?.length === 0 ? false : true,
                onCheckBoxClick: () => {
                    handleSelectProduct(item);
                },
                cellClickAction: () => handleSelectProduct(item),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center h-full
                         border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        {item?.productImageDetails?.productAvatar ||
                        item?.productImageDetails?.productImages?.[0] ? (
                            <VendorAvatar
                                imageSrc={
                                    item?.productImageDetails?.productAvatar ||
                                    item?.productImageDetails?.productImages?.[0]
                                }
                                size={32}
                                name={item?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-sm text-slate-500"></i>
                            </div>
                        )}

                        <div className="">
                            <p className="text-gm-50"> {item?.productName}</p>
                            <p className="text-slate-500"> {item?.sku}</p>
                        </div>
                    </div>
                ),
                widthClass: "!border-b  !border-slate-200",
            },
        ];
    });

    useEffect(() => {
        if (productModal) {
            setDashboardContent(document.getElementById("scrollableTable"));
        }
    }, [productModal]);

    useEffect(() => {
        const parsedData = data?.map((item) => {
            return {
                ...item?.inventory,
                inventoryId: item?.inventoryId,
                productQuantity: item?.quantity,
                scrap: item?.scrap,
            };
        });

        setAddSelectedProduct(parsedData);
        setSelectedProduct(parsedData);
    }, [data?.length]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    useEffect(() => {
        dispatch(productionActions?.getNonFinishedInventory(10, page, debouncedProductSearch));
    }, [dispatch, debouncedProductSearch, page]);

    useEffect(() => {
        if (Boolean(fetchedNonFinishedInventorySuccess)) {
            setProduct((prev) =>
                page === 1
                    ? uniqBy([...fetchedNonFinishedInventorySuccess?.products], "_id")
                    : uniqBy([...prev, ...fetchedNonFinishedInventorySuccess?.products], "_id")
            );
            setPaginate({
                current: fetchedNonFinishedInventorySuccess?.pagination?.current,
                number_of_pages: fetchedNonFinishedInventorySuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedNonFinishedInventorySuccess]);
    // useEffect(() => {
    //     dispatch(inventoryActions.fetchInventoryList(10, page, debouncedProductSearch, "Finished Goods"));

    //     return () => {
    //         dispatch(inventoryActions.resetFetchInventoryList());
    //     };
    // }, [dispatch, debouncedProductSearch, page]);

    // useEffect(() => {
    //     if (Boolean(fetchedInventoryListSuccess)) {
    //         setProduct(fetchedInventoryListSuccess?.inventory?.data);
    //         setPaginate({
    //             current: fetchedInventoryListSuccess?.pagination?.current,
    //             number_of_pages: fetchedInventoryListSuccess?.pagination?.number_of_pages,
    //         });
    //     }
    // }, [fetchedInventoryListSuccess]);

    return (
        <div>
            <div className="mb-10">
                {addSelectedProduct?.length < 1 ? (
                    <div className="py-8 rounded-md border border-slate-300 border-dashed flex items-center justify-center">
                        <div className="flex flex-col items-center text-center w-[52%]">
                            <i className="ri-file-list-2-line text-[40px] text-slate-500 mb-4"></i>
                            <span className="block text-sm font-rocGroteskMedium mb-1">
                                No Recipe Components found
                            </span>
                            <span className="block text-sm font-rocGroteskMedium mb-5 text-slate-500">
                                No items have been added to the bill of materials yet. To get
                                started, click the "Create a BOM" button below and begin adding
                                components, materials, or product to build your bill of materials.
                            </span>
                            <Button
                                btnClassname="!px-4 !py-2 !w-fit"
                                btnText="Create Recipe"
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_inventory,
                                    AccountPermissions.update_inventory,
                                ]}
                                onClick={() => setProductModal(true)}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <span className="block text-sm font-rocGroteskMedium mb-5 text-slate-500">
                            You can adjust the quantity and scrap percentage of each component to
                            calculate the total quantity and cost of the recipe.
                        </span>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                saveRecipe();
                            }}
                            className="pb-3 pt-1"
                        >
                            <CustomTable
                                tableHeader={recipesTableHeader}
                                tableBody={recipesTableBody}
                                isScrollable={false}
                                isCellBordered={true}
                                // isCellSelectable={true}
                                // isAllSelectable={true}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-6"
                            />
                            <div className="flex items-center space-x-3">
                                <Button
                                    btnText="Add More Recipe Components"
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_inventory,
                                        AccountPermissions.update_inventory,
                                    ]}
                                    btnClassname="!bg-white !py-3 !pl-4 !pl-5 border border-slate-200 !text-[#142837] 
                                !text-[13px] !w-auto !mt-6"
                                    icon={<i className="ri-add-fill text-base text-gm-50"></i>}
                                    onClick={() => {
                                        setProductModal(true);
                                    }}
                                />

                                <Button
                                    btnText="Save"
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_inventory,
                                        AccountPermissions.update_inventory,
                                    ]}
                                    btnClassname=" !py-3 !pl-4 !pl-5 !text-[13px] !w-auto !mt-6"
                                    isLoading={updatingSingleProduct}
                                    disabled={updatingSingleProduct}
                                    type="submit"
                                />
                            </div>
                        </form>
                    </>
                )}
            </div>

            <ModalContainer
                open={productModal}
                // showCloseIcon={false}
                tailwindClassName="w-[90%] lg:w-[1000px]"
            >
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">
                            Add Raw Material to Product
                        </p>
                        <i
                            onClick={() => setProductModal(false)}
                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                        ></i>
                    </div>
                    <div className="my-8 mx-8  ">
                        <div className="">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onInput={handleProductDebouncedChange}
                                inputPlaceholder="Search "
                                inputClassName={
                                    "!h-[50px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-tl-md !rounded-tr-md !rounded-bl-none !rounded-br-none !h-[50px] !pl-[40px] !bg-white !border !border-slate-200 !border-b-[0.5px]"
                                }
                            />
                        </div>
                        {product?.length > 0 ? (
                            <div className="">
                                <div className="max-h-[300px] overflow-y-auto" id="scrollableTable">
                                    <CustomTable
                                        tableBody={tableModalBody}
                                        tableHeader={tableModalHeader}
                                        isAllSelectable={true}
                                        headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                        headerContainerClass="!bg-slate-50"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />

                                    {loadMore && fetchingNonFinishedInventory && (
                                        <div className="flex my-4 justify-center">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                    {paginate?.current === paginate?.number_of_pages && (
                                        <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                            End of list
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex my-10 text-slate-500  justify-center text-sm font-rocGroteskMedium h-40">
                                No product
                            </div>
                        )}
                    </div>
                    <div className=" px-8 py-4 flex gap-4 items-center justify-end border-t border-t-slate-200">
                        <div className="flex justify-end gap-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-6 !py-3 !bg-tradeally-neutral-20 !w-fit"
                                onClick={() => setProductModal(false)}
                                // disabled={addingNewStockAdjustment}
                            />
                            <Button
                                btnText="Add recipes"
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_inventory,
                                    AccountPermissions.update_inventory,
                                ]}
                                btnClassname="!px-6 !py-3 !w-fit"
                                onClick={() => {
                                    handleAddButton();
                                }}
                                // disabled={addingNewStockAdjustment}
                                // isLoading={addingNewStockAdjustment}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default Recipes;
