export const workforceTypes = {
    FETCH_AI_AGENTS_REQUEST: "FETCH_AI_AGENTS_REQUEST",
    FETCH_AI_AGENTS_SUCCESS: "FETCH_AI_AGENTS_SUCCESS",
    FETCH_AI_AGENTS_FAILURE: "FETCH_AI_AGENTS_FAILURE",
    FETCH_AI_TEAMMATES_REQUEST: "FETCH_AI_TEAMMATES_REQUEST",
    FETCH_AI_TEAMMATES_SUCCESS: "FETCH_AI_TEAMMATES_SUCCESS",
    FETCH_AI_TEAMMATES_FAILURE: "FETCH_AI_TEAMMATES_FAILURE",
    FETCH_SINGLE_AI_TEAMMATE_REQUEST: "FETCH_SINGLE_AI_TEAMMATE_REQUEST",
    FETCH_SINGLE_AI_TEAMMATE_SUCCESS: "FETCH_SINGLE_AI_TEAMMATE_SUCCESS",
    FETCH_SINGLE_AI_TEAMMATE_FAILURE: "FETCH_SINGLE_AI_TEAMMATE_FAILURE",
    CREATE_AI_TEAMMATE_REQUEST: "CREATE_AI_TEAMMATE_REQUEST",
    CREATE_AI_TEAMMATE_SUCCESS: "CREATE_AI_TEAMMATE_SUCCESS",
    CREATE_AI_TEAMMATE_FAILURE: "CREATE_AI_TEAMMATE_FAILURE",
    UPDATE_AI_TEAMMATE_REQUEST: "UPDATE_AI_TEAMMATE_REQUEST",
    UPDATE_AI_TEAMMATE_SUCCESS: "UPDATE_AI_TEAMMATE_SUCCESS",
    UPDATE_AI_TEAMMATE_FAILURE: "UPDATE_AI_TEAMMATE_FAILURE",
    FETCH_TASK_LIST_REQUEST: "FETCH_TASK_LIST_REQUEST",
    FETCH_TASK_LIST_SUCCESS: "FETCH_TASK_LIST_SUCCESS",
    FETCH_TASK_LIST_FAILURE: "FETCH_TASK_LIST_FAILURE",
    FETCH_SINGLE_TASK_REQUEST: "FETCH_SINGLE_TASK_REQUEST",
    FETCH_SINGLE_TASK_SUCCESS: "FETCH_SINGLE_TASK_SUCCESS",
    FETCH_SINGLE_TASK_FAILURE: "FETCH_SINGLE_TASK_FAILURE",
    UPDATE_TASK_SALES_ORDER_REQUEST: "UPDATE_TASK_SALES_ORDER_REQUEST",
    UPDATE_TASK_SALES_ORDER_SUCCESS: "UPDATE_TASK_SALES_ORDER_SUCCESS",
    UPDATE_TASK_SALES_ORDER_FAILURE: "UPDATE_TASK_SALES_ORDER_FAILURE",
    APPROVE_TASK_STEP_REQUEST: "APPROVE_TASK_STEP_REQUEST",
    APPROVE_TASK_STEP_SUCCESS: "APPROVE_TASK_STEP_SUCCESS",
    APPROVE_TASK_STEP_FAILURE: "APPROVE_TASK_STEP_FAILURE",
    RETRY_TASK_STEP_REQUEST: "RETRY_TASK_STEP_REQUEST",
    RETRY_TASK_STEP_SUCCESS: "RETRY_TASK_STEP_SUCCESS",
    RETRY_TASK_STEP_FAILURE: "RETRY_TASK_STEP_FAILURE",
    GIVE_TASK_STEP_FEEDBACK_REQUEST: "GIVE_TASK_STEP_FEEDBACK_REQUEST",
    GIVE_TASK_STEP_FEEDBACK_SUCCESS: "GIVE_TASK_STEP_FEEDBACK_SUCCESS",
    GIVE_TASK_STEP_FEEDBACK_FAILURE: "GIVE_TASK_STEP_FEEDBACK_FAILURE",
    FETCH_TASK_ANALYTICS_REQUEST: "FETCH_TASK_ANALYTICS_REQUEST",
    FETCH_TASK_ANALYTICS_SUCCESS: "FETCH_TASK_ANALYTICS_SUCCESS",
    FETCH_TASK_ANALYTICS_FAILURE: "FETCH_TASK_ANALYTICS_FAILURE",
    TEST_AI_AGENT_REQUEST: "TEST_AI_AGENT_REQUEST",
    TEST_AI_AGENT_SUCCESS: "TEST_AI_AGENT_SUCCESS",
    TEST_AI_AGENT_FAILURE: "TEST_AI_AGENT_FAILURE",
    GET_SINGLE_AI_AGENT_TASKS_REQUEST: "GET_SINGLE_AI_AGENT_TASKS_REQUEST",
    GET_SINGLE_AI_AGENT_TASKS_SUCCESS: "GET_SINGLE_AI_AGENT_TASKS_SUCCESS",
    GET_SINGLE_AI_AGENT_TASKS_FAILURE: "GET_SINGLE_AI_AGENT_TASKS_FAILURE",
    GET_SINGLE_AI_AGENT_TASK_STATS_REQUEST: "GET_SINGLE_AI_AGENT_TASK_STATS_REQUEST",
    GET_SINGLE_AI_AGENT_TASK_STATS_SUCCESS: "GET_SINGLE_AI_AGENT_TASK_STATS_SUCCESS",
    GET_SINGLE_AI_AGENT_TASK_STATS_FAILURE: "GET_SINGLE_AI_AGENT_TASK_STATS_FAILURE",
    GET_SINGLE_CAMPAIGN_REQUEST: "GET_SINGLE_CAMPAIGN_REQUEST",
    GET_SINGLE_CAMPAIGN_SUCCESS: "GET_SINGLE_CAMPAIGN_SUCCESS",
    GET_SINGLE_CAMPAIGN_FAILURE: "GET_SINGLE_CAMPAIGN_FAILURE",
    CREATE_CAMPAIGN_COMMENT_REQUEST: "CREATE_CAMPAIGN_COMMENT_REQUEST",
    CREATE_CAMPAIGN_COMMENT_SUCCESS: "CREATE_CAMPAIGN_COMMENT_SUCCESS",
    CREATE_CAMPAIGN_COMMENT_FAILURE: "CREATE_CAMPAIGN_COMMENT_FAILURE",
    UPDATE_CAMPAIGN_COMMENT_REQUEST: "UPDATE_CAMPAIGN_COMMENT_REQUEST",
    UPDATE_CAMPAIGN_COMMENT_SUCCESS: "UPDATE_CAMPAIGN_COMMENT_SUCCESS",
    UPDATE_CAMPAIGN_COMMENT_FAILURE: "UPDATE_CAMPAIGN_COMMENT_FAILURE",
    GET_CAMPAIGN_COMMENTS_REQUEST: "GET_CAMPAIGN_COMMENTS_REQUEST",
    GET_CAMPAIGN_COMMENTS_SUCCESS: "GET_CAMPAIGN_COMMENTS_SUCCESS",
    GET_CAMPAIGN_COMMENTS_FAILURE: "GET_CAMPAIGN_COMMENTS_FAILURE",
    GET_CAMPAIGN_SECTION_COMMENTS_REQUEST: "GET_CAMPAIGN_SECTION_COMMENTS_REQUEST",
    GET_CAMPAIGN_SECTION_COMMENTS_SUCCESS: "GET_CAMPAIGN_SECTION_COMMENTS_SUCCESS",
    GET_CAMPAIGN_SECTION_COMMENTS_FAILURE: "GET_CAMPAIGN_SECTION_COMMENTS_FAILURE",
    GET_CAMPAIGN_COMMENT_REPLY_REQUEST: "GET_CAMPAIGN_COMMENT_REPLY_REQUEST",
    GET_CAMPAIGN_COMMENT_REPLY_SUCCESS: "GET_CAMPAIGN_COMMENT_REPLY_SUCCESS",
    GET_CAMPAIGN_COMMENT_REPLY_FAILURE: "GET_CAMPAIGN_COMMENT_REPLY_FAILURE",
    CREATE_SPS_COMMERCE_CONNECTION_REQUEST: "CREATE_SPS_COMMERCE_CONNECTION_REQUEST",
    CREATE_SPS_COMMERCE_CONNECTION_SUCCESS: "CREATE_SPS_COMMERCE_CONNECTION_SUCCESS",
    CREATE_SPS_COMMERCE_CONNECTION_FAILURE: "CREATE_SPS_COMMERCE_CONNECTION_FAILURE",
    GET_CAMPAIGN_ERRORS_REQUEST: "GET_CAMPAIGN_ERRORS_REQUEST",
    GET_CAMPAIGN_ERRORS_SUCCESS: "GET_CAMPAIGN_ERRORS_SUCCESS",
    GET_CAMPAIGN_ERRORS_FAILURE: "GET_CAMPAIGN_ERRORS_FAILURE",
    CORRECT_CAMPAIGN_ERROR_REQUEST: "CORRECT_CAMPAIGN_ERROR_REQUEST",
    CORRECT_CAMPAIGN_ERROR_SUCCESS: "CORRECT_CAMPAIGN_ERROR_SUCCESS",
    CORRECT_CAMPAIGN_ERROR_FAILURE: "CORRECT_CAMPAIGN_ERROR_FAILURE",
    GET_AI_NOTIFICATIONS_REQUEST: "GET_AI_NOTIFICATIONS_REQUEST",
    GET_AI_NOTIFICATIONS_SUCCESS: "GET_AI_NOTIFICATIONS_SUCCESS",
    GET_AI_NOTIFICATIONS_FAILURE: "GET_AI_NOTIFICATIONS_FAILURE",
    MARK_ALL_AI_NOTIFICATIONS_AS_READ_REQUEST: "MARK_ALL_AI_NOTIFICATIONS_AS_READ_REQUEST",
    MARK_ALL_AI_NOTIFICATIONS_AS_READ_SUCCESS: "MARK_ALL_AI_NOTIFICATIONS_AS_READ_SUCCESS",
    MARK_ALL_AI_NOTIFICATIONS_AS_READ_FAILURE: "MARK_ALL_AI_NOTIFICATIONS_AS_READ_FAILURE",
};
