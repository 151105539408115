import { workforceTypes } from "../types";

const initialState = {
    fetchingAiAgents: false,
    fetchAiAgentSuccess: null,
    fetchAiAgentFailure: null,
    fetchingAiTeammates: false,
    fetchAiTeammatesSuccess: null,
    fetchAiTeammatesFailure: null,
    creatingAiTeammate: false,
    createAiTeammateSuccess: null,
    createAiTeammateFailure: null,
    fetchingTaskList: false,
    fetchTaskListSuccess: null,
    fetchTaskListFailure: null,
    fetchingSingleTask: false,
    fetchSingleTaskSuccess: null,
    fetchSingleTaskFailure: null,
    updatingTaskSalesOrder: false,
    updateTaskSalesOrderSuccess: null,
    updateTaskSalesOrderFailure: null,
    approvingTaskStep: false,
    approveTaskStepSuccess: null,
    approveTaskStepFailure: null,
    retryingTaskStep: false,
    retryTaskStepSuccess: null,
    retryTaskStepFailure: null,
    givingTaskStepFeedback: false,
    giveTaskStepFeedbackSuccess: null,
    giveTaskStepFeedbackFailure: null,
    fetchingTaskAnalytics: false,
    fetchTaskAnalyticsSuccess: null,
    fetchTaskAnalyticsFailure: null,
    fetchingSingleAiTeammate: false,
    fetchSingleAiTeammateSuccess: null,
    fetchSingleAiTeammateFailure: null,
    updatingAiTeammate: false,
    updateAiTeammateSuccess: null,
    updateAiTeammateFailure: null,
    testingAiTeammate: false,
    testAiTeammateSuccess: null,
    testAiTeammateFailure: null,
    fetchingAiAgentTasks: false,
    fetchAiAgentTasksSuccess: null,
    fetchAiAgentTasksFailure: null,
    fetchingAiAgentTaskStats: false,
    fetchAiAgentTaskStatsSuccess: null,
    fetchAiAgentTaskStatsFailure: null,
    creatingCampaignComment: false,
    createCampaignCommentSuccess: null,
    createCampaignCommentFailure: null,
    updatingCampaignComment: false,
    updateCampaignCommentSuccess: null,
    updateCampaignCommentFailure: null,
    fetchingCampaignComment: false,
    fetchCampaignCommentSuccess: null,
    fetchCampaignCommentFailure: null,
    fetchingCampaignSectionComments: false,
    fetchCampaignSectionCommentsSuccess: null,
    fetchCampaignSectionCommentsFailure: null,
    fetchingCampaignCommentReplies: false,
    fetchCampaignCommentRepliesSuccess: null,
    fetchCampaignCommentRepliesFailure: null,
    creatingSpsCommerceConnection: false,
    createSpsCommerceConnectionSuccess: null,
    createSpsCommerceConnectionFailure: null,
    fetchingSingleCampaign: false,
    fetchSingleCampaignSuccess: null,
    fetchSingleCampaignFailure: null,
    fetchingCampaignErrors: false,
    fetchCampaignErrorsSuccess: null,
    fetchCampaignErrorsFailure: null,
    correctingCampaignError: false,
    correctCampaignErrorSuccess: null,
    correctCampaignErrorFailure: null,
    fetchingAiNotifications: false,
    fetchAiNotificationsSuccess: null,
    fetchAiNotificationsFailure: null,
    markingAllAiNotificationsAsRead: false,
    markAllAiNotificationsAsReadSuccess: null,
    markAllAiNotificationsAsReadFailure: null,
};

export const workforce = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case workforceTypes.MARK_ALL_AI_NOTIFICATIONS_AS_READ_REQUEST:
            return {
                ...state,
                markingAllAiNotificationsAsRead: true,
                markAllAiNotificationsAsReadSuccess: null,
                markAllAiNotificationsAsReadFailure: null,
            };
        case workforceTypes.MARK_ALL_AI_NOTIFICATIONS_AS_READ_SUCCESS:
            return {
                ...state,
                markingAllAiNotificationsAsRead: false,
                markAllAiNotificationsAsReadSuccess: action.payload,
                markAllAiNotificationsAsReadFailure: null,
            };
        case workforceTypes.MARK_ALL_AI_NOTIFICATIONS_AS_READ_FAILURE:
            return {
                ...state,
                markingAllAiNotificationsAsRead: false,
                markAllAiNotificationsAsReadSuccess: null,
                markAllAiNotificationsAsReadFailure: action.payload,
            };

        case workforceTypes.GET_AI_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                fetchingAiNotifications: true,
                fetchAiNotificationsSuccess: null,
                fetchAiNotificationsFailure: null,
            };
        case workforceTypes.GET_AI_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                fetchingAiNotifications: false,
                fetchAiNotificationsSuccess: action.payload,
                fetchAiNotificationsFailure: null,
            };
        case workforceTypes.GET_AI_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                fetchingAiNotifications: false,
                fetchAiNotificationsSuccess: null,
                fetchAiNotificationsFailure: action.payload,
            };

        case workforceTypes.CREATE_SPS_COMMERCE_CONNECTION_REQUEST:
            return {
                ...state,
                creatingSpsCommerceConnection: true,
                createSpsCommerceConnectionSuccess: null,
                createSpsCommerceConnectionFailure: null,
            };
        case workforceTypes.CREATE_SPS_COMMERCE_CONNECTION_SUCCESS:
            return {
                ...state,
                creatingSpsCommerceConnection: false,
                createSpsCommerceConnectionSuccess: action.payload,
                createSpsCommerceConnectionFailure: null,
            };
        case workforceTypes.CREATE_SPS_COMMERCE_CONNECTION_FAILURE:
            return {
                ...state,
                creatingSpsCommerceConnection: false,
                createSpsCommerceConnectionSuccess: null,
                createSpsCommerceConnectionFailure: action.payload,
            };

        case workforceTypes.CORRECT_CAMPAIGN_ERROR_REQUEST:
            return {
                ...state,
                correctingCampaignError: true,
                correctCampaignErrorSuccess: null,
                correctCampaignErrorFailure: null,
            };
        case workforceTypes.CORRECT_CAMPAIGN_ERROR_SUCCESS:
            return {
                ...state,
                correctingCampaignError: false,
                correctCampaignErrorSuccess: action.payload,
                correctCampaignErrorFailure: null,
            };
        case workforceTypes.CORRECT_CAMPAIGN_ERROR_FAILURE:
            return {
                ...state,
                correctingCampaignError: false,
                correctCampaignErrorSuccess: null,
                correctCampaignErrorFailure: action.payload,
            };
        case workforceTypes.GET_CAMPAIGN_ERRORS_REQUEST:
            return {
                ...state,
                fetchingCampaignErrors: true,
                fetchCampaignErrorsSuccess: null,
                fetchCampaignErrorsFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_ERRORS_SUCCESS:
            return {
                ...state,
                fetchingCampaignErrors: false,
                fetchCampaignErrorsSuccess: action.payload,
                fetchCampaignErrorsFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_ERRORS_FAILURE:
            return {
                ...state,
                fetchingCampaignErrors: false,
                fetchCampaignErrorsSuccess: null,
                fetchCampaignErrorsFailure: action.payload,
            };

        case workforceTypes.GET_SINGLE_CAMPAIGN_REQUEST:
            return {
                ...state,
                fetchingSingleCampaign: true,
                fetchSingleCampaignSuccess: null,
                fetchSingleCampaignFailure: null,
            };
        case workforceTypes.GET_SINGLE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                fetchingSingleCampaign: false,
                fetchSingleCampaignSuccess: action.payload,
                fetchSingleCampaignFailure: null,
            };
        case workforceTypes.GET_SINGLE_CAMPAIGN_FAILURE:
            return {
                ...state,
                fetchingSingleCampaign: false,
                fetchSingleCampaignSuccess: null,
                fetchSingleCampaignFailure: action.payload,
            };

        case workforceTypes.GET_CAMPAIGN_COMMENT_REPLY_REQUEST:
            return {
                ...state,
                fetchingCampaignCommentReplies: true,
                fetchCampaignCommentRepliesSuccess: null,
                fetchCampaignCommentRepliesFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_COMMENT_REPLY_SUCCESS:
            return {
                ...state,
                fetchingCampaignCommentReplies: false,
                fetchCampaignCommentRepliesSuccess: action.payload,
                fetchCampaignCommentRepliesFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_COMMENT_REPLY_FAILURE:
            return {
                ...state,
                fetchingCampaignCommentReplies: false,
                fetchCampaignCommentRepliesSuccess: null,
                fetchCampaignCommentRepliesFailure: action.payload,
            };

        case workforceTypes.GET_CAMPAIGN_SECTION_COMMENTS_REQUEST:
            return {
                ...state,
                fetchingCampaignSectionComments: true,
                fetchCampaignSectionCommentsSuccess: null,
                fetchCampaignSectionCommentsFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_SECTION_COMMENTS_SUCCESS:
            return {
                ...state,
                fetchingCampaignSectionComments: false,
                fetchCampaignSectionCommentsSuccess: action.payload,
                fetchCampaignSectionCommentsFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_SECTION_COMMENTS_FAILURE:
            return {
                ...state,
                fetchingCampaignSectionComments: false,
                fetchCampaignSectionCommentsSuccess: null,
                fetchCampaignSectionCommentsFailure: action.payload,
            };

        case workforceTypes.GET_CAMPAIGN_COMMENTS_REQUEST:
            return {
                ...state,
                fetchingCampaignComment: true,
                fetchCampaignCommentSuccess: null,
                fetchCampaignCommentFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_COMMENTS_SUCCESS:
            return {
                ...state,
                fetchingCampaignComment: false,
                fetchCampaignCommentSuccess: action.payload,
                fetchCampaignCommentFailure: null,
            };
        case workforceTypes.GET_CAMPAIGN_COMMENTS_FAILURE:
            return {
                ...state,
                fetchingCampaignComment: false,
                fetchCampaignCommentSuccess: null,
                fetchCampaignCommentFailure: action.payload,
            };

        case workforceTypes.UPDATE_CAMPAIGN_COMMENT_REQUEST:
            return {
                ...state,
                updatingCampaignComment: true,
                updateCampaignCommentSuccess: null,
                updateCampaignCommentFailure: null,
            };
        case workforceTypes.UPDATE_CAMPAIGN_COMMENT_SUCCESS:
            return {
                ...state,
                updatingCampaignComment: false,
                updateCampaignCommentSuccess: action.payload,
                updateCampaignCommentFailure: null,
            };
        case workforceTypes.UPDATE_CAMPAIGN_COMMENT_FAILURE:
            return {
                ...state,
                updatingCampaignComment: false,
                updateCampaignCommentSuccess: null,
                updateCampaignCommentFailure: action.payload,
            };

        case workforceTypes.CREATE_CAMPAIGN_COMMENT_REQUEST:
            return {
                ...state,
                creatingCampaignComment: true,
                createCampaignCommentSuccess: null,
                createCampaignCommentFailure: null,
            };
        case workforceTypes.CREATE_CAMPAIGN_COMMENT_SUCCESS:
            return {
                ...state,
                creatingCampaignComment: false,
                createCampaignCommentSuccess: action.payload,
                createCampaignCommentFailure: null,
            };
        case workforceTypes.CREATE_CAMPAIGN_COMMENT_FAILURE:
            return {
                ...state,
                creatingCampaignComment: false,
                createCampaignCommentSuccess: null,
                createCampaignCommentFailure: action.payload,
            };

        case workforceTypes.GET_SINGLE_AI_AGENT_TASK_STATS_REQUEST:
            return {
                ...state,
                fetchingAiAgentTaskStats: true,
                fetchAiAgentTaskStatsSuccess: null,
                fetchAiAgentTaskStatsFailure: null,
            };
        case workforceTypes.GET_SINGLE_AI_AGENT_TASK_STATS_SUCCESS:
            return {
                ...state,
                fetchingAiAgentTaskStats: false,
                fetchAiAgentTaskStatsSuccess: action.payload,
                fetchAiAgentTaskStatsFailure: null,
            };
        case workforceTypes.GET_SINGLE_AI_AGENT_TASK_STATS_FAILURE:
            return {
                ...state,
                fetchingAiAgentTaskStats: false,
                fetchAiAgentTaskStatsSuccess: null,
                fetchAiAgentTaskStatsFailure: action.payload,
            };

        case workforceTypes.GET_SINGLE_AI_AGENT_TASKS_REQUEST:
            return {
                ...state,
                fetchingAiAgentTasks: true,
                fetchAiAgentTasksSuccess: null,
                fetchAiAgentTasksFailure: null,
            };
        case workforceTypes.GET_SINGLE_AI_AGENT_TASKS_SUCCESS:
            return {
                ...state,
                fetchingAiAgentTasks: false,
                fetchAiAgentTasksSuccess: action.payload,
                fetchAiAgentTasksFailure: null,
            };
        case workforceTypes.GET_SINGLE_AI_AGENT_TASKS_FAILURE:
            return {
                ...state,
                fetchingAiAgentTasks: false,
                fetchAiAgentTasksSuccess: null,
                fetchAiAgentTasksFailure: action.payload,
            };

        case workforceTypes.TEST_AI_AGENT_REQUEST:
            return {
                ...state,
                testingAiTeammate: true,
                testAiTeammateSuccess: null,
                testAiTeammateFailure: null,
            };
        case workforceTypes.TEST_AI_AGENT_SUCCESS:
            return {
                ...state,
                testingAiTeammate: false,
                testAiTeammateSuccess: action.payload,
                testAiTeammateFailure: null,
            };
        case workforceTypes.TEST_AI_AGENT_FAILURE:
            return {
                ...state,
                testingAiTeammate: false,
                testAiTeammateSuccess: null,
                testAiTeammateFailure: action.payload,
            };

        case workforceTypes.UPDATE_AI_TEAMMATE_REQUEST:
            return {
                ...state,
                updatingAiTeammate: true,
                updateAiTeammateSuccess: null,
                updateAiTeammateFailure: null,
            };
        case workforceTypes.UPDATE_AI_TEAMMATE_SUCCESS:
            return {
                ...state,
                updatingAiTeammate: false,
                updateAiTeammateSuccess: action.payload,
                updateAiTeammateFailure: null,
            };
        case workforceTypes.UPDATE_AI_TEAMMATE_FAILURE:
            return {
                ...state,
                updatingAiTeammate: false,
                updateAiTeammateSuccess: null,
                updateAiTeammateFailure: action.payload,
            };

        case workforceTypes.FETCH_SINGLE_AI_TEAMMATE_REQUEST:
            return {
                ...state,
                fetchingSingleAiTeammate: true,
                fetchSingleAiTeammateSuccess: null,
                fetchSingleAiTeammateFailure: null,
            };
        case workforceTypes.FETCH_SINGLE_AI_TEAMMATE_SUCCESS:
            return {
                ...state,
                fetchingSingleAiTeammate: false,
                fetchSingleAiTeammateSuccess: action.payload,
                fetchSingleAiTeammateFailure: null,
            };
        case workforceTypes.FETCH_SINGLE_AI_TEAMMATE_FAILURE:
            return {
                ...state,
                fetchingSingleAiTeammate: false,
                fetchSingleAiTeammateSuccess: null,
                fetchSingleAiTeammateFailure: action.payload,
            };

        case workforceTypes.FETCH_TASK_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingTaskAnalytics: true,
                fetchTaskAnalyticsSuccess: null,
                fetchTaskAnalyticsFailure: null,
            };
        case workforceTypes.FETCH_TASK_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingTaskAnalytics: false,
                fetchTaskAnalyticsSuccess: action.payload,
                fetchTaskAnalyticsFailure: null,
            };
        case workforceTypes.FETCH_TASK_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingTaskAnalytics: false,
                fetchTaskAnalyticsSuccess: null,
                fetchTaskAnalyticsFailure: action.payload,
            };

        case workforceTypes.GIVE_TASK_STEP_FEEDBACK_REQUEST:
            return {
                ...state,
                givingTaskStepFeedback: true,
                giveTaskStepFeedbackSuccess: null,
                giveTaskStepFeedbackFailure: null,
            };
        case workforceTypes.GIVE_TASK_STEP_FEEDBACK_SUCCESS:
            return {
                ...state,
                givingTaskStepFeedback: false,
                giveTaskStepFeedbackSuccess: action.payload,
                giveTaskStepFeedbackFailure: null,
            };
        case workforceTypes.GIVE_TASK_STEP_FEEDBACK_FAILURE:
            return {
                ...state,
                givingTaskStepFeedback: false,
                giveTaskStepFeedbackSuccess: null,
                giveTaskStepFeedbackFailure: action.payload,
            };

        case workforceTypes.RETRY_TASK_STEP_REQUEST:
            return {
                ...state,
                retryingTaskStep: true,
                retryTaskStepSuccess: null,
                retryTaskStepFailure: null,
            };
        case workforceTypes.RETRY_TASK_STEP_SUCCESS:
            return {
                ...state,
                retryingTaskStep: false,
                retryTaskStepSuccess: action.payload,
                retryTaskStepFailure: null,
            };
        case workforceTypes.RETRY_TASK_STEP_FAILURE:
            return {
                ...state,
                retryingTaskStep: false,
                retryTaskStepSuccess: null,
                retryTaskStepFailure: action.payload,
            };

        case workforceTypes.APPROVE_TASK_STEP_REQUEST:
            return {
                ...state,
                approvingTaskStep: true,
                approveTaskStepSuccess: null,
                approveTaskStepFailure: null,
            };
        case workforceTypes.APPROVE_TASK_STEP_SUCCESS:
            return {
                ...state,
                approvingTaskStep: false,
                approveTaskStepSuccess: action.payload,
                approveTaskStepFailure: null,
            };
        case workforceTypes.APPROVE_TASK_STEP_FAILURE:
            return {
                ...state,
                approvingTaskStep: false,
                approveTaskStepSuccess: null,
                approveTaskStepFailure: action.payload,
            };

        case workforceTypes.UPDATE_TASK_SALES_ORDER_REQUEST:
            return {
                ...state,
                updatingTaskSalesOrder: true,
                updateTaskSalesOrderSuccess: null,
                updateTaskSalesOrderFailure: null,
            };
        case workforceTypes.UPDATE_TASK_SALES_ORDER_SUCCESS:
            return {
                ...state,
                updatingTaskSalesOrder: false,
                updateTaskSalesOrderSuccess: action.payload,
                updateTaskSalesOrderFailure: null,
            };
        case workforceTypes.UPDATE_TASK_SALES_ORDER_FAILURE:
            return {
                ...state,
                updatingTaskSalesOrder: false,
                updateTaskSalesOrderSuccess: null,
                updateTaskSalesOrderFailure: action.payload,
            };

        case workforceTypes.FETCH_SINGLE_TASK_REQUEST:
            return {
                ...state,
                fetchingSingleTask: true,
                fetchSingleTaskSuccess: null,
                fetchSingleTaskFailure: null,
            };
        case workforceTypes.FETCH_SINGLE_TASK_SUCCESS:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchSingleTaskSuccess: action.payload,
                fetchSingleTaskFailure: null,
            };
        case workforceTypes.FETCH_SINGLE_TASK_FAILURE:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchSingleTaskSuccess: null,
                fetchSingleTaskFailure: action.payload,
            };

        case workforceTypes.FETCH_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingTaskList: true,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: null,
            };
        case workforceTypes.FETCH_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: action.payload,
                fetchTaskListFailure: null,
            };
        case workforceTypes.FETCH_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: action.payload,
            };

        case workforceTypes.FETCH_AI_AGENTS_REQUEST:
            return {
                ...state,
                fetchingAiAgents: true,
                fetchAiAgentSuccess: null,
                fetchAiAgentFailure: null,
            };
        case workforceTypes.FETCH_AI_AGENTS_SUCCESS:
            return {
                ...state,
                fetchingAiAgents: false,
                fetchAiAgentSuccess: action.payload,
                fetchAiAgentFailure: null,
            };
        case workforceTypes.FETCH_AI_AGENTS_FAILURE:
            return {
                ...state,
                fetchingAiAgents: false,
                fetchAiAgentSuccess: null,
                fetchAiAgentFailure: action.payload,
            };

        case workforceTypes.FETCH_AI_TEAMMATES_REQUEST:
            return {
                ...state,
                fetchingAiTeammates: true,
                fetchAiTeammatesSuccess: null,
                fetchAiTeammatesFailure: null,
            };
        case workforceTypes.FETCH_AI_TEAMMATES_SUCCESS:
            return {
                ...state,
                fetchingAiTeammates: false,
                fetchAiTeammatesSuccess: action.payload,
                fetchAiTeammatesFailure: null,
            };
        case workforceTypes.FETCH_AI_TEAMMATES_FAILURE:
            return {
                ...state,
                fetchingAiTeammates: false,
                fetchAiTeammatesSuccess: null,
                fetchAiTeammatesFailure: action.payload,
            };

        case workforceTypes.CREATE_AI_TEAMMATE_REQUEST:
            return {
                ...state,
                creatingAiTeammate: true,
                createAiTeammateSuccess: null,
                createAiTeammateFailure: null,
            };
        case workforceTypes.CREATE_AI_TEAMMATE_SUCCESS:
            return {
                ...state,
                creatingAiTeammate: false,
                createAiTeammateSuccess: action.payload,
                createAiTeammateFailure: null,
            };
        case workforceTypes.CREATE_AI_TEAMMATE_FAILURE:
            return {
                ...state,
                creatingAiTeammate: false,
                createAiTeammateSuccess: null,
                createAiTeammateFailure: action.payload,
            };
        default:
            return state;
    }
};
