import React, { useState } from "react";
import SalesOrderDetails from "components/views/Synth2.0/Order/SalesOrderDetails";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import useGetSingleSalesOrder from "hooks/salesHooks/useGetSingleSalesOrder";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import moment from "moment";
import PageFrame from "components/layout/PageFrame";

const OrderDetails = () => {
    const navigate = useNavigate();
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openApprovalSuccessModal, setOpenApprovalSuccessModal] = useState(false);
    const [openRejectModal, setOpentRejectModal] = useState(false);
    const [openRejectionReasonModal, setOpentRejectionReasonModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");

    const { data: orderDetail, isFetching } = useGetSingleSalesOrder();

    const tableHeader = [
        { title: "Category", widthClass: "w-[16.7%]" },
        { title: "Product name", widthClass: "w-[16.7%]" },
        { title: "Qty", widthClass: "w-[16.7%]" },
        { title: "Discount", widthClass: "w-[16.6%]" },
        { title: "Price per unit", widthClass: "w-[16.6%]" },
        { title: "Sub total", widthClass: "w-[16.6%]" },
    ];

    const tableBody = [1, 2, 3]?.map((order, idx) => [
        {
            content: (
                <div className={`py-5 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    <p className="text-slate-500 ">Fruits & Veget...</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    Oranges
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    50
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    5%
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    50
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    $500
                </div>
            ),
        },
    ]);

    return (
        <PageFrame isLoading={isFetching}>
            <SalesOrderDetails
                title="Sales order details"
                onApprove={() => setOpenApproveModal(true)}
                onReject={() => setOpentRejectModal(true)}
                channelImg={displayProductChannelIcon(orderDetail?.channel, "!w-5 !h-5")}
                channel={
                    orderDetail?.channel?.toLowerCase()?.includes("trackstar")
                        ? orderDetail?.channel?.split(".")[1]
                        : orderDetail?.channel
                }
                orderDate={moment(orderDetail?.saleDate)?.format("MMM DD, YYYY")}
                customerPO={orderDetail?.taId || orderDetail?.orderId}
                deliveryDate={
                    orderDetail?.deliveryDate
                        ? moment(orderDetail?.deliveryDate)?.format("MMM DD, YYYY")
                        : "N/A"
                }
                products={orderDetail?.products}
                currency={orderDetail?.currency}
                subTotal={orderDetail?.subTotal}
                discount="d-5%"
                total={orderDetail?.total}
                orderStatus={orderDetail?.status}
                customerName={
                    orderDetail?.customerDetails?.company ||
                    `${orderDetail?.customerDetails?.first_name || ""} ${
                        orderDetail?.customerDetails?.last_name || ""
                    }`
                }
                haulage="d-FEDex"
                email={orderDetail?.customerDetails?.email}
                thirdParty="d-Amazon"
                phone={orderDetail?.customerDetails?.phone}
                billAddress={
                    orderDetail?.shipping?.address
                        ? `${orderDetail?.shipping?.address?.city || ""} ${
                              orderDetail?.shipping?.address?.country
                          }`
                        : orderDetail?.customerDetails?.address1
                }
                salesId={orderDetail?._id}
            />
            <ModalContainer
                open={openApproveModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApproveModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-16 h-16 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Approved
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    The purchase request has been approved by you, kindly proceed to
                                    the next approval level.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApproveModal(false);
                                }}
                                disabled={false}
                            />
                            <Button
                                onClick={() => {}}
                                btnText={"Proceed"}
                                type={"button"}
                                disabled={false}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openRejectModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Caution_sign_g1u9nv.svg"
                                alt="caution"
                                className="w-[60px] h-[53px]"
                            />
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Rejected
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    This sales order will be rejected by you, kindly add a comment
                                    to complete rejection
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectModal(false);
                                }}
                                disabled={false}
                            />
                            <Button
                                onClick={() => setOpentRejectionReasonModal(true)}
                                btnText={"Add Comment"}
                                type={"button"}
                                btnClassname={"!bg-r-50 !text-white"}
                                disabled={false}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openApprovalSuccessModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApprovalSuccessModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-16 h-16 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Success
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    Request forwarded to next approver successfully. Approver will
                                    be notified of the pending task.Your request is pending the
                                    approval of the next level and you will be notified upon
                                    completion
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApprovalSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openRejectionReasonModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectionReasonModal(false);
                    setRejectionReason("");
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center">
                            <div className="mb-8">
                                <h3 className="text-2xl mb-2.5 text-black font-rocGroteskMedium">
                                    Provide a comment to reject this Order
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    To ensure transparency and compliance, please provide a brief
                                    comment explaining your decision to rejecting this Order.
                                </p>
                            </div>

                            <div className="w-full">
                                <TextareaInput
                                    name={"rejectionReason"}
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    rows={5}
                                    placeholder={"Comment"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>

                        <div className="mt-5 flex flex-col items-center gap-2.5">
                            <Button
                                onClick={() => {}}
                                btnText={"Send"}
                                type={"button"}
                                disabled={false}
                                isLoading={false}
                            />
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-transparent !text-gm-50 !w-fit"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectionReasonModal(false);
                                    setRejectionReason("");
                                }}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default OrderDetails;
