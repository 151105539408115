import Button from "components/common/Button";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import CustomToast from "components/common/CustomToast";
import Dropdown from "components/common/Dropdown";
import EmptyStateComp from "components/common/EmptyStateComp";
import TextInput from "components/common/InputField/TextInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import MultipleLineChart from "components/common/MultipleLineChart";
import SynthTooltip from "components/common/SynthTooltip";
import { AnalyticsCard } from "components/views/Ldb/Dashboard/BusinessAnalytics/Card";
import { formatMoney, getDashboardChartData } from "helpers";
import { getWeeksInMonth } from "helpers/dateTime";
import { monifyInputField } from "helpers/monifyInputField";
import useGetDashboardConfigList from "hooks/Dashboard/useGetDashboardConfigList";
import useGetDashboardGWM from "hooks/Dashboard/useGetDashboardGWM";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { flattenDeep, truncate } from "lodash";
import millify from "millify";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import ResponsivePaginationComponent from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { Tooltip } from "react-tooltip";
import { dashboardActions } from "redux/Ldb/actions";
import { productCategories } from "variables/productCategories";
import ReactEcharts from "echarts-for-react";
// import * as echarts from "echarts";
import useGetBundleDashboardGWM from "hooks/Dashboard/useGetBundleDashboardGWM";
import useGetBundleDashboardConfigList from "hooks/Dashboard/useGetBundleDashboardConfigList";
import useGetBundleDashboardGWMTooltip from "hooks/Dashboard/useGetBundleDashboardGWMTooltip";
import { getCurrentStartAndEndDate } from "helpers/getCurrentStartAndEndDate";
import Loader from "components/common/Loader";
import { AccountPermissions } from "types/permissions";
import VendorAvatar from "components/common/VendorAvatar";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";

const BundledProductForecast = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [activePeriod, setActivePeriod] = useState<number>(0);
    const [inventoryModal, setInventoryModal] = useState<boolean>(false);
    const [profitMarginModal, setProfitMarginModal] = useState<boolean>(false);
    const [durationFilter, setDurationFilter] = useState<string>("This year");
    const [modalDurationFilter, setModalDurationFilter] = useState<string>("This year");
    const [customFilter, setCustomFilter] = useState<string>("");
    const [startAndEndDate, setStartAndEndDate] = useState<string[]>([]);
    const [chartFilter, setChartFilter] = useState<string>("yearly");
    const [customValue, customOnChange] = useState("");
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
    const [categoryFilter, setCategoryFilter] = useState<string>("");
    const [productFilter, setProductFilter] = useState<string[]>([]);
    const [modalProductFilter, setModalProductFilter] = useState<string>("");
    const [channelFilter, setChannelFilter] = useState<string>("");
    const [modalChannelFilter, setModalChannelFilter] = useState<string>("");
    const [monthlyConfigChange, setMonthlyConfigChange] = useState<{ [key: string]: any }[]>([]);
    const [weeklyConfigChange, setWeeklyConfigChange] = useState<{ [key: string]: any }[]>([]);
    const [chartData, setChartData] = useState<{ [key: string]: any }>([]);
    const [trackPerformance, setTrackPerformance] = useState<boolean>(true);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [cacheData, setCacheData] = useState({});
    const [currYAxisLabel, setCurrYAxisLabel] = useState("");

    const clearSearchRef = useRef(null);
    const customRef = useRef(null);
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const currentDate = moment();
    const monthlyTab = chartData?.[0]?.filter((month) => {
        const monthDate = moment(month, "MMM YYYY"); // Parse the month using moment.js
        return monthDate.isSameOrAfter(currentDate, "month"); // Check if the month is the current month or a future month
    });

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const forecastTabs = [
        { id: 0, title: "Graph view" },
        { id: 1, title: "Table view" },
    ];

    const configureForecastOptions = [{ label: "General configuration", value: "table" }];

    const dateValues = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },
        {
            period: "custom",
            value: "custom",
        },
    ];

    const dispatch = useAppDispatch();
    const { addingForecastConfig, addForecastConfigSuccess } = useAppSelector(
        (state) => state.dashboard
    );

    const {
        data: gwmData,
        isFetching: gwmLoading,
        handleDateRangeChange,
        handleChannelChange,
        handleCategoryChange,
        handleProductChange,
        productIds,
        handleStartAndEndDateChange,
    } = useGetBundleDashboardGWM();

    const {
        data: gwmTooltip,
        isFetching: gwmTooltipLoading,
        setGwmDataTooltip,
        handleDateRangeChange: handleTooltipDateRangeChange,
        handleChannelChange: handleTooltipChannelChange,
        handleCategoryChange: handleTooltipCategoryChange,
        handleProductChange: handleTooltipProductChange,
        productIds: tooltipProductIds,
        handleStartAndEndDateChange: handleTooltipStartAndEndDateChange,
    } = useGetBundleDashboardGWMTooltip();

    const {
        data: productData,
        rawData,
        isFetching: productListLoading,
        productSearchList,
        handleDebouncedChange,
        handleDebouncedChangeTwo,
        handleSearch,
        handleSearchTwo,
        search,
        searchTwo,
        setSearch,
        setSearchTwo,
        setDebouncedSearch,
        setDebouncedSearchTwo,
        pagination,
        isLoadingMore,
    } = useGetProductList();

    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();

    const {
        data: configList,
        updatedPage,
        setUpdatedPage,
        pagination: configPagination,
        handleDateRangeChange: configHandleDateRangeChange,
        handleChannelChange: configHandleChannelChange,
        handleCategoryChange: configHandleCategoryChange,
        handleProductChange: configHandleProductChange,
        productIds: configProductIds,
    } = useGetBundleDashboardConfigList();

    const option = {
        grid: { top: 8, right: 0, bottom: 24, left: 0, containLabel: true },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow", // Crosshairs for bars
            },
            enterable: true,
            confine: true,
            formatter: function (params) {
                // Build the tooltip content dynamically

                const data = cacheData?.[chartFilter]?.[params?.[0]?.name] || gwmTooltip;
                const actualSalesTotalQty = data?.actualSales?.reduce(
                    (acc, curr) => acc + curr?.totalQty,
                    0
                );
                const forecastedSalesTotalQty = data?.forecastedSales?.reduce(
                    (acc, curr) => acc + curr?.totalQty,
                    0
                );

                return `
                    <div class=" rounded-lg w-[750px] max-h-[450px]">
                        <div class="font-rocGroteskMedium ">
                            <p class="text-base font-rocGroteskMedium mb-5 pb-1 border-b border-slate-100">
                                Sales and forecast metrics (${params?.[0]?.name})
                            </p>
                           
                            <div class="flex justify-between items-start">
                                <div class="w-1/2 text-slate-800 pr-[28px] border-r border-slate-200">
                                    <div class="flex justify-between items-center pb-1.5 border-b border-slate-100">
                                        <p class="text-[13px] ">Actual sales</p>
                                        <p class="font-bold text-[13px] flex items-center space-x-1.5">
                                            <span>$${formatMoney().format(
                                                data?.totalActualSales
                                            )}</span>
                                            <span class="text-[10px] text-g-60 px-1.5 h-[18px] rounded border border-g-25 flex items-center justify-center">${formatMoney().format(
                                                actualSalesTotalQty
                                            )} units</span>
                                        </p>
                                    </div>
                                    <div class="mt-[14px] space-y-[14px] max-h-[250px] overflow-y-auto">
                                        ${data?.actualSales
                                            ?.map((item) => {
                                                return `
                                                    <div>
                                                        <div class="flex justify-between items-center mb-1">
                                                            <p class="text-[13px] ">${truncate(
                                                                item?.inventory?.sku,
                                                                { length: 25 }
                                                            )}</p>
                                                            <p class="font-bold text-[13px] flex items-center space-x-1.5">
                                                                <span>$${formatMoney().format(
                                                                    item?.totalSales
                                                                )}</span>
                                                                <span class="text-[10px] text-g-60 px-1.5 h-[18px] rounded border border-g-25 flex items-center justify-center">${formatMoney().format(
                                                                    item?.totalQty
                                                                )} units</span>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <details class="bg-slate-50 border-[0.7px] border-b-25 p-1 rounded">
                                                                <summary class="text-[10px] text-b-50 flex items-center justify-between">
                                                                    <span>View product breakdown</span>
                                                                    <i class="ri-arrow-down-s-line text-[14px]"></i>
                                                                </summary>

                                                                <div class="mt-3 space-y-2">
                                                                
                                                                    ${item?.inventory?.bundleItems
                                                                        ?.map((product) => {
                                                                            return `
                                                                        <div class="flex justify-between space-x-1.5 items-center mb-1">
                                                                            <div>
                                                                                <p class="text-xs text-slate-500">${truncate(
                                                                                    product
                                                                                        ?.inventory
                                                                                        ?.productName,
                                                                                    { length: 20 }
                                                                                )}</p>
                                                                                <p class="text-[10px] text-slate-500">${truncate(
                                                                                    product
                                                                                        ?.inventory
                                                                                        ?.sku,
                                                                                    { length: 30 }
                                                                                )}</p>
                                                                            </div>
                                                                            <p class="font-bold text-[13px] flex items-center space-x-1.5">
                                                                                <span>$${
                                                                                    product
                                                                                        ?.inventory
                                                                                        ?.salesPrice
                                                                                        ?.amount
                                                                                        ? formatMoney().format(
                                                                                              product
                                                                                                  ?.inventory
                                                                                                  ?.salesPrice
                                                                                                  ?.amount *
                                                                                                  product?.quantity *
                                                                                                  item?.totalQty
                                                                                          )
                                                                                        : "N/A"
                                                                                }</span>
                                                                                <span class="text-[10px] text-g-60 px-1.5 h-[18px] rounded border border-g-25 flex items-center justify-center">${formatMoney().format(
                                                                                    product?.quantity *
                                                                                        item?.totalQty
                                                                                )} units</span>
                                                                            </p>
                                                                        </div>
                                                                    `;
                                                                        })
                                                                        ?.join("")}
                                                                </div>
                                                            </details>
                                                        </div>
                                                    </div>
                                            `;
                                            })
                                            ?.join("")}
                                        
                                    </div>
                                    
                                </div>

                                <div class="w-1/2 text-slate-800 pl-[28px] ">
                                    <div class="flex justify-between items-center pb-1.5 border-b border-slate-100">
                                        <p class="text-[13px] ">Forecasted sales</p>
                                        <p class="font-bold text-[13px] flex items-center space-x-1.5">
                                            <span>$${formatMoney().format(
                                                data?.totalForecastedSales
                                            )}</span>
                                            <span class="text-[10px] text-g-60 px-1.5 h-[18px] rounded border border-g-25 flex items-center justify-center">${formatMoney().format(
                                                forecastedSalesTotalQty
                                            )} units</span>
                                        </p>
                                    </div>
                                    <div class="mt-[14px] space-y-[14px] max-h-[250px] overflow-y-auto">
                                        ${data?.forecastedSales
                                            ?.map((item) => {
                                                return `
                                                    <div>
                                                        <div class="flex justify-between items-center mb-1">
                                                            <p class="text-[13px] ">${truncate(
                                                                item?.inventory?.sku,
                                                                { length: 25 }
                                                            )}</p>
                                                            <p class="font-bold text-[13px] flex items-center space-x-1.5">
                                                                <span>$${formatMoney().format(
                                                                    item?.totalSales
                                                                )}</span>
                                                                <span class="text-[10px] text-g-60 px-1.5 h-[18px] rounded border border-g-25 flex items-center justify-center">${formatMoney().format(
                                                                    item?.totalQty
                                                                )} units</span>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <details class="bg-slate-50 border-[0.7px] border-b-25 p-1 rounded">
                                                                <summary class="text-[10px] text-b-50 flex items-center justify-between">
                                                                    <span>View product breakdown</span>
                                                                    <i class="ri-arrow-down-s-line text-[14px]"></i>
                                                                </summary>

                                                                <div class="mt-3 space-y-2">
                                                                ${item?.inventory?.bundleItems
                                                                    ?.map((product) => {
                                                                        return `
                                                                        <div class="flex justify-between space-x-1.5 items-center mb-1">
                                                                            <div>
                                                                                <p class="text-xs text-slate-500">${truncate(
                                                                                    product
                                                                                        ?.inventory
                                                                                        ?.productName,
                                                                                    { length: 20 }
                                                                                )}</p>
                                                                                <p class="text-[10px] text-slate-500">${truncate(
                                                                                    product
                                                                                        ?.inventory
                                                                                        ?.sku,
                                                                                    { length: 30 }
                                                                                )}</p>
                                                                            </div>
                                                                            
                                                                            
                                                                            <p class="font-bold text-[13px] flex items-center space-x-1.5">
                                                                                <span>$${
                                                                                    product
                                                                                        ?.inventory
                                                                                        ?.salesPrice
                                                                                        ?.amount
                                                                                        ? formatMoney().format(
                                                                                              product
                                                                                                  ?.inventory
                                                                                                  ?.salesPrice
                                                                                                  ?.amount *
                                                                                                  product?.quantity *
                                                                                                  item?.totalQty
                                                                                          )
                                                                                        : "N/A"
                                                                                }</span>
                                                                                <span class="text-[10px] text-g-60 px-1.5 h-[18px] rounded border border-g-25 flex items-center justify-center">${formatMoney().format(
                                                                                    product?.quantity *
                                                                                        item?.totalQty
                                                                                )} units</span>
                                                                            </p>
                                                                        </div>
                                                                    `;
                                                                    })
                                                                    ?.join("")}
                                                                    
                                                                </div>
                                                            </details>
                                                        </div>
                                                    </div>
                                            `;
                                            })
                                            ?.join("")}
                                        
                                    </div>
                                    
                                </div>
                            </div>
                                
                        </div>
                    </div>
                `;
            },
            extraCssText: "pointer-events: auto;", // Prevent tooltip from disappearing on hover
        },
        animation: false,
        xAxis: {
            type: "category",
            data: chartData?.[0],
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: "${value}",
            },
        },
        series: [
            {
                name: "Forecast",
                type: "bar",
                data: chartData?.[2]?.map((item) => item?.value),
                // data: [45, 60, 50, 40, 70, 60, 50, 90, 80, 70, 60, 50],
                barWidth: "55%",
                itemStyle: {
                    color: "#CCCBF6", // Light blue for the forecast bars

                    borderRadius: [10, 10, 0, 0],
                },
                barGap: "-80%", // Overlapping bars
            },
            {
                name: "Actual",
                type: "bar",
                data: chartData?.[1]?.map((item) => item?.value),
                // data: [30, 40, 35, 30, 45, 40, 30, 55, 50, 45, 40, 35],
                barWidth: "55%",
                itemStyle: {
                    color: "#22214C", // Dark blue for the actual bars
                    borderRadius: [10, 10, 0, 0],
                },
            },
            // {
            //     name: "Configured",
            //     type: "bar",
            //     data: chartData?.[3]?.map((item) => item?.value),
            //     // data: [30, 40, 35, 30, 45, 40, 30, 55, 50, 45, 40, 35],
            //     barWidth: "55%",
            //     itemStyle: {
            //         color: "#00A5D0", // Dark blue for the actual bars
            //         borderRadius: [10, 10, 0, 0],
            //     },
            //     // barGap: "-0%", // Overlapping bars
            // },
        ],
    };

    const onEvents = {
        mouseover: (params) => {
            if (params.componentType === "series" && params.seriesType === "bar") {
                // Perform any custom action here
                const startOfMonth = moment(params?.name).startOf("month"); // Start of the current week
                const endOfMonth = moment(params?.name).endOf("month"); // End of the current week

                const startOfCurrMonth = moment().startOf("month");

                const startOfWeek = startOfCurrMonth
                    .clone()
                    .add(params?.dataIndex, "weeks")
                    .startOf("week");
                const endOfWeek = startOfWeek.clone().endOf("week");

                if (!cacheData?.[chartFilter]?.[params?.name]) {
                    setCurrYAxisLabel(params?.name);

                    handleTooltipStartAndEndDateChange(
                        chartFilter === "monthly"
                            ? [startOfWeek?.toDate(), endOfWeek?.toDate()]
                            : [startOfMonth?.toDate(), endOfMonth?.toDate()]
                    );
                }
            }
        },
    };

    const handleCategoryValue = (e) => {
        const { value } = e.target;
        setCategoryFilter(value);
        handleCategoryChange(value);
    };

    const handleDateChange = (e) => {
        const { value } = e.target;
        const [val, period] = value.split("-");
        // profitMarginModal || inventoryModal
        //     ? setModalDurationFilter(period)
        setDurationFilter(period);
        setCustomFilter(val);

        if (val !== "custom") {
            // if (!profitMarginModal && !inventoryModal) {
            handleDateRangeChange(val);
            setStartAndEndDate([]);
            setChartFilter(val);
            customOnChange("");
            // }

            // profitMarginModal ? setProductDateRange(val) : inventoryModal && setDateRange(val);
        } else {
            setShowCustomDate(true);
        }
    };

    const handleProductValue = (e) => {
        const { value } = e.target;

        const prodIdsCopy = [...productIds];
        const productFilterCopy = [...productFilter];
        const newValue = value?.split("-")[0];
        const newName = value?.split("-")[1];
        const isValuePresent = prodIdsCopy?.find((id) => id === newValue);

        if (isValuePresent) {
            handleProductChange(prodIdsCopy?.filter((id) => id !== newValue));
            setProductFilter(productFilterCopy?.filter((name) => name !== newName));
        } else {
            if (newValue === "") {
                handleProductChange([]);
                setProductFilter([]);
            } else {
                setProductFilter((prev) => [...prev, newName]);
                handleProductChange([...prodIdsCopy, newValue]);
            }
        }

        // if (!profitMarginModal && !inventoryModal) {
        handleCategoryChange("");
        setCategoryFilter("");
        // }

        // if (profitMarginModal || inventoryModal) {
        //     setModalProductFilter(value);
        // }
        // profitMarginModal || inventoryModal
        //     ? setModalProductFilter(value)
        //     : setProductFilter(value);
    };

    const handleChangeValue = (e) => {
        const { value } = e.target;
        // profitMarginModal || inventoryModal
        //     ? setModalChannelFilter(value)
        setChannelFilter(value);

        if (value === "All channels") {
            // profitMarginModal
            //     ? setProductChannel("")
            //     : inventoryModal
            //     ? setChannel("")
            handleChannelChange("");
        } else {
            // profitMarginModal
            //     ? setProductChannel(value)
            //     : inventoryModal
            //     ? setChannel(value)
            handleChannelChange(value);
        }
    };

    const handleSaveConfig = () => {
        const parsedData = [
            ...(chartFilter === "yearly" ? monthlyConfigChange : weeklyConfigChange),
        ]?.map((item) => {
            return Object.values(item)[0];
        });
        const body = {
            configs: flattenDeep(parsedData),
        };

        dispatch(dashboardActions.addForecastConfig(body));
    };

    const handleConfigChange = (value, inventory, weekData) => {
        const date = moment(monthlyTab?.[activePeriod], "MMMM YYYY");
        const year = date.year();
        const month = date.month() + 1;
        const today = moment();
        const thisMonth = today.month();
        const thisYear = today.year();
        const currMonth = today?.format("MMM-YYYY");
        const nOfWeeksOfTheMonth = getWeeksInMonth(thisYear, thisMonth);
        const keyFormat = chartFilter === "yearly" ? monthlyTab?.[activePeriod] : currMonth;
        const configCopy = [
            ...(chartFilter === "yearly" ? monthlyConfigChange : weeklyConfigChange),
        ];
        const conf = configCopy.find((item) => item[keyFormat]?.inventoryId === inventory?._id);
        const configIndex = configCopy.findIndex(
            (item) => item[keyFormat]?.inventoryId === inventory?._id
        );
        const weekValueFromMonthValue =
            Number(String(value)?.split(",")?.join("") || 0) / nOfWeeksOfTheMonth;

        const yearlyData = {
            year: `${year}-01-01`,
            channel: inventory?.channel,
            forecastLevel: "global",
            monthlyDemand: {
                month: month,
                quantity: Number(String(value)?.split(",")?.join("") || 0),
            },
            weeklyDemand: Array(nOfWeeksOfTheMonth)
                .fill(0)
                ?.map((val, idx) => {
                    return {
                        week: idx + 1,
                        quantity: weekValueFromMonthValue,
                    };
                }),
            inventoryId: inventory?._id,
            helpTrackPerformance: trackPerformance,
        };

        const weeklyValues = conf?.[keyFormat]?.weeklyDemand?.map((item) => item?.quantity);

        const weeklyData = [...(weeklyValues || [])];
        weeklyData[activePeriod] = Number(String(value)?.split(",")?.join(""));

        const monthlyData = {
            year: `${thisYear}-01-01`,
            channel: inventory?.channel,
            forecastLevel: "global",
            weeklyDemand: [...(weekData?.length > weeklyData?.length ? weekData : weeklyData)]?.map(
                (val, idx) => {
                    return {
                        week: idx + 1,
                        quantity:
                            Number(weeklyData?.[idx]) >= 0
                                ? Number(weeklyData?.[idx])
                                : Number(
                                      String(weekData?.[idx]?.weeklyDemand?.quantity)
                                          ?.split(",")
                                          ?.join("")
                                  ) || 0,
                    };
                }
            ),
            monthlyDemand: {
                month: thisMonth,
                quantity: weeklyData?.reduce((acc, curr) => acc + Number(curr), 0),
            },
            inventoryId: inventory?._id,
            helpTrackPerformance: trackPerformance,
        };

        if (configIndex === -1) {
            configCopy.push({ [keyFormat]: chartFilter === "yearly" ? yearlyData : monthlyData });
        } else {
            configCopy[configIndex] = {
                [keyFormat]: chartFilter === "yearly" ? yearlyData : monthlyData,
            };
        }

        if (chartFilter === "yearly") {
            setMonthlyConfigChange(configCopy);
        } else {
            setWeeklyConfigChange(configCopy);
        }
    };

    const configureForecastTableHeader = [
        {
            title: "Bundle SKU",
            widthClass: "w-[136px] !border-r-none",
        },
        { title: "SKU components", widthClass: "w-[136px] !border-r-none" },
        { title: "Unit/SKU", widthClass: "w-[106px] !border-r-none" },
        { title: "Actual sale/Bundle", widthClass: "w-[130px] !border-r-none" },
        { title: "Actual sale/SKU", widthClass: "w-[116px] !border-r-none" },
        // { title: "Growth rate/SKU", widthClass: "w-[116px] !border-r-none" },
        { title: "Adjusted Demand forecast/Bundle", widthClass: "w-[200px] !border-r-none" },
        { title: "Demand forecast/Bundle", widthClass: "w-[177px] !border-r-none" },
        { title: "Adjusted Demand forecast/SKU", widthClass: "w-[160px] !border-r-none" },
        { title: "Demand forecast/SKU", widthClass: "w-[147px] !border-r-none" },
        { title: "Adjusted COGS forecast", widthClass: "w-[124px] !border-r-none" },
        { title: "COGS forecast", widthClass: "w-[106px] !border-r-none" },
        { title: "Adjusted Revenue forecast", widthClass: "w-[160px] !border-r-none" },
        { title: "Revenue forecast", widthClass: "w-[122px] !border-r-none" },
        { title: "Current stock level/SKU", widthClass: "w-[157px] !border-r-none" },
        { title: "Stock deficit/SKU", widthClass: "w-[126px] !border-r-none" },
        // { title: "Replenishment due", widthClass: "w-[136px] !border-r-none" },
    ];

    const configureForecastTableBody = configList?.map((product) => {
        const currMonth = moment()?.format("MMM-YYYY");
        const prevMonth = moment()?.subtract(1, "months")?.format("MMM-YYYY");
        const currentKeyFormat = monthlyTab?.[activePeriod]?.split(" ")?.join("-");
        const prevKeyFormat = moment(currentKeyFormat)?.subtract(1, "months")?.format("MMM-YYYY");

        const currentConfigObj = product?.configs?.find?.((item) => {
            return chartFilter === "yearly" ? item?.[currentKeyFormat] : item?.[currMonth];
        });

        const prevConfigObj = product?.configs?.find?.((item) => {
            return chartFilter === "yearly"
                ? item?.[activePeriod === 0 ? prevMonth : prevKeyFormat]
                : item?.[currMonth];
        });

        const currentConfigValues =
            chartFilter === "yearly"
                ? currentConfigObj?.[currentKeyFormat]?.[0]
                : currentConfigObj?.[currMonth]?.[activePeriod];

        const currForecastData =
            chartFilter === "yearly"
                ? product?.estinmatedSalesForecast?.monthlyForecast?.find?.((item) => {
                      return item?.yearMonth === currentKeyFormat;
                  })
                : product?.estinmatedSalesForecast?.weeklyForecast?.filter?.((item) => {
                      return item?.yearMonth === currMonth;
                  });

        const prevForecastData =
            chartFilter === "yearly"
                ? product?.estinmatedSalesForecast?.monthlyForecast?.find?.((item) => {
                      return item?.yearMonth === prevKeyFormat;
                  })
                : product?.estinmatedSalesForecast?.weeklyForecast?.filter?.((item) => {
                      return item?.yearMonth === currMonth;
                  });

        const currDemandForecast =
            chartFilter === "yearly"
                ? String(Math.round(currForecastData?.forecastedSale || 0))
                : String(Math.round(currForecastData?.[activePeriod]?.forecastedSale || 0));

        const prevDemandForecast =
            chartFilter === "yearly"
                ? String(Math.round(prevForecastData?.forecastedSale || 0))
                : String(Math.round(prevForecastData?.[activePeriod - 1]?.forecastedSale || 0));

        const currStockLevel = Math.round(
            Number(currentConfigValues?.monthlyDemand?.stockLevel) ||
                Number(product?.inventory?.productStockDetails?.stockLevel)
        );

        const stockDeficit =
            chartFilter === "yearly"
                ? String(
                      Math.round(currForecastData?.forecastedSale || 0) -
                          Number(currStockLevel || 0)
                  )
                : String(
                      Math.round(currForecastData?.[activePeriod]?.forecastedSale || 0) -
                          Number(currStockLevel || 0)
                  );

        const revenueForecast =
            chartFilter === "yearly"
                ? Math.round(currForecastData?.forecastedSale || 0) *
                  (product?.inventory?.salesPrice?.amount || 0)
                : Math.round(currForecastData?.[activePeriod]?.forecastedSale || 0) *
                  (product?.inventory?.salesPrice?.amount || 0);

        const configCopy = [
            ...(chartFilter === "yearly" ? monthlyConfigChange : weeklyConfigChange),
        ];
        const changedConfig = configCopy.find((conf) => {
            return (
                conf?.[chartFilter === "yearly" ? monthlyTab?.[activePeriod] : currMonth]
                    ?.inventoryId === product?.inventory?._id
            );
        });

        const changeValue = Math.round(
            chartFilter === "yearly"
                ? changedConfig?.[monthlyTab?.[activePeriod]]?.monthlyDemand?.quantity
                : changedConfig?.[currMonth]?.weeklyDemand?.[activePeriod]?.quantity
        );

        const defaultValue = Math.round(
            chartFilter === "yearly"
                ? currentConfigValues?.monthlyDemand?.quantity || ""
                : currentConfigValues?.weeklyDemand?.quantity || ""
        );

        const adjustedValue = Number(changeValue || defaultValue || 0);

        return {
            groupContent: (
                <div
                    className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div className="font-rocGroteskMedium   items-center flex space-x-3">
                        <div className="text-sm font-rocGroteskMedium">
                            <p className="text-g-75 ">{product?.inventory?.sku}</p>
                            <p className="text-slate-500">{product?.inventory?.productName}</p>
                        </div>
                    </div>
                </div>
            ),
            associatedSKUs: product?.inventory?.bundleItems?.map((associatedSku) => {
                return [
                    {
                        content: (
                            <div
                                key={1}
                                className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                            >
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-g-75 ">{associatedSku?.inventory?.sku}</p>
                                    <p className="text-slate-500 ">
                                        {associatedSku?.inventory?.productName}
                                    </p>
                                </div>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>{formatMoney().format(associatedSku?.quantity)}</p>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>${formatMoney().format(associatedSku?.totalActualSales)}</p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                        shouldRowSpan: true,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    $
                                    {formatMoney().format(
                                        associatedSku?.totalActualSales * associatedSku?.quantity
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600 w-full py-2`}
                            >
                                <TextInput
                                    name={"adjustedDemandForecast"}
                                    type={"text"}
                                    onWheel={(e: any) => e.target.blur()}
                                    value={monifyInputField(
                                        changeValue >= 0 ? changeValue : defaultValue
                                    )}
                                    onChange={(e) => {
                                        handleConfigChange(
                                            monifyInputField(e.target.value),
                                            product?.inventory,
                                            currentConfigObj?.[currMonth] || []
                                        );
                                    }}
                                    placeholder={"Adjusted Demand"}
                                    required={true}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    pattern={"^[0-9]+$"}
                                    title={"must be digits"}
                                />
                            </div>
                        ),
                        tableDataDivClass: "!pl-0",
                        shouldRowSpan: true,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>{formatMoney().format(currDemandForecast)}</p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                        shouldRowSpan: true,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    {formatMoney().format(
                                        Number(adjustedValue) * associatedSku?.quantity
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    {formatMoney().format(
                                        Number(currDemandForecast) * associatedSku?.quantity
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    $
                                    {formatMoney().format(
                                        Number(adjustedValue) *
                                            Number(associatedSku?.inventory?.unitCost?.amount)
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    $
                                    {formatMoney().format(
                                        Number(currDemandForecast) *
                                            Number(associatedSku?.inventory?.unitCost?.amount)
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    $
                                    {formatMoney().format(
                                        Number(adjustedValue) *
                                            Number(associatedSku?.inventory?.salesPrice?.amount)
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    $
                                    {formatMoney().format(
                                        Number(currDemandForecast) *
                                            Number(associatedSku?.inventory?.salesPrice?.amount)
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    {formatMoney().format(
                                        Number(
                                            associatedSku?.inventory?.productStockDetails
                                                ?.stockLevel
                                        )
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>
                                    {formatMoney().format(
                                        Number(
                                            associatedSku?.inventory?.productStockDetails
                                                ?.stockLevel
                                        ) -
                                            Number(currDemandForecast) * associatedSku?.quantity
                                    )}
                                </p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    // {
                    //     content: (
                    //         <div
                    //             className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                    //         >
                    //             <p>0</p>
                    //         </div>
                    //     ),
                    //     // widthClass: `!border-none`,
                    // },
                ];
            }),
        };
    });

    const tableHeader = [
        {
            title: "Raw materials",
            widthClass: "w-[21%] !border-r-none",
        },
        {
            title: "Associated SKUs/products",
            widthClass: "w-[16.4%] !border-r-none",
            tooltipIcon: "associated-skus",
            tooltipContent: ".",
        },
        {
            title: "Forecasted qty",
            widthClass: "w-[11%] !border-r-none",
            tooltipIcon: "forecasted-qty",
            tooltipContent: ".",
        },
        {
            title: "Warehouse",
            widthClass: "w-[11%] !border-r-none",
            tooltipIcon: "warehouse",
            tooltipContent: ".",
        },
        {
            title: "",
            widthClass: "w-[4.3%] !border-r-none",
        },
    ];

    const tableData = [1, 1, 1]?.map((product) => {
        return {
            groupContent: (
                <div
                    className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div className="font-rocGroteskMedium   items-center flex space-x-3">
                        {/* {product?.productImageDetails?.productImages?.[0] ? (
                             <VendorAvatar
                                 imageSrc={
                                     product?.productImageDetails?.productAvatar ||
                                     product?.productImageDetails?.productImages?.[0]
                                 }
                                 size={32}
                                 name={product?.productName}
                                 containerClassname="!rounded-md"
                             />
                         ) : ( */}
                        <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                            <i className="ri-box-2-fill text-lg text-slate-500"></i>
                        </div>
                        {/* )} */}

                        <div className="text-sm font-rocGroteskMedium">
                            <p className="text-g-75">Jars</p>
                            <p className="text-slate-500 ">
                                Forecasted needs:
                                <span className="font-bold"> 12,506 units</span>
                            </p>
                            <p className="text-slate-500 ">
                                Available qty:
                                <span className="font-bold"> 10,306 units</span>
                            </p>
                            <p className="text-slate-500 ">
                                Stock deficit:
                                <span className="font-bold"> 12,506 units</span>
                            </p>
                        </div>
                    </div>
                </div>
            ),
            associatedSKUs: [1, 1, 1].map((associatedSku) => {
                return [
                    {
                        content: (
                            <div
                                key={1}
                                className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                            >
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-g-75 ">
                                        {/* SKU - {truncate(associatedSku?.sku, { length: 25 })} */}
                                        SKU-12345
                                    </p>
                                    <p className="text-slate-500 ">
                                        {/* {truncate(associatedSku?.productName, { length: 25 })} */}
                                        Garlic
                                    </p>
                                </div>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                {/* <p>{associatedSku?.stockLevel}</p> */}
                                10
                            </div>
                        ),
                        // widthClass: `!border-none`,
                        // shouldRowSpan: true,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                {/* <p>{associatedSku?.onGoingSales}</p> */}
                               Techouse Warehouse
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown
                                    dropdown={
                                        <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                                    dropdownItemsClasses={``}
                                    name={"faq-dropdown"}
                                    //  dropdownOptions={[
                                    //      {
                                    //          label: "View Mapped SKU",
                                    //          value: "view mapped sku",
                                    //          action: () => {
                                    //              navigate(
                                    //                  `/dashboard/inventory/mapped-sku/${product?._id}`,
                                    //                  { state: { internalNavigation: true } }
                                    //              );
                                    //          },
                                    //      },
                                    //      {
                                    //          label: "Edit Mapped SKU",
                                    //          value: "Edit mapped sku",
                                    //          action: () => {
                                    //              navigate(
                                    //                  `/dashboard/inventory/mapped-sku/edit/${product?._id}`,
                                    //                  { state: { internalNavigation: true } }
                                    //              );
                                    //          },
                                    //      },
                                    //  ]}
                                />
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                ];
            }),
        };
    });

    useEffect(() => {
        if (gwmTooltip && chartFilter && currYAxisLabel !== "") {
            setCacheData((prev) => ({
                ...prev,
                [chartFilter]: {
                    ...prev?.[chartFilter],
                    [currYAxisLabel]: gwmTooltip,
                },
            }));
        }
    }, [chartFilter, gwmTooltip, currYAxisLabel]);

    useEffect(() => {
        if (gwmData) {
            let chartDataValues;
            if (customFilter === "custom" && startAndEndDate?.length === 2) {
                chartDataValues = getDashboardChartData(gwmData, "custom", startAndEndDate);
            } else {
                chartDataValues = getDashboardChartData(gwmData, chartFilter);
            }
            setChartData(chartDataValues);
        }
    }, [chartFilter, gwmData, startAndEndDate]);

    useEffect(() => {
        if (Boolean(addForecastConfigSuccess)) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Configurations saved successfully"} type={"success"} />
            ));
            dispatch(dashboardActions.resetAddForecastConfig());
        }
    }, [dispatch, addForecastConfigSuccess]);

    useEffect(() => {
        if (durationFilter === "custom" && customValue[0] !== customValue[1]) {
            const dates = [
                moment(customValue?.[0]).format("MMM DD YYYY"),
                moment(customValue?.[1]).format("MMM DD YYYY"),
            ];

            setStartAndEndDate([dates?.[0], dates?.[1]]);
            if (customValue?.[0] !== customValue?.[1]) {
                handleStartAndEndDateChange(customValue);
                handleTooltipStartAndEndDateChange(customValue);
            }
        }
    }, [durationFilter, customValue]);

    return (
        <div>
            <div className="relative lg:border lg:border-slate-200 lg:rounded-xl py-[15px] lg:px-5 lg:mt-[56px] mb-10">
                <div className="lg:flex justify-between">
                    <div className="flex items-center space-x-6">
                        <p className="text-g-75 font-rocGroteskMedium text-sm lg:text-lg mb-4 lg:mb-0">
                            Bundled SKUs Forecast
                        </p>

                        <div className="bg-slate-50 !w-fit p-1 flex gap-2 font-rocGroteskMedium font-sm  max-lg:mb-0">
                            {forecastTabs?.map((tab) => {
                                return (
                                    <button
                                        key={tab?.id}
                                        className={`w-auto  py-[7px] px-4  text-sm ${
                                            activeTab === tab?.id &&
                                            "!bg-white rounded-[6px] shadow-buttonShadow text-slate-900"
                                        } text-slate-500 `}
                                        onClick={() => {
                                            setActiveTab(tab?.id);
                                        }}
                                    >
                                        {tab?.title}
                                    </button>
                                );
                            })}
                        </div>
                    </div>

                    <div className=" w-full justify-end flex sm:hidden mb-4 sm:mb-0">
                        <Dropdown
                            handleChange={(name, value) => {
                                setActivePeriod(0);
                                configHandleDateRangeChange(value?.split("-")[0]);
                                handleDateChange({ target: { name, value } });
                            }}
                            dropdown={
                                <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] xs:gap-2 !border !border-slate-100">
                                    <i className="ri-arrow-down-s-line  xs:text-lg text-[#142837]"></i>

                                    <div
                                        className={`text-g-75 font-rocGroteskMedium text-xs xs:text-[13px] inline-block ${
                                            durationFilter === "custom" && "capitalize"
                                        } `}
                                    >
                                        {startAndEndDate?.length !== 0
                                            ? ` ${startAndEndDate[0]} to ${startAndEndDate[1]}`
                                            : durationFilter || "This year"}
                                    </div>
                                </div>
                            }
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!w-full !mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                            name="valueRange"
                            dropdownOptions={[
                                ...dateValues?.map((item, idx) => ({
                                    label: (
                                        <div
                                            key={idx}
                                            className={`${
                                                item.period === "custom" && "capitalize"
                                            } text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                                        >
                                            {item?.period}
                                        </div>
                                    ),
                                    value: item?.value + "-" + item?.period,
                                })),
                            ]}
                        />
                    </div>

                    <div className="">
                        <div className="flex sm:space-x-2.5  max-sm:justify-between max-sm: items-end border-b border-b-slate-200 sm:border-b-0">
                            <Dropdown
                                handleChange={(name, value) => {
                                    configHandleCategoryChange(value);
                                    handleCategoryValue({
                                        target: { name, value },
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between sm:rounded-[6px] sm:shadow-buttonShadow2 pt-[6px] sm:py-[6px] xs:px-[10px] xs:gap-2 !border-0 sm:!border sm:!border-slate-100">
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] inline-block`}
                                        >
                                            {categoryFilter ? categoryFilter : "All product types"}
                                        </div>
                                        <i className="ri-arrow-down-s-line text-xs xs:text-lg text-g-75"></i>
                                    </div>
                                }
                                // wholeContainerClass=" border-r  border-r-slate-200 sm:border-0 "
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 left-0 sm:right-0 rounded-[6px]  !w-[150px] !min-w-min
                                    `}
                                name="valueRange"
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[150px] px-4 py-[10px]"
                                            >
                                                All product types
                                            </div>
                                        ),
                                        value: "",
                                    },
                                    ...productCategories?.map((item) => ({
                                        label: (
                                            <div
                                                className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[150px] px-4 py-[10px]"
                                            >
                                                {item?.label}
                                            </div>
                                        ),
                                        value: item?.value,
                                    })),
                                ]}
                            />

                            <div className="sm:hidden w-[1px] h-[20px] xs:h-[25px] bg-slate-200"></div>

                            <Dropdown
                                handleChange={(name, value) => {
                                    const prodIdsCopy = [...configProductIds];
                                    const newValue = value?.split("-")[0];
                                    const isValuePresent = prodIdsCopy?.find(
                                        (id) => id === newValue
                                    );

                                    if (isValuePresent) {
                                        configHandleProductChange(
                                            prodIdsCopy?.filter((id) => id !== newValue)
                                        );
                                    } else {
                                        if (newValue === "") {
                                            configHandleProductChange([]);
                                        } else {
                                            configHandleProductChange([...prodIdsCopy, newValue]);
                                        }
                                    }

                                    handleProductValue({
                                        target: { name, value },
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center sm:rounded-[6px] sm:shadow-buttonShadow2 pt-[6px] sm:py-[6px] xs:px-[10px] xs:gap-2 sm:!border sm:!border-slate-100">
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] inline-block`}
                                        >
                                            {productFilter?.length > 0
                                                ? truncate(productFilter?.join(","), {
                                                      length: 15,
                                                  })
                                                : "All product"}
                                        </div>
                                        <i className="ri-arrow-down-s-line text-xs xs:text-lg text-g-75"></i>
                                    </div>
                                }
                                // wholeContainerClass=" border-r  border-r-slate-200 sm:border-0 "
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`sm:shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] 
                                            !min-h-[300px] !max-h-[300px] !w-auto !pt-0
                                    `}
                                loader={isLoadingMore}
                                // endOfList={
                                //     pagination?.currentPage === pagination?.noOfPages
                                // }
                                name="valueRange"
                                search={
                                    <div ref={clearSearchRef} className="">
                                        <TextInput
                                            value={searchTwo}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={handleSearchTwo}
                                            onInput={handleDebouncedChangeTwo}
                                            inputPlaceholder="Search product"
                                            containerClassname=""
                                            inputContainerClassname={"!h-[40px]"}
                                            // inputClassName="w-[50%]"
                                        />
                                    </div>
                                }
                                leaveOpen={true}
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                        border-b border-b-slate-100 !w-[190px] px-2 py-[10px]"
                                            >
                                                <i
                                                    className={`${
                                                        configProductIds?.length === 0 ||
                                                        productIds?.length === 0
                                                            ? "ri-checkbox-line text-carribean-green"
                                                            : "ri-checkbox-blank-line text-slate-200"
                                                    }    text-xl`}
                                                ></i>
                                                All product
                                            </div>
                                        ),
                                        value: "",
                                    },
                                    ...productSearchList?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] gap-2 flex items-center
                                                        border-b border-b-slate-100 !w-[190px] px-2 py-[10px]"
                                            >
                                                <i
                                                    className={`${
                                                        configProductIds?.includes(item?._id) ||
                                                        productIds?.includes(item?._id)
                                                            ? "ri-checkbox-line text-carribean-green"
                                                            : "ri-checkbox-blank-line text-slate-200"
                                                    }   text-xl`}
                                                ></i>

                                                <div className="flex flex-col">
                                                    <p className="text-xs mb-1">{` SKU: ${item?.sku}`}</p>
                                                    <span className="text-[10px] leading-normal">
                                                        {truncate(item?.productName, {
                                                            length: 50,
                                                        })}
                                                    </span>

                                                    {/* <span className="text-xs">
                                                                    {truncate(item?.productName, {
                                                                        length: 38,
                                                                    })}
                                                                </span>

                                                                <p className="text-[10px]">{` SKU No: ${item?.sku}`}</p> */}
                                                </div>
                                            </div>
                                        ),
                                        value: item?._id + "-" + item?.productName,
                                    })),
                                ]}
                            />
                            <div className="sm:hidden w-[1px] h-[20px] xs:h-[25px] bg-slate-200"></div>

                            <Dropdown
                                handleChange={(name, value) => {
                                    configHandleChannelChange(value);
                                    handleChangeValue({
                                        target: { name, value },
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] sm:shadow-buttonShadow2 pt-[6px] sm:py-[6px] xs:px-[10px] xs:gap-2 border-0 sm:!border sm:!border-slate-100">
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] inline-block  ${
                                                channelFilter && "capitalize"
                                            }`}
                                        >
                                            {truncate(channelFilter, {
                                                length: 15,
                                            }) || "All channels"}
                                        </div>
                                        <i className="ri-arrow-down-s-line text-xs xs:text-lg text-g-75"></i>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-[190px] !min-w-min
                                    `}
                                name="valueRange"
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                        border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                            >
                                                {/* <i
                                                                className={`${
                                                                    channelFilter === ""
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }   text-xl`}
                                                            ></i> */}
                                                All channels
                                            </div>
                                        ),
                                        value: "",
                                    },
                                    ...salesChannel?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                        border-b border-b-slate-100 !w-[190px] px-4 py-[10px] capitalize"
                                            >
                                                {/* <i
                                                                className={`${
                                                                    channelFilter === item
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }   text-xl`}
                                                            ></i> */}
                                                {item}
                                            </div>
                                        ),
                                        value: item,
                                    })),
                                ]}
                            />
                            <div className="">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        setActivePeriod(0);
                                        configHandleDateRangeChange(value?.split("-")[0]);
                                        handleDateChange({ target: { name, value } });
                                    }}
                                    dropdown={
                                        <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100 max-sm:hidden">
                                            <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block ${
                                                    durationFilter === "custom" && "capitalize"
                                                } `}
                                            >
                                                {startAndEndDate?.length !== 0
                                                    ? ` ${startAndEndDate[0]} to ${startAndEndDate[1]}`
                                                    : durationFilter || "This year"}
                                            </div>
                                        </div>
                                    }
                                    dropdownItemsClasses="!px-0 !py-0"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                    name="valueRange"
                                    dropdownOptions={[
                                        ...dateValues
                                            ?.filter((item) =>
                                                activeTab === 0 ? true : item?.value !== "custom"
                                            )
                                            ?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className={`${
                                                            item.period === "custom" && "capitalize"
                                                        } text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                                                    >
                                                        {item?.period}
                                                    </div>
                                                ),
                                                value: item?.value + "-" + item?.period,
                                            })),
                                    ]}
                                />
                            </div>
                            <div className="relative">
                                {showCustomDate && (
                                    <div
                                        ref={customRef}
                                        className="w-[630px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                    >
                                        <CustomCalendar
                                            showDoubleView={true}
                                            selectRange={true}
                                            value={customValue}
                                            onChange={customOnChange}
                                        />
                                        <div className="flex justify-end">
                                            <Button
                                                btnText={"Close"}
                                                btnClassname={"!w-fit !py-2"}
                                                onClick={() => setShowCustomDate(false)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="relative">
                                {activeTab === 0 ? (
                                    <Dropdown
                                        handleChange={(name, value) => {
                                            if (value === "table") {
                                                setActiveTab(1);
                                            }
                                        }}
                                        dropdown={
                                            <div className="flex items-center justify-between bg-[#56B648] rounded-[6px] sm:shadow-buttonShadow2  xs:gap-2 border-0 sm:!border sm:!border-slate-100">
                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] flex items-center space-x-1.5 pt-[6px] sm:py-[6px] xs:px-[10px]`}
                                                >
                                                    <i className="ri-edit-2-fill text-base text-white"></i>
                                                    <span className="text-white">
                                                        Configure forecast
                                                    </span>
                                                </div>
                                                <div className="pt-[6px] sm:py-[6px] xs:px-[10px] border-l border-white">
                                                    <i className="ri-arrow-down-s-line text-base text-white"></i>
                                                </div>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-full !min-w-min
                                    `}
                                        name="valueRang"
                                        dropdownOptions={configureForecastOptions?.map((opt) => ({
                                            label: (
                                                <div
                                                    className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 px-2 py-[10px]"
                                                >
                                                    {opt?.label}
                                                </div>
                                            ),
                                            value: opt?.value,
                                        }))}
                                    />
                                ) : (
                                    <div className="flex items-center space-x-2.5">
                                        <Button
                                            btnText="Cancel"
                                            btnClassname="!bg-white !border !border-slate-100 shadow-[0px_1px_2px_0px_#5B687126] !text-[13px] !px-2 !py-2.5 rounded-[5px] !h-[42px]"
                                            icon={<i className="ri-close-fill text-base"></i>}
                                            onClick={() => {
                                                setActiveTab(0);
                                                setMonthlyConfigChange([]);
                                                setWeeklyConfigChange([]);
                                            }}
                                        />
                                        <Button
                                            btnText="Save configuration"
                                            btnClassname="!bg-[#56B648] !w-[164px] !text-white !text-[13px] !whitespace-nowrap !px-2 !py-2.5 rounded-[5px] !h-[42px]"
                                            icon={
                                                <i className="ri-check-double-line text-base"></i>
                                            }
                                            btnPermission={[
                                                AccountPermissions.all_access,
                                                AccountPermissions.view_dashboard,
                                            ]}
                                            isLoading={addingForecastConfig}
                                            onClick={() => handleSaveConfig()}
                                        />
                                    </div>
                                )}

                                {/* <Button
                                                btnText="Adjust Forecast"
                                                btnClassname="!bg-[#56B648] !w-fit !text-white !text-[13px] !px-2 !py-2.5 rounded-[5px]"
                                                icon={<i className="ri-add-line"></i>}
                                                onClick={() => setForecastModal(true)}
                                            /> */}

                                {/* <ModalContainer
                                                modalClassName="!bg-transparent absolute !w-[530px] !left-auto !right-0 top-[50px] transition ease-in-out delay-150 duration-300 !h-auto shadow-tableShadow !rounded-xl"
                                                closeModal={() => setForecastModal(false)}
                                                tailwindClassName="w-[90%] lg:w-[1000px]"
                                                open={forecastModal}
                                            >
                                                <div className="bg-white  px-4 pt-[15px] pb-[25px] !w-[530px]">
                                                    <div className="flex justify-between items-center border-b border-slate-100 text-slate-800 font-rocGroteskBold">
                                                        <p className="text-base !leading-[23px] ">
                                                            Adjust forecast
                                                        </p>
                                                        <i
                                                            className="ri-close-line  text-2xl cursor-pointer text-slate-800"
                                                            onClick={() => setForecastModal(false)}
                                                        ></i>
                                                    </div>

                                                    <div className="space-y-2.5 mt-5">
                                                        <SelectInput
                                                            floatingPlaceholderClass="!text-slate-400"
                                                            className="!border-gm-25"
                                                            placeholder="Select influencing event"
                                                            iconClassName="!text-slate-400"
                                                        />
                                                        <p className="text-slate-800 font-sm font-rocGroteskMedium">
                                                            Select timeframe
                                                        </p>
                                                        <div className="grid grid-cols-2 items-center gap-2 relative">
                                                            <DateInput
                                                                label="Date from"
                                                                value={""}
                                                                borderContainerClassname="w-full !border-gm-25"
                                                                borderClassname="!border-gm-25"
                                                            />

                                                            <DateInput label="Date to" value={""} />
                                                            <div className="absolute left-[47%] z-[2] top-[20%] rounded-full w-[30px] h-[30px] shadow-buttonShadow2 flex items-center justify-center bg-white">
                                                                <i className="ri-arrow-right-line text-sm cursor-pointer text-slate-400"></i>
                                                            </div>
                                                        </div>
                                                        <p className="text-slate-800 font-sm font-rocGroteskMedium">
                                                            Select other factors
                                                        </p>
                                                        <SelectInput
                                                            floatingPlaceholderClass="!text-slate-400"
                                                            className="!border-gm-25"
                                                            iconClassName="!text-slate-400"
                                                            placeholder="Select channel"
                                                        />
                                                    </div>
                                                    <div className="flex justify-between gap-3.5 mt-6">
                                                        <Button
                                                            btnText="Adjust Forecast"
                                                            btnClassname=" !w-full  !text-[13px] !px-2 !py-4 rounded"
                                                            onClick={() => setForecastModal(false)}
                                                        />
                                                        <Button
                                                            btnText="Cancel"
                                                            btnClassname="!bg-n-20 !w-full !text-[13px] !px-2 !py-4 rounded"
                                                            onClick={() => setForecastModal(false)}
                                                        />
                                                    </div>
                                                </div>
                                            </ModalContainer> */}
                            </div>
                        </div>
                    </div>
                </div>

                {activeTab === 0 && (
                    <div>
                        <div className="lg:flex justify-between items-start mt-8">
                            <div className="flex flex-col gap-3.5 mb-6 lg:mb-0">
                                <div className="flex items-center space-x-1">
                                    <span className="w-[14px] h-[14px] rounded-sm bg-[#22214C]" />
                                    <p className="text-slate-700 font-rocGroteskMedium text-[10px] leading-3 ml-1">
                                        Actual bundle sales
                                    </p>
                                </div>

                                <div className="flex items-center space-x-1">
                                    <span className="w-[14px] h-[14px] rounded-sm bg-[#CCCBF6]" />
                                    <p className="text-slate-700 font-rocGroteskMedium text-[10px] leading-3 ml-1">
                                        Bundle demand forecast
                                    </p>
                                </div>
                            </div>

                            <div className="flex max-lg:flex-col">
                                <div className="flex w-full lg:w-fit max-lg:items-center max-lg:justify-between max-lg:border-b max-lg:border-b-slate-200">
                                    <AnalyticsCard
                                        title={"Forecasted revenue"}
                                        figure={
                                            gwmData?.forecastTotalSale !== undefined
                                                ? millify(Number(gwmData?.forecastTotalSale), {
                                                      precision: 2,
                                                  })
                                                : "----"
                                        }
                                        currency={"$"}
                                        customFigure={
                                            Boolean(gwmData?.configuredRevForecast) &&
                                            gwmData?.configuredRevForecast !== undefined
                                                ? millify(Number(gwmData?.configuredRevForecast), {
                                                      precision: 2,
                                                  })
                                                : "----"
                                        }
                                        loader={false}
                                        iconTwo
                                        hideDirection={true}
                                        hasCustomValue={true}
                                        increaseClassName="!text-g-60"
                                        innerClassName="!justify-end"
                                        addTextTwoClass="!whitespace-nowrap"
                                        addTextTwo={"Synth's revenue forecast"}
                                        customSubTextClass="!whitespace-nowrap"
                                        customSubText={"Configured revenue forecast"}
                                        className="!shadow-none !border-y-0 !border-l-0 lg:!border-r lg:!border-r-slate-200 !py-2 !pl-0 lg:!pl-[15px] !pr-8  !rounded-none flex-1 lg:!w-auto"
                                        arrUpClass={"!text-g-60"}
                                        titleClassName="!text-slate-600 !text-[10px]"
                                        figureClassName="!mt-2.5 !text-slate-600  !text-[20px] !leading-[28px]"
                                        tooltipClassName="!max-w-[250px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-l-transparent before:!border-r-gm-75 before:!top-[40%] before:!-left-[9%] !z-[999] before:!border-[6px] !left-[50px] xs:!left-[120px]"
                                        toolTipContent={
                                            <div className="space-y-2 p-3">
                                                <div className="">
                                                    <p className="underline text-xs font-rocGroteskMedium mb-1">
                                                        Synth's revenue forecast
                                                    </p>
                                                    <p className="text-[11px]">
                                                        This is the total amount our system has
                                                        forecasted you would sell for all your
                                                        bundles over the next 6 months. We use the
                                                        current unit bundle value/amount multiplied
                                                        by the forecasted quantity.
                                                    </p>
                                                </div>

                                                <div className="">
                                                    <p className="underline text-xs font-rocGroteskMedium mb-1">
                                                        Configured revenue forecast
                                                    </p>
                                                    <p className="text-[11px]">
                                                        his is the total forecasted revenue for all
                                                        your bundles that you have configured over
                                                        the next 6 months. We use the current unit
                                                        bundle value/amount multiplied by the
                                                        forecasted quantity you set.
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        addTextTwoTooltipContent={
                                            "This is the total amount our system has forecasted you would sell for all your bundles over the next 6 months. We use the current unit bundle value/amount multiplied by the forecasted quantity."
                                        }
                                        customSubTextTooltipContent={
                                            "This is the total forecasted revenue for all your bundles that you have configured over the next 6 months. We use the current unit bundle value/amount multiplied by the forecasted quantity you set."
                                        }
                                    />

                                    <div className="h-[29px] lg:hidden w-[1px] bg-slate-200"></div>

                                    <AnalyticsCard
                                        title={"Forecasted sales quantity"}
                                        value={Math.round(gwmData?.totalForecastQty)}
                                        customFigure={
                                            gwmData?.configuredDemandForecast
                                                ? formatMoney().format(
                                                      gwmData?.configuredDemandForecast
                                                  )
                                                : "----"
                                        }
                                        loader={false}
                                        iconTwo
                                        hasCustomValue={true}
                                        className="!shadow-none !border-y-0 !border-l-0 lg:!border-r lg:!border-r-slate-200 !py-2 !pl-[15px] !pr-8  !rounded-none  flex-1 lg:!w-fit"
                                        titleClassName="!text-slate-500 !text-[10px]"
                                        innerClassName="!justify-end"
                                        addTextTwoClass="!whitespace-nowrap"
                                        addTextTwo={"Synth's demand forecast"}
                                        customSubTextClass="!whitespace-nowrap"
                                        customSubText={"Configured demand forecast"}
                                        figureClassName="!mt-2.5 !text-slate-600  !text-[20px] !leading-[28px]"
                                        tooltipClassName="!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-r-transparent lg:before:!border-l-transparent before:!border-l-gm-75 lg:before:!border-r-gm-75 before:!top-[40%] before:!-right-[95px] lg:before:!right-auto lg:before:!-left-[9%] !z-[999] before:!border-[6px] !-left-[100px] lg:!left-[120px]"
                                        toolTipContent={
                                            <div className="!p-3">
                                                <p className="underline text-xs font-rocGroteskMedium mb-1">
                                                    Forecasted sales quantity
                                                </p>
                                                <p className="text-[11px]">
                                                    This is the estimated number of units of all
                                                    products that you are likely to sell in a future
                                                    period.
                                                </p>
                                            </div>
                                        }
                                        addTextTwoTooltipContent={
                                            "This is the total number of units across all your bundles that you are most likely going to sell over the next 6 months."
                                        }
                                        customSubTextTooltipContent={
                                            "This is the total quantity across all your bundles that you have setup as your likely forecasted units to be sold."
                                        }
                                    />
                                </div>

                                <AnalyticsCard
                                    title={"Forecasted inventory deficit"}
                                    value={Math.round(gwmData?.forecastedStockNeeded)}
                                    loader={false}
                                    iconTwo
                                    addTextTwo={"(oos)"}
                                    addTextTwoClass="!text-r-55 !text-xl"
                                    textAndSubtextContainer={"!justify-end"}
                                    className="!shadow-none !border-none !py-2 !pl-0 lg:!pl-[15px] !pr-8  !rounded-none !w-auto"
                                    titleClassName="!text-slate-600 !text-[10px]"
                                    figureClassName="!mt-2.5 !text-r-55  !text-[20px] !leading-[28px] flex gap-1 items-end"
                                    tooltipClassName="!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-l-transparent lg:before:!border-r-transparent before:!border-r-gm-75 lg:before:!border-l-gm-75 before:!top-[40%] before:!-left-[9%] lg:before:!left-auto lg:before:!-right-[6px] !z-[999] before:!border-[6px] !left-[0px] xs:!left-[120px] lg:!-left-[250px]"
                                    toolTipContent={
                                        <div className="!p-3">
                                            <p className="underline text-xs font-rocGroteskMedium mb-1">
                                                Forecasted inventory deficit
                                            </p>
                                            <p className="text-[11px] ">
                                                This is the estimated number of units of all your
                                                products that are likely to be out of stock in a
                                                future period. These are the likely out of stock
                                                units of products with respect to the demand
                                                forecast.
                                            </p>
                                        </div>
                                    }
                                    addTextTwoTooltipContent={
                                        "This is the total number of units you would need to meet up the forecasted sales quantity over the next 6 months, based on your current inventory level today."
                                    }
                                />
                            </div>
                        </div>
                        {productData?.length !== 0 &&
                        !gwmLoading &&
                        gwmData?.currentSales?.length === 0 &&
                        gwmData?.forcastSales?.length === 0 ? (
                            <div className="w-[100%] h-[400px] flex items-center justify-center">
                                <div className="flex items-center  gap-[3px] flex-col w-[353px] justify-center">
                                    <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1717426949/icon_w8c4mn.png"
                                        alt=""
                                        className=""
                                    />
                                    <p className="text-g-75 font-rocGroteskBold text-base ">
                                        {" "}
                                        No forecast data to show for this query{" "}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="mt-10 mb-6 w-full">
                                <ReactEcharts
                                    option={option}
                                    onEvents={onEvents}
                                    style={{ height: "450px", width: "100%" }}
                                />
                            </div>
                        )}
                    </div>
                )}

                {activeTab === 1 && durationFilter !== "custom" ? (
                    <div className="mt-4">
                        <div className="flex items-center justify-between pt-4 border-t">
                            <div className="flex items-center space-x-2.5">
                                {[...(chartFilter === "yearly" ? monthlyTab : chartData?.[0])]?.map(
                                    (item, idx) => {
                                        return (
                                            <span
                                                onClick={() => setActivePeriod(idx)}
                                                className={`text-sm font-rocGroteskMedium text-tradeally-neutral-200 cursor-pointer pb-2.5 ${
                                                    activePeriod === idx &&
                                                    "text-gm-50 border-b-2 border-[#FFB903]"
                                                }`}
                                            >
                                                {item}
                                            </span>
                                        );
                                    }
                                )}
                            </div>

                            <div className="flex items-center space-x-1">
                                <ToggleInput
                                    checked={trackPerformance}
                                    name={"google trends"}
                                    onChange={(evt) => setTrackPerformance(evt.target.checked)}
                                />
                                <span className="text-[13px] font-rocGroteskMedium text-slate-800">
                                    Help me track the performance
                                </span>
                            </div>
                        </div>

                        {categoryFilter === "Finished Goods" && (
                            <>
                                {configureForecastTableBody?.length > 0 ? (
                                    <div className="mt-4">
                                        {productListLoading ? (
                                            <div>
                                                <CustomTableLoader
                                                    tableHeader={configureForecastTableHeader}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="overflow-x-scroll"
                                                onScroll={handleScroll}
                                            >
                                                <CustomTable
                                                    groupedData={configureForecastTableBody}
                                                    tableHeader={configureForecastTableHeader}
                                                    isScrollable={true}
                                                    isScrolled={isScrolled}
                                                    isCellBordered={true}
                                                    scrollWithin={true}
                                                    isInifiniteScroll={false}
                                                    isCellSelectable={false}
                                                    isAllSelectable={false}
                                                    isGrouped={true}
                                                    tableContainerClass="!max-h-[427px]"
                                                    headerContainerClass="!bg-slate-50"
                                                    headerItemClass="font-rocGroteskMedium !text-xs !text-slate-700"
                                                    tableClass="!border !border-slate-200 cursor-pointer"
                                                    bodyItemClass="!border-slate-200 !cursor-default hover:!bg-slate-50"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <EmptyStateComp
                                        title="No products available"
                                        description={`There are no products available `}
                                        icon={
                                            <i className="ri-archive-2-line text-[48px] text-slate-400"></i>
                                        }
                                    />
                                )}{" "}
                            </>
                        )}

                        {categoryFilter !== "Finished Goods" && (
                            <CustomTable
                                groupedData={tableData}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isCellSelectable={false}
                                isCellBordered={true}
                                isGrouped={true}
                                headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                headerContainerClass="!bg-slate-50 !border !border-[#E2E8F0] "
                                tableContainerClass="mb-[-90px] pb-[90px]"
                                isScrollable={false}
                                bodyItemClass="!border-t-none !border-r-none"
                                tableClass="!border-t-none !border-r-none !border-l-none"
                            />
                        )}
                        {/* <div className="mt-4 overflow-x-scroll">
                                        
                                    </div> */}

                        <div className="flex justify-end mt-5">
                            <ResponsivePaginationComponent
                                current={Number(configPagination?.currentPage)}
                                total={Number(configPagination?.noOfPages)}
                                maxWidth={350}
                                onPageChange={(page) => setUpdatedPage(page)}
                            />
                        </div>
                    </div>
                ) : activeTab === 1 && durationFilter === "custom" ? (
                    <EmptyStateComp
                        title="View unavailable for custom date filter"
                        description="Table view is unavailable for custom date filter, kindly select a different date filter to view the table"
                    />
                ) : null}

                {gwmLoading && (
                    <div className="w-full h-full bg-black/5 absolute top-0 left-0">
                        <div className="w-full h-full flex justify-center items-center">
                            <Loader size={10} />
                        </div>
                    </div>
                )}
                {/* <ForecastInsights /> */}
            </div>
        </div>
    );
};

export default BundledProductForecast;
