import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, set, sortBy, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { useSearchParams } from "react-router-dom";

const useGetProductList = (
    scrollContent?: HTMLElement,
    defaultCategory?: string,
    inventory?: boolean,
    tabContainer?: boolean
) => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get("search");
    const categoryQuery = searchParams.get("category");
    const channelQuery = searchParams.get("channel");
    const currentProductType = searchParams.get("currProductType");

    const {
        fetchingInventoryList,
        fetchedInventoryListSuccess,
        fetchedInventoryListFailure,
        fetchingInventorySearchList,
        fetchedInventorySearchListSuccess,
    } = useAppSelector((state) => state.inventory);

    const [productList, setProductList] = useState<{ [key: string]: any }[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [refetch, setRefetch] = useState<boolean>(false);
    const [productSearchList, setProductSearchList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [tracking, setTracking] = useState<boolean>(true);
    const [debouncedSearchTwo, setDebouncedSearchTwo] = useState<string>("");
    const [search, setSearch] = useState<string>(searchQuery || "");
    const [searchTwo, setSearchTwo] = useState<string>("");
    const [productCategory, setProductCategory] = useState<string>(() => defaultCategory || "");
    const [channel, setChannel] = useState<string>(channelQuery || "");
    const [rawProductData, setRawData] = useState([]);
    const [applyProductFilter, setApplyProductFilter] = useState<boolean>(false);

    const [selectedProduct, setSelectedProduct] = useState<{ name?: string; value: string }>({
        name: "",
        value: "",
    });
    const [stockLevelRange, setStockLevelRange] = useState<{
        stockLevelMin?: string;
        stockLevelMax?: string;
    }>({});
    const [forecastedNeedsRange, setForecastedNeedsRange] = useState<{
        forecastedDemandMin?: string;
        forecastedDemandMax?: string;
    }>({});

    const [variantTableFilter, setVariantTableFilter] = useState<{
        sortBy?: string;
        sortDirection?: string;
    }>({});

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent,
            tabContainer
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setSearch(e.target.value);
    };

    const handleSearchTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setSearchTwo(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearch(e.target.value);
        setUpdatedPage(1);
        if (currentProductType) {
            setSearchParams({
                currProductType: currentProductType,
                search: e.target.value,
                category: productCategory,
                channel: channel,
            });
        } else {
            setSearchParams({
                search: e.target.value,
                category: productCategory,
                channel: channel,
            });
        }
    }, 1000);

    const handleDebouncedChangeTwo = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearchTwo(e.target.value);
    }, 1000);

    const handleChannelChange = (value: string) => {
        setChannel(value);
        setUpdatedPage(1);
        if (currentProductType) {
            setSearchParams({
                currProductType: currentProductType,
                search: search,
                category: productCategory,
                channel: value,
            });
        } else {
            setSearchParams({
                search: search,
                category: productCategory,
                channel: value,
            });
        }
    };

    const handleFilterChange = (value: string) => {
        setProductCategory(value);
        setUpdatedPage(1);
    };

    const handleClearChannel = () => {
        setChannel("");
        setUpdatedPage(1);
        if (currentProductType) {
            setSearchParams({
                currProductType: currentProductType,
                search: search,
                category: productCategory,
                channel: "",
            });
        } else {
            setSearchParams({
                search: search,
                category: productCategory,
                channel: "",
            });
        }
    };

    const handleClearFilter = () => {
        setProductCategory("");
        setUpdatedPage(1);
        if (currentProductType) {
            setSearchParams({
                currProductType: currentProductType,
                search: search,
                category: "",
                channel: channel,
            });
        } else {
            setSearchParams({
                search: search,
                category: "",
                channel: channel,
            });
        }
    };

    const handleFilterChangeTwo = (name: string, value: string) => {
        setUpdatedPage(1);
        setSelectedProduct({ name, value });
    };

    const handleClearFilterTwo = () => {
        setUpdatedPage(1);
        setSelectedProduct({ name: "", value: "" });
    };

    useEffect(() => {
        setUpdatedPage(1);
        setProductList([]);
    }, [
        debouncedSearch,
        productCategory,
        !inventory ? channel : "",
        tracking,
        refetch,
        channelQuery,
        searchQuery,
        categoryQuery,
        applyProductFilter,
        variantTableFilter,
    ]);

    useEffect(() => {

        dispatch(
            inventoryActions.fetchInventoryList(
                10,
                updatedPage,
                searchQuery || debouncedSearch,
                productCategory,
                channelQuery || channel,
                tracking,
                variantTableFilter?.sortBy,
                variantTableFilter?.sortDirection,
                stockLevelRange?.stockLevelMin,
                stockLevelRange?.stockLevelMax,
                forecastedNeedsRange?.forecastedDemandMin,
                forecastedNeedsRange?.forecastedDemandMax
            )
        );
        return () => {
            dispatch(inventoryActions.resetFetchInventoryList());
        };
    }, [
        debouncedSearch,
        productCategory,
        !inventory ? channel : "",
        tracking,
        refetch,
        channelQuery,
        searchQuery,
        categoryQuery,
        applyProductFilter,
        variantTableFilter?.sortDirection,
        variantTableFilter?.sortBy,
        updatedPage,
    ]);

    useEffect(() => {
        dispatch(inventoryActions.fetchInventorySearchList(10, debouncedSearchTwo));
    }, [dispatch, debouncedSearchTwo]);

    useEffect(() => {
        if (Boolean(fetchedInventoryListSuccess)) {
            setProductList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedInventoryListSuccess.inventory?.data], "_id")
                    : uniqBy([...prev, ...fetchedInventoryListSuccess.inventory?.data], "_id")
            );
            setPagination({
                currentPage: fetchedInventoryListSuccess.pagination?.current,
                noOfPages: fetchedInventoryListSuccess.pagination?.number_of_pages,
            });
            setTotal(fetchedInventoryListSuccess?.total);
            setRawData(fetchedInventoryListSuccess.inventory?.data);
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchInventoryList());
        }
    }, [
        dispatch,
        fetchedInventoryListSuccess,
        updatedPage,
        debouncedSearch,
        productCategory,
        channel,
        tracking,
        refetch,
        channelQuery,
        searchQuery,
        categoryQuery,
        applyProductFilter,
        variantTableFilter,
    ]);

    useEffect(() => {
        if (Boolean(fetchedInventorySearchListSuccess)) {
            setProductSearchList(fetchedInventorySearchListSuccess.inventory?.data);
        }
    }, [fetchedInventorySearchListSuccess]);

    return {
        isFetchingSecondList: fetchingInventorySearchList,
        isFetching: fetchingInventoryList,
        error: fetchedInventoryListFailure,
        data: productList,
        rawData: rawProductData || [],
        productSearchList,
        setProductList,
        setUpdatedPage,
        pagination,
        setPagination,
        debouncedSearch,
        search,
        setSearch,
        setDebouncedSearch,
        searchTwo,
        setSearchTwo,
        setDebouncedSearchTwo,
        productCategory,
        handleDebouncedChange,
        handleDebouncedChangeTwo,
        handleSearch,
        handleSearchTwo,
        handleFilterChange,
        handleFilterChangeTwo,
        handleClearFilter,
        handleClearFilterTwo,
        selectedProduct,
        isLoadingMore,
        updatedPage,
        channel,
        total,
        handleChannelChange,
        handleClearChannel,
        setTracking,
        setRefetch,
        stockLevelRange,
        forecastedNeedsRange,
        variantTableFilter,
        setVariantTableFilter,
        setStockLevelRange,
        setForecastedNeedsRange,
        setChannel,
        setProductCategory,
        setApplyProductFilter,
        applyProductFilter,
    };
};

export default useGetProductList;
