import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import WorkforceAnalyticsCard from "components/common/WorkforceAnalyticsCard";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ListView from "./ListView";
import GridView from "./GridView";
import GridViewHumanTask from "./GridViewHumanTask";
import useGetTaskList from "hooks/workforceHooks/useGetTaskList";
import useGetPendingHumanTaskList from "hooks/workforceHooks/useGetPendingHumanTask";
import useGetCompletedHumanTask from "hooks/workforceHooks/useGetCompletedHumanTask";
import useGetFailedHumanTask from "hooks/workforceHooks/useGetFailedHumanTask";
import useGetAllHumanTask from "hooks/workforceHooks/useGetAllHumanTask";
import useGetTaskAnalytics from "hooks/workforceHooks/useGetTaskAnalytics";
import Skeleton from "react-loading-skeleton";
import { formatMoney, getFromStorage } from "helpers";
import useGetAiAgents from "hooks/workforceHooks/useGetAiAgents";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import { assign, debounce, orderBy, truncate } from "lodash";
import useGetAiTeammates from "hooks/workforceHooks/useGetAiTeammates";
import { useChannel } from "ably/react";
import useGetUserProfile from "hooks/useGetUserProfile";
import ModalContainer from "components/common/ModalContainer";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { authActions, productionActions, taskActions } from "redux/Ldb/actions";
import { AccountPermissions } from "types/permissions";

const TaskManager = () => {
    const dispatch = useAppDispatch();
    const tabs = ["Human tasks", "AI tasks"];
    const [activeTab, setActiveTab] = useState(0);
    const [selectedView, setSelectedView] = useState("grid");
    const [activeTasks, setActiveTasks] = useState([]);
    const [pendingTasks, setPendingTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [failedTasks, setFailedTasks] = useState([]);
    const [taskModal, setTaskModal] = useState<boolean>(false);
    const [qcData, setQcData] = useState<{ [key: string]: any }[]>([{}]);
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [workOrderList, setWorkOrderList] = useState<{ [key: string]: any }[]>([]);
    const [debounceWorkOrderSearch, setDebounceWorkOrderSearch] = useState<string>("");
    const [workOrderSearch, setWorkOrderSearch] = useState<string>("");
    const [workOrderId, setWorkOrderId] = useState<string>("");
    const [searchHumanTask, setSearchHumanTask] = useState<string>("");
    const [humanTaskType, setHumanTaskType] = useState<string>("");
    const [humanStatus, setHumanStatus] = useState<string>("");

    const [type, setType] = useState<string>("");

    const [params] = useSearchParams();
    const tab = params?.get("tab");
    const profile = getFromStorage("ally-user");
    const [userProfile ] = useGetUserProfile();

    const navigate = useNavigate();
    const { data: taskAnalytics, isFetching: isFetchingAnalytics } = useGetTaskAnalytics();
    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);
    const { fetchingWorkOrderList, fetchedWorkOrderListSuccess } = useAppSelector(
        (state) => state.production
    );
    const { creatingHumanTask, createHumanTaskSuccess } = useAppSelector((state) => state.task);

    const { data: aiAgents, isFetching: isFetchingAiAgent } = useGetAiAgents();
    const {
        isFetching: fetchingAiTeammates,
        data: aiTeammates,
        search: searchTeammate,
        handleSearch: handleTeammateSearch,
        handleDebouncedChange: handleTeammateDebouncedChange,
        selectedTeammate,
        handleAssignedToFilterChange,
        handleClearAssignedToFilter,
    } = useGetAiTeammates();
    const {
        data: tasks,
        search,
        handleSearch,
        handleDebouncedChange,
        handleTaskStatusChange,
        handleClearTaskStatusFilter,
        handleTaskTypeChange,
        handleClearTaskTypeFilter,
        taskStatus,
        taskType,
        pagination,
        setUpdatedPage,
        setLoadMore,
    } = useGetTaskList(selectedTeammate?.value);
    const {
        data: pendingHumanTasks,
        setDebouncedSearch: setDebouncedSearchPending,
        setTaskStatus: setStatusPending,
        pagination: paginationPending,
        setUpdatedPage: setUpdatedPagePending,
        setLoadMore: setLoadMorePending,
    } = useGetPendingHumanTaskList();
    const {
        data: completedHumanTasks,
        setDebouncedSearch: setDebouncedSearchCompleted,
        setTaskStatus: setStatusCompleted,
        pagination: paginationCompleted,
        setUpdatedPage: setUpdatedPageCompleted,
        setLoadMore: setLoadMoreCompleted,
    } = useGetCompletedHumanTask();

    const {
        data: failedHumanTasks,
        setDebouncedSearch: setDebouncedSearchFailed,
        setTaskStatus: setStatusFailed,
        pagination: paginationFailed,
        setUpdatedPage: setUpdatedPageFailed,
        setLoadMore: setLoadMoreFailed,
    } = useGetFailedHumanTask();
    const {
        data: allHumanTasks,
        setDebouncedSearch: setDebouncedSearchAllHuman,
        setTaskStatus: setStatusAllHuman,
        pagination: paginationAllHumanTask,
        setUpdatedPage: setUpdatedPageAllHumanTask,
        setLoadMore: setLoadMoreAllHumanTask,
        totalHumanTask,
    } = useGetAllHumanTask();

    const handleDebouncedSearch = debounce((e) => {
        if (selectedView !== "grid") {
            setDebouncedSearchAllHuman(e?.target?.value);
        } else {
            setDebouncedSearchCompleted(e?.target?.value);
            setDebouncedSearchPending(e?.target?.value);
            setDebouncedSearchFailed(e?.target?.value);
        }
    }, 800);

    const handleClearSearch = () => {
        setSearchHumanTask("");
        setDebouncedSearchAllHuman("");
        setDebouncedSearchCompleted("");
        setDebouncedSearchPending("");
        setDebouncedSearchFailed("");
    };

    useEffect(() => {
        if (selectedView !== "grid") {
            setStatusAllHuman(humanStatus);
            setStatusPending("pending");
            setStatusCompleted("passed");
            setStatusFailed("failed");
        } else {
            if (humanStatus === "pending") {
                setStatusCompleted("");
                setStatusFailed("");
                setStatusPending("pending");
            } else if (humanStatus === "completed") {
                setStatusPending("");
                setStatusFailed("");
                setStatusCompleted("passed");
            } else if (humanStatus === "failed") {
                setStatusPending("");
                setStatusCompleted("");
                setStatusFailed("failed");
            }
        }
    }, [humanStatus]);

    useEffect(() => {
        setHumanTaskType("");
        setHumanStatus("");
        handleClearAssignedToFilter();
        handleClearTaskStatusFilter();
        handleClearTaskTypeFilter();
    }, [activeTab, selectedView]);

    useEffect(() => {
        if (taskModal) {
            dispatch(productionActions?.fetchWorkOrderList(40, 1, debounceWorkOrderSearch));
        }
    }, [taskModal, debounceWorkOrderSearch]);

    useEffect(() => {
        if (!taskModal) {
            setWorkOrderId("");
            setQcData([{}]);
        }
    }, [taskModal]);

    useEffect(() => {
        if (Boolean(fetchedWorkOrderListSuccess)) {
            setWorkOrderList(fetchedWorkOrderListSuccess?.workOrders);
        }
    }, [fetchedWorkOrderListSuccess]);

    useEffect(() => {
        dispatch(
            authActions.getTeamMembers(
                40,
                debouncedSearch,
                null,
                userProfile?.isMultiBrandAccount || userProfile?.accountType === "brand"
                    ? profile?.mainToken || profile?.token
                    : userProfile?.token
            )
        );
    }, [
        dispatch,
        debouncedSearch,
        userProfile?.token,
        userProfile?.isMultiBrandAccount,
        userProfile?.accountType,
        profile?.mainToken,
        profile?.token,
    ]);

    const handleDebouncedChangeTeamMember = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    const handleAddNewTask = () => {
        const body = qcData?.filter((task) => task?.task && task?.assigneeId);
        dispatch(taskActions?.createHumanTask(body, workOrderId?.split("_")[0]));
    };

    useEffect(() => {
        if (Boolean(createHumanTaskSuccess)) {
            dispatch(taskActions?.resetCreateHumanTask());
            setTaskModal(false);
            if (selectedView === "grid") {
                dispatch(taskActions.fetchPendingHumanTaskList(10, 1, "pending"));
            } else {
                dispatch(taskActions.fetchAllHumanTaskList(10, 1));
            }
            setQcData([{}]);
            setWorkOrderId("");
        }
    }, [createHumanTaskSuccess]);

    const {} = useChannel(
        `agent_tasks_${profile?.user?.parentId || profile?.user?._id}`,
        (taskData) => {
            const isTask = taskData?.name?.split("_")?.length === 2;

            if (isTask) {
                let activeTasksCopy = [...activeTasks];
                let pendingTasksCopy = [...pendingTasks];
                let completedTasksCopy = [...completedTasks];
                let failedTasksCopy = [...failedTasks];

                const activeTaskIndex = activeTasksCopy?.findIndex(
                    (task) => task?._id === taskData?.data?.task?._id
                );
                const pendingTaskIndex = pendingTasksCopy?.findIndex(
                    (task) => task?._id === taskData?.data?.task?._id
                );
                const completedTaskIndex = pendingTasksCopy?.findIndex(
                    (task) => task?._id === taskData?.data?.task?._id
                );
                const failedTaskIndex = failedTasksCopy?.findIndex(
                    (task) => task?._id === taskData?.data?.task?._id
                );

                const moveToPending =
                    taskData?.data?.task?.status?.toLowerCase() === "pending" ||
                    taskData?.data?.task?.status?.toLowerCase() === "attention required";
                const moveToActive = taskData?.data?.task?.status?.toLowerCase() === "in progress";
                const moveToCompleted = taskData?.data?.task?.status?.toLowerCase() === "completed";
                const moveToFailed = taskData?.data?.task?.status?.toLowerCase() === "failed";

                if (activeTaskIndex !== -1) {
                    activeTasksCopy?.splice(activeTaskIndex, 1);
                }

                if (pendingTaskIndex !== -1) {
                    pendingTasksCopy?.splice(pendingTaskIndex, 1);
                }

                if (completedTaskIndex !== -1) {
                    completedTasksCopy?.splice(completedTaskIndex, 1);
                }

                if (failedTaskIndex !== -1) {
                    failedTasksCopy?.splice(failedTaskIndex, 1);
                }

                if (moveToPending) {
                    pendingTasksCopy = [taskData?.data?.task, ...pendingTasksCopy];
                }
                if (moveToActive) {
                    activeTasksCopy = [taskData?.data?.task, ...activeTasksCopy];
                }
                if (moveToCompleted) {
                    completedTasksCopy = [taskData?.data?.task, ...completedTasksCopy];
                }
                if (moveToFailed) {
                    failedTasksCopy = [taskData?.data?.task, ...failedTasksCopy];
                }

                setPendingTasks(orderBy(pendingTasksCopy, "createdAt", ["desc"]));
                setActiveTasks(orderBy(activeTasksCopy, "createdAt", ["desc"]));
                setCompletedTasks(orderBy(completedTasksCopy, "createdAt", ["desc"]));
                setFailedTasks(orderBy(failedTasksCopy, "createdAt", ["desc"]));
            }
        }
    );

    useEffect(() => {
        setActiveTasks(orderBy(tasks?.activeTasks, "createdAt", ["desc"]));
        setPendingTasks(orderBy(tasks?.pendingTasks, "createdAt", ["desc"]));
        setCompletedTasks(orderBy(tasks?.completedTasks, "createdAt", ["desc"]));
        setFailedTasks(orderBy(tasks?.failedTasks, "createdAt", ["desc"]));
    }, [tasks]);

    useEffect(() => {
        if (tab) {
            setActiveTab(Number(tab));
        }
    }, [tab]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div>
                        {selectedView === "grid" ? (
                            <GridViewHumanTask
                                failedTasks={failedHumanTasks}
                                pendingTasks={pendingHumanTasks}
                                completedTasks={completedHumanTasks}
                                pagination={[
                                    paginationPending,
                                    paginationCompleted,
                                    paginationFailed,
                                ]}
                                setUpdatedPage={[
                                    setUpdatedPagePending,
                                    setUpdatedPageCompleted,
                                    setUpdatedPageFailed,
                                ]}
                                setLoadMore={[
                                    setLoadMorePending,
                                    setLoadMoreCompleted,
                                    setLoadMoreFailed,
                                ]}
                                humanStatus={humanStatus}
                            />
                        ) : (
                            <ListView
                                allHumanTasks={allHumanTasks}
                                pagination={paginationAllHumanTask}
                                type="humanTasks"
                            />
                        )}
                    </div>
                );
            case 1:
                return (
                    <div>
                        {selectedView === "grid" ? (
                            <GridView
                                activeTasks={activeTasks}
                                pendingTasks={pendingTasks}
                                completedTasks={completedTasks}
                                failedTasks={failedTasks}
                                pagination={pagination}
                                setUpdatedPage={setUpdatedPage}
                                setLoadMore={setLoadMore}
                            />
                        ) : (
                            <ListView data={tasks} pagination={pagination} />
                        )}
                    </div>
                );

            default:
                break;
        }
    };

    const handleQcData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...qcData];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setQcData(copyArr);
    };

    const QualityControlTableHeader = [
        { title: "Task", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        { title: "Assign to ", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        { title: "Status", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        { title: "", widthClass: "w-[7%] !font-rocGroteskMedium !border-slate-200" },
    ];

    const QualityControlTableBody = qcData?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-slate-900 flex items-center  font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.task || ""}
                            name={"task"}
                            type="text"
                            required={(item?.assigneeId && true) || (idx === 0 && true)}
                            onChange={(e) => handleQcData(e, idx)}
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-900 flex items-center  font-rocGroteskMedium `}
                    >
                        <SelectInput
                            value={item?.assigneeId || ""}
                            name="assigneeId"
                            handleChange={(name, value) => {
                                handleQcData({ target: { name, value } }, idx);
                            }}
                            dropdownOptions={teamMembers?.map((member) => ({
                                label: member?.fullName ? member?.fullName : member?.email,
                                value: member?._id,
                            }))}
                            className="!border-none"
                            dropdownClassName="!text-xs !text-gm-50 !font-rocGroteskMedium !max-h-[150px]"
                            isRequired={item?.task && true}
                            clearValue={true}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 px-2.5 text-sm  flex gap-2 items-center font-rocGroteskMedium `}
                    >
                        <div className="bg-[#FEF3C7] w-[20px] h-[20px] flex items-center justify-center rounded-full">
                            <i className="ri-time-line text-[#D97706] text-xs"></i>
                        </div>

                        <p className="text-slate-900 text-sm">QC Pending</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center justify-center  font-rocGroteskMedium `}
                    >
                        <i
                            className="ri-delete-bin-line text-[#64748B] text-sm"
                            onClick={() => {
                                if (qcData?.length !== 1) {
                                    const copyArr = [...qcData];
                                    copyArr?.splice(idx, 1);
                                    setQcData(copyArr);
                                }
                            }}
                        ></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div>
            <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
                <div className="mb-6">
                    <PageTitleSection
                        leftComp={
                            <div className="flex items-center space-x-3">
                                <h3 className="text-xl font-rocGroteskMedium">Task manager</h3>
                            </div>
                        }
                    />
                </div>

                {isFetchingAnalytics ? (
                    <div className="grid grid-cols-4 items-center space-x-3 w-full mb-8">
                        {[1, 2, 3, 4]?.map((_, idx) => (
                            <Skeleton
                                key={idx}
                                width={"100%"}
                                height={127}
                                highlightColor="#E2E8F0"
                                borderRadius={8}
                                baseColor="#F1F5F9"
                                className="custom-skeleton"
                            />
                        ))}
                    </div>
                ) : (
                    <div className="grid grid-cols-3 items-center space-x-3 w-full mb-8">
                        <WorkforceAnalyticsCard
                            title="All tasks"
                            value={formatMoney(true).format(
                                taskAnalytics?.allTasks?.current + totalHumanTask
                            )}
                            bottomComp={
                                Boolean(taskAnalytics?.allTasks?.percentage) && (
                                    <div className="flex items-center space-x-2">
                                        <div
                                            className={`${
                                                String(
                                                    taskAnalytics?.allTasks?.percentage
                                                )?.startsWith("-")
                                                    ? "text-r-55 bg-r-25"
                                                    : "bg-[#F0FDF4] text-[#22C55E]"
                                            } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                        >
                                            <i className="ri-arrow-up-line mt-[-2px] "></i>
                                            <span>
                                                {taskAnalytics?.allTasks?.percentage?.toFixed(0)}%
                                            </span>
                                        </div>
                                        <span className="text-sm text-slate-500">
                                            since last month
                                        </span>
                                    </div>
                                )
                            }
                        />
                        <WorkforceAnalyticsCard
                            title="Ongoing AI Tasks"
                            value={formatMoney(true).format(
                                taskAnalytics?.ongoingAiAgentTasks?.current
                            )}
                            bottomComp={
                                Boolean(taskAnalytics?.ongoingAiAgentTasks?.percentage) && (
                                    <div className="flex items-center space-x-2">
                                        <div
                                            className={`${
                                                String(
                                                    taskAnalytics?.ongoingAiAgentTasks?.percentage
                                                )?.startsWith("-")
                                                    ? "text-r-55 bg-r-25"
                                                    : "bg-[#F0FDF4] text-[#22C55E]"
                                            } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                        >
                                            <i className="ri-arrow-up-line mt-[-2px] "></i>
                                            <span>
                                                {taskAnalytics?.ongoingAiAgentTasks?.percentage?.toFixed(
                                                    0
                                                )}
                                                %
                                            </span>
                                        </div>
                                        <span className="text-sm text-slate-500">
                                            since last month
                                        </span>
                                    </div>
                                )
                            }
                        />

                        <WorkforceAnalyticsCard
                            title="Task that requires human attention"
                            value={`${formatMoney(true).format(
                                taskAnalytics?.taskRequiringHumanAttention?.current + totalHumanTask
                            )}`}
                            bottomComp={
                                Boolean(taskAnalytics?.taskRequiringHumanAttention?.percentage) && (
                                    <div className="flex items-center space-x-2">
                                        <div
                                            className={`${
                                                String(
                                                    taskAnalytics?.taskRequiringHumanAttention
                                                        ?.percentage
                                                )?.startsWith("-")
                                                    ? "text-r-55 bg-r-25"
                                                    : "bg-[#F0FDF4] text-[#22C55E]"
                                            } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                        >
                                            <i className="ri-arrow-up-line mt-[-2px] "></i>
                                            <span>
                                                {taskAnalytics?.taskRequiringHumanAttention?.percentage?.toFixed(
                                                    0
                                                )}
                                                %
                                            </span>
                                        </div>
                                        <span className="text-sm text-slate-500">
                                            since last month
                                        </span>
                                    </div>
                                )
                            }
                        />
                    </div>
                )}

                <div>
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={(idx) => {
                            setActiveTab(idx);
                            navigate(`/dashboard/task?tab=${idx}`, {
                                replace: true,
                                state: { internalNavigation: true },
                            });
                        }}
                        component={displayActiveTab()}
                        className="!px-0"
                        showButtonClassName
                        itemClassName="!pb-2 max-lg:whitespace-nowrap"
                        borderLineClase={"!text-slate-100 w-fit max-lg:w-full mb-6"}
                        showButton={
                            <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                                <div className="rounded flex h-[40px] w-[80px] border border-slate-200">
                                    <span
                                        onClick={() => {
                                            setSelectedView("grid");
                                            if (activeTab === 0) {
                                                handleClearSearch();
                                            }
                                        }}
                                        className={`h-full w-full flex items-center justify-center hover:bg-slate-200 cursor-pointer border-r border-slate-200 ${
                                            selectedView === "grid" && "bg-slate-200"
                                        }`}
                                    >
                                        <i className="ri-layout-grid-line text-base"></i>
                                    </span>
                                    <span
                                        onClick={() => {
                                            setSelectedView("list");
                                            handleClearSearch();
                                        }}
                                        className={`h-full w-full flex items-center justify-center hover:bg-slate-200 cursor-pointer ${
                                            selectedView === "list" && "bg-slate-200"
                                        }`}
                                    >
                                        <i className="ri-list-check text-base"></i>
                                    </span>
                                </div>

                                <TextInput
                                    name={"search"}
                                    value={activeTab === 0 ? searchHumanTask : search}
                                    type={"text"}
                                    inputPlaceholder={"Search tasks"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={(e) =>
                                        activeTab === 0
                                            ? setSearchHumanTask(e?.target?.value)
                                            : handleSearch(e)
                                    }
                                    onInput={
                                        activeTab === 0
                                            ? handleDebouncedSearch
                                            : handleDebouncedChange
                                    }
                                    containerClassname={"!w-[250px] max-sm:!w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] max-sm:!w-full !pl-[25px] !h-[40px] !bg-white !border !border-slate-200"
                                    }
                                />

                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        activeTab === 0
                                            ? setHumanTaskType(value)
                                            : handleTaskTypeChange(value);
                                    }}
                                    dropdown={
                                        <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                {activeTab === 0 && humanTaskType
                                                    ? humanTaskType
                                                    : camelCaseToRegularCase(taskType) &&
                                                      camelCaseToRegularCase(taskType) !==
                                                          "undefined"
                                                    ? camelCaseToRegularCase(taskType)
                                                    : "Task type"}
                                            </span>

                                            <div className="flex items-center space-x-2">
                                                {(taskType || humanTaskType) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            activeTab === 0
                                                                ? setHumanTaskType("")
                                                                : handleClearTaskTypeFilter();
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    wholeContainerClass={`max-sm:!w-full`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={
                                        activeTab === 0
                                            ? [
                                                  {
                                                      label: "Work Order Quality Checklist",
                                                      value: "Quality Checklist",
                                                  },
                                              ]
                                            : aiAgents?.map((agent) => {
                                                  return {
                                                      label: camelCaseToRegularCase(agent?.type),
                                                      value: agent?.type,
                                                  };
                                              })
                                    }
                                />

                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        activeTab === 0
                                            ? setHumanStatus(value)
                                            : handleTaskStatusChange(value);
                                    }}
                                    dropdown={
                                        <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium capitalize">
                                                {activeTab === 0 && humanStatus
                                                    ? humanStatus
                                                    : activeTab === 1 && taskStatus
                                                    ? taskStatus
                                                    : "Status"}
                                            </span>

                                            <div className="flex items-center space-x-2">
                                                {(taskStatus || humanStatus) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            if (activeTab === 0) {
                                                                setHumanStatus("");
                                                                setStatusAllHuman("");
                                                                setStatusCompleted("passed");
                                                                setStatusFailed("failed");
                                                                setStatusPending("pending");
                                                            } else {
                                                                handleClearTaskStatusFilter();
                                                            }
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    wholeContainerClass={`max-sm:!w-full`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={
                                        activeTab === 0
                                            ? [
                                                  {
                                                      label: "Pending",
                                                      value: "pending",
                                                  },
                                                  {
                                                      label: "Completed",
                                                      value: "completed",
                                                  },
                                                  {
                                                      label: "Failed",
                                                      value: "failed",
                                                  },
                                              ]
                                            : [
                                                  {
                                                      label: "Pending",
                                                      value: "pending",
                                                  },
                                                  {
                                                      label: "In Progress",
                                                      value: "in progress",
                                                  },
                                                  {
                                                      label: "Completed",
                                                      value: "completed",
                                                  },
                                                  {
                                                      label: "Failed",
                                                      value: "failed",
                                                  },
                                                  {
                                                      label: "Attention Required",
                                                      value: "attention required",
                                                  },
                                              ]
                                    }
                                />

                                {/* {activeTab === 1 && (
                                    <Dropdown
                                        handleChange={(name, value) => {
                                            console.log("");
                                        }}
                                        dropdown={
                                            <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                                <span
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                                >
                                                    {truncate(selectedTeammate?.name, {
                                                        length: 20,
                                                    }) || "Assigned to"}
                                                </span>
                                                <div className="flex items-center space-x-1">
                                                    {selectedTeammate?.name && (
                                                        <i
                                                            onClick={handleClearAssignedToFilter}
                                                            className="ri-close-circle-line text-slate-500"
                                                        ></i>
                                                    )}
                                                    <i className="ri-arrow-down-s-line text-g-75"></i>
                                                </div>
                                            </div>
                                        }
                                        isLoading={fetchingAiTeammates}
                                        search={
                                            <div>
                                                <TextInput
                                                    value={searchTeammate}
                                                    name={"search"}
                                                    type={"text"}
                                                    required={false}
                                                    onChange={handleTeammateSearch}
                                                    onInput={handleTeammateDebouncedChange}
                                                    inputPlaceholder="Search AI teammates"
                                                    containerClassname=""
                                                    // inputContainerClassname={"!border-none !rounded-none "}
                                                    inputClassName="w-full"
                                                />
                                            </div>
                                        }
                                        wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                                        dropdownContainerClasses={`shadow-cardShadow-4 !w-full !max-w-full !right-0 min-w-full border border-slate-100 rounded !max-h-fit !pt-0`}
                                        dropdownOptions={[
                                            {
                                                customChild: (
                                                    <div>
                                                        <div className="max-h-[280px]">
                                                            {aiTeammates?.map((teammate) => {
                                                                return (
                                                                    <div
                                                                        key={teammate?._id}
                                                                        onClick={() =>
                                                                            handleAssignedToFilterChange(
                                                                                teammate?.teamMateName,
                                                                                teammate?._id
                                                                            )
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer `}
                                                                        >
                                                                            <div>
                                                                                <div className="flex items-center space-x-3">
                                                                                    <div>
                                                                                        <span className="block text-xs font-rocGroteskMedium">
                                                                                            {truncate(
                                                                                                teammate?.teamMateName,
                                                                                                {
                                                                                                    length: 30,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                        <span className="block text-[10px] text-slate-500 font-rocGroteskMedium">
                                                                                            {truncate(
                                                                                                camelCaseToRegularCase(
                                                                                                    teammate?.agentType
                                                                                                ),
                                                                                                {
                                                                                                    length: 50,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    />
                                )} */}
                                {/* <Button
                                    btnText="Settings"
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !bg-transparent !border !border-slate-200 !text-[13px] !whitespace-nowrap"
                                    icon={<i className="ri-settings-2-line text-base"></i>}
                                    onClick={() => {}}
                                />

                                <Button
                                    btnText="Create new task"
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !whitespace-nowrap"
                                    onClick={() => {
                                        // navigate(`/dashboard/workforce/create-ai-teammate`);
                                    }}
                                /> */}

                                {activeTab === 0 && (
                                    <Button
                                        btnText={"Add new task"}
                                        btnPermission={[
                                            AccountPermissions.all_access,
                                            AccountPermissions.create_ai_teammate,
                                        ]}
                                        btnClassname="!px-4 !py-2.5 !w-fit"
                                        onClick={() => setTaskModal(true)}
                                    />
                                )}
                            </div>
                        }
                    />
                </div>
            </div>

            <ModalContainer
                open={taskModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px] max-h-[80vh] rounded-[8px]"
                showCloseIcon={false}
                closeModal={() => {
                    setTaskModal(true);
                }}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddNewTask();
                    }}
                >
                    <div className="sticky top-0 bg-white rounded-t-lg z-[1200] px-6 font-rocGroteskMedium border-b border-b-slate-200 flex items-center justify-between py-3">
                        <p className="mt-1 text-base text-gm-50 ">Add new task to a work order</p>
                        <i
                            className={`${
                                !creatingHumanTask && "cursor-pointer"
                            } ri-close-fill text-lg text-gm-50 `}
                            onClick={() => {
                                !creatingHumanTask && setTaskModal(false);
                            }}
                        ></i>
                    </div>

                    <div className="px-6 space-y-3 py-4">
                        <div className="grid grid-cols-2 gap-4">
                            <SelectInput
                                value={workOrderId?.split("_")[0]}
                                name="workOrderId"
                                inputPlaceholder={"Work Order Id"}
                                handleChange={(name, value) => {
                                    // setWorkOrderId(value.split("_")[0]);
                                    setWorkOrderId(value);
                                }}
                                handleInputChange={debounce((evt) => {
                                    setDebounceWorkOrderSearch(evt.target.value);
                                }, 800)}
                                isRequired={true}
                                className=""
                                inputContainerClassName="!mb-0"
                                searchLoading={fetchingWorkOrderList}
                                dropdownOptions={workOrderList.map((item) => ({
                                    label: "WO-" + item.taId + "_" + item.productName,
                                    value: item._id + "_" + item.productName,
                                }))}
                                clearValue={true}
                            />
                            <TextInput
                                value={
                                    workOrderId
                                        ? workOrderId.split("_")[1]
                                        : "Select work order to see product name"
                                }
                                name={"productName"}
                                type={"text"}
                                placeholder={"Product name"}
                                required={true}
                                // onChange={(e) => setBomName(e?.target.value)}
                                containerClassname=""
                                inputClassName="!text-slate-500"
                                disabled={true}
                            />
                        </div>

                        <div className="!mt-6">
                            <p className="text-base text-[#334155] !mb-4">
                                Quality control checklists
                            </p>
                            <CustomTable
                                tableBody={QualityControlTableBody}
                                tableHeader={QualityControlTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200 "
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass="!border-slate-200"
                                bodyItemClass="hover:!bg-transparent !border-slate-200"
                            />
                            <Button
                                btnText="Add more QC checklist"
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_ai_teammate,
                                ]}
                                btnClassname=" !my-4 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                onClick={() => setQcData((prev) => [...prev, {}])}
                            />
                        </div>
                    </div>

                    <div
                        className={`border-t border-t-slate-200 py-4 px-6 flex items-center w-full sticky bottom-0 bg-white
                            justify-end  space-x-4`}
                    >
                        <div className="flex items-center  justify-end gap-4 ">
                            <Button
                                btnText={"Cancel"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setTaskModal(false);
                                    setQcData([{}]);
                                }}
                            />
                            <Button
                                type="submit"
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_ai_teammate,
                                ]}
                                btnText="Add new task(s)"
                                isLoading={creatingHumanTask}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </div>
    );
};

export default TaskManager;
