import React, { useCallback, useEffect, useRef, useState } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { displayFileIcon, getCurrencyFromCurrencyCode, getFromStorage, onScroll } from "helpers";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import { ProductDataType } from "types/productDataType";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, productionActions, vendorActions } from "redux/Ldb/actions";
import { debounce, isEqual, truncate } from "lodash";
import VendorAvatar from "components/common/VendorAvatar";
import Loader from "components/common/Loader";
import Button from "components/common/Button";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import ModalContainer from "components/common/ModalContainer";
import CustomTable from "components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import { unitOfMeasurement } from "variables/unitOfMeasurement";
import useGetProductDetails from "hooks/inventoryHooks/useGetProductDetails";
import { productCategories } from "variables/productCategories";
import ToggleInput from "components/common/InputField/ToggleInput";
import useGetUserProfile from "hooks/useGetUserProfile";
import EmptyStateComp from "components/common/EmptyStateComp";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";

type SingleProductProps = {
    productData: ProductDataType;
    handleChange: (name: string, value: any) => void;
    setAddWarehouseModal: React.Dispatch<React.SetStateAction<boolean>>;
    productImages?: string[];
    productImage?: string;
    setDeleteImageModal?: React.Dispatch<React.SetStateAction<boolean>>;
    setProductImage?: React.Dispatch<React.SetStateAction<string>>;
    handleInputUploadAction?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    uploadingFiles?: boolean;
    handleUploadAttachments?: (files: File[]) => void;
    uploadingAttachments?: boolean;
    attachments?: { filename: string; path: string }[];
    setSelectedFilePath?: React.Dispatch<React.SetStateAction<string>>;
    setDeleteAttachmentModal?: React.Dispatch<React.SetStateAction<boolean>>;
    billOfMaterialIds?: any;
    setBillOfMaterialIds?: any;
};

const SingleProduct = ({
    productData,
    handleChange,
    setAddWarehouseModal,
    productImages,
    productImage,
    setDeleteImageModal,
    setProductImage,
    handleInputUploadAction,
    uploadingFiles,
    handleUploadAttachments,
    uploadingAttachments,
    attachments,
    setSelectedFilePath,
    setDeleteAttachmentModal,
    setBillOfMaterialIds,
}: SingleProductProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [onboardedVendors, setOnboardedVendors] = useState<{ [key: string]: any }[]>([]);
    const [warehouses, setWarehouses] = useState<{ [key: string]: any }[]>([]);
    const [productModal, setProductModal] = useState<boolean>(false);
    const [newRawMaterialModal, setNewRawMaterialModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [product, setProduct] = useState<{ [key: string]: any }[]>([]);
    const [debouncedProductSearch, setDebouncedProductSearch] = useState<string>("");
    const [productShow, setProductShow] = useState<boolean>(true);
    const [priceShow, setPriceShow] = useState<boolean>(false);
    const [recipeShow, setRecipeShow] = useState<boolean>(false);
    const [documentShow, setDocumentShow] = useState<boolean>(false);
    const [warehouseShow, setWarehouseShow] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const [addSelectedProduct, setAddSelectedProduct] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [dashboardContent, setDashboardContent] = useState<any>();
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [addNew, setAddNew] = useState<boolean>(false);
    const [newUnit, setNewUnit] = useState<string>("");
    const [bodyData, setBodyData] = useState<{ [key: string]: any }>({});
    const [unitMeasure, setUnitMeasure] = useState<
        {
            label: string | JSX.Element;
            value: string;
        }[]
    >(unitOfMeasurement);
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [newRawMaterialForm, setNewRawMaterialForm] = useState<{ [key: string]: any }[]>([
        {
            productName: "",
            price: "",
            uom: "",
            quantity: "",
            supplier: [],
            warehouse: "",
        },
    ]);

    const [profile] = useGetUserProfile();
    const userProfile = getFromStorage("ally-user");
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setAddNew(false);
                setNewUnit("");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerRef]);
    useEffect(() => {
        if (inputRef?.current) {
            inputRef?.current?.focus();
        }
    }, [inputRef?.current, addNew]);

    useEffect(() => {
        if (productModal) {
            setDashboardContent(document.getElementById("scrollableTable"));
        }
    }, [productModal]);

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setPage(() => page + 1);
                    setLoadMore(true);
                }
            ),
        [dashboardContent, paginate]
    );
    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);
    const { fetchedLdbVendorsSuccess, fetchingLdbVendors } = useAppSelector(
        (state) => state.vendor
    );
    const {
        fetchingWarehouseList,
        fetchedWarehouseListSuccess,
        createWarehouseSuccess,
        fetchingInventoryList,
        fetchedInventoryListSuccess,
        addingNewRawMaterial,
        addNewRawMaterialSuccess,
    } = useAppSelector((state) => state.inventory);

    const { fetchingNonFinishedInventory, fetchedNonFinishedInventorySuccess } = useAppSelector(
        (state) => state.production
    );

    const { addingVendor, addVendorSuccess } = useAppSelector((state) => state?.vendor);

    const { data } = useGetProductDetails();

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    const handleProductDebouncedChange = debounce((e) => {
        setPage(1);
        setDebouncedProductSearch(e.target.value);
    }, 1500);
    const handleWarehouseDebouncedChange = debounce((e) => {
        setDebouncedWarehouseSearch(e.target.value);
    }, 1500);

    useEffect(() => {
        if (Boolean(fetchedNonFinishedInventorySuccess)) {
            setProduct((prev) =>
                page === 1
                    ? fetchedNonFinishedInventorySuccess?.products
                    : [...prev, ...fetchedNonFinishedInventorySuccess?.products]
            );
            setPaginate({
                current: fetchedNonFinishedInventorySuccess?.pagination?.current,
                number_of_pages: fetchedNonFinishedInventorySuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedNonFinishedInventorySuccess, page]);

    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(20, debouncedSearch, "accepted"));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            setOnboardedVendors(fetchedLdbVendorsSuccess);
        }
    }, [fetchedLdbVendorsSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, "", debouncedWarehouseSearch));
    }, [dispatch, debouncedWarehouseSearch, createWarehouseSuccess]);

    useEffect(() => {
        dispatch(productionActions.getNonFinishedInventory(10, page, debouncedProductSearch));
    }, [dispatch, debouncedProductSearch, page, addNewRawMaterialSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouses(fetchedWarehouseListSuccess?.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    const handleAddButton = () => {
        const copySelectedProduct = [...selectedProduct];
        const addQuantity = copySelectedProduct?.map((item) => {
            return {
                ...item,
                ...(item?.inventoryId && { inventoryId: item?.inventoryId }),
                ...(item?.productQuantity && { productQuantity: item?.productQuantity }),
                ...(item?.scrap && { scrap: item?.scrap }),
            };
        });
        setAddSelectedProduct([...addQuantity]);
        setProductModal(false);
    };

    const handleSelectAllProducts = () => {
        if (product?.length === selectedProduct?.length) {
            setSelectedProduct([]);
        } else {
            setSelectedProduct(product);
        }
    };

    const handleSelectProduct = (item) => {
        const checkIfSelected = selectedProduct?.findIndex((prod) => prod?._id === item?._id);

        if (checkIfSelected === -1) {
            setSelectedProduct((prev) => {
                const selected = [...prev];
                selected.push(item);
                return selected;
            });
        } else {
            const copySelectedProduct = [...selectedProduct];
            const filter = copySelectedProduct?.filter((prod) => prod?._id !== item?._id);
            setSelectedProduct(filter);
        }
    };

    const handleInputChange = (e, idx) => {
        const { name, value } = e.target;
        const copySelectedProduct = [...addSelectedProduct];
        copySelectedProduct[idx] = {
            ...copySelectedProduct[idx],
            [name]: value,
        };
        setAddSelectedProduct(copySelectedProduct);
    };

    useEffect(() => {
        if (addSelectedProduct?.length > 0) {
            const copySelectProduct = [...addSelectedProduct];
            const getProductIds = copySelectProduct?.map((item) => {
                const { _id, inventoryId, productQuantity, scrap } = item;
                return {
                    inventoryId: _id || inventoryId,
                    quantity: productQuantity,
                    scrap,
                };
            });

            setBillOfMaterialIds(getProductIds);
        }
    }, [addSelectedProduct]);

    useEffect(() => {
        if (addSelectedProduct?.length > 0) {
            setSelectedProduct(addSelectedProduct);
        }
    }, [addSelectedProduct]);

    useEffect(() => {
        const formatBillOfMaterialIds = data?.billOfMaterialIds?.map((item) => ({
            ...item?.inventory,
            inventoryId: item?.inventoryId,
            productQuantity: item?.quantity,
            scrap: item?.scrap,
        }));

        setAddSelectedProduct(formatBillOfMaterialIds);
    }, [data]);

    useEffect(() => {
        setSelectedProduct([]);
        setAddSelectedProduct([]);
        setBillOfMaterialIds([]);
    }, [productData?.inventoryCategory]);

    const tableModalHeader = [
        {
            title: "",
            widthClass: "w-[5%] !font-rocGroteskMedium  !border-r !border-slate-200",
            itemClass: "!py-4",
            onSelectAll: () => {
                handleSelectAllProducts();
            },
            isAllChecked: selectedProduct?.length === product?.length,
        },
        {
            title: "Product name",
            widthClass: "w-[95%] !font-rocGroteskMedium  !border-slate-200",
        },
    ];

    const tableModalBody = product?.map((item, idx) => {
        const isSelected = selectedProduct?.filter((prod) => prod?._id === item?._id);
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                    ></div>
                ),
                widthClass: "!border-b !border-r !border-slate-200",
                isItemChecked: isSelected?.length === 0 ? false : true,
                onCheckBoxClick: () => {
                    handleSelectProduct(item);
                },
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center h-full
                         border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        {item?.productImageDetails?.productImages?.[0] ? (
                            <VendorAvatar
                                imageSrc={item.productImageDetails?.productImages?.[0]}
                                size={32}
                                name={item.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-sm text-slate-500"></i>
                            </div>
                        )}

                        <div className="">
                            <p className="text-gm-50"> {item?.productName}</p>
                            <p className="text-slate-500"> {item?.sku}</p>
                        </div>
                    </div>
                ),
                widthClass: "!border-b  !border-slate-200",
            },
        ];
    });

    const handleRemoveNewRawMaterialRow = (idx: number) => {
        const newRawMaterialFormCopy = [...newRawMaterialForm];

        if (newRawMaterialFormCopy?.length > 1) {
            newRawMaterialFormCopy?.splice(idx, 1);
            setNewRawMaterialForm(newRawMaterialFormCopy);
        } else {
            setNewRawMaterialForm([
                {
                    productName: "",
                    price: "",
                    uom: "",
                    quantity: "",
                    supplier: [],
                    warehouse: "",
                },
            ]);
        }
    };

    const handleNewRawMaterialFormChange = (idx: number, name, value) => {
        const newRawMaterialFormCopy = [...newRawMaterialForm];

        newRawMaterialFormCopy[idx][name] = value;

        setNewRawMaterialForm(newRawMaterialFormCopy);
    };

    const handleSubmitNewMaterialForm = () => {
        const body: { [key: string]: any } = {
            products: newRawMaterialForm?.map((item) => ({
                productName: item?.productName,
                quantity: {
                    amount: item?.quantity,
                    unit: item?.uom,
                },
                unitCost: {
                    amount: item?.price,
                    currency: "USD",
                },
                vendorIds: item?.supplier,
                warehouseId: item?.warehouse,
            })),
        };

        const areAllFieldsFilled = newRawMaterialForm.every((product) =>
            Object.values(product).every((value) =>
                Array.isArray(value) ? value.length > 0 : value !== ""
            )
        );

        if (!areAllFieldsFilled) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please fill all fields"} type={"success"} />
            ));
            return;
        }

        const isSelfSupplierPresent = body.products?.some((item) =>
            item.vendorIds?.includes("self")
        );

        if (isSelfSupplierPresent) {
            let supplierBody: { [key: string]: any } = {
                // businessName: profile?.profile?.businessName,
                businessName: "Self",

                // "website": "gdsfds",
                email: userProfile?.user?.email,
                source: "online",
            };
            // body.vendorIds = body.vendorIds?.filter((item) => item !== "self");
            setBodyData(body);

            dispatch(vendorActions.addVendor(supplierBody));
        } else {
            dispatch(inventoryActions.addMultipleRawMaterials(body));
        }
    };

    const newRawMaterialModalHeader = [
        {
            title: "Product name",
            widthClass: "w-[25%] !font-rocGroteskMedium  ",
        },
        {
            title: "Unit price (USD)",
            widthClass: "w-[18%] !font-rocGroteskMedium  ",
        },
        {
            title: "Quantity",
            widthClass: "w-[30%] !font-rocGroteskMedium  ",
        },
        {
            title: "Select Supplier",
            widthClass: "w-[18%] !font-rocGroteskMedium ",
        },
        {
            title: "Warehouse",
            widthClass: "w-[18%] !font-rocGroteskMedium ",
        },
        {
            title: "",
            widthClass: "w-[4.2%] !font-rocGroteskMedium ",
        },
    ];

    const newRawMaterialModalBody = newRawMaterialForm?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-1.5 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium w-full`}
                    >
                        <div className="text-center">
                            <TextInput
                                name="productName"
                                type="text"
                                value={item?.productName}
                                onChange={(e) => {
                                    handleNewRawMaterialFormChange(
                                        idx,
                                        e.target.name,
                                        e.target.value
                                    );
                                }}
                                inputPlaceholder="Enter..."
                                inputClassName={" "}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-white !min-w-[84px] !w-full !text-center !px-0 !py-0 !h-[30px] !border-0"
                                }
                                required={true}
                            />
                        </div>
                    </div>
                ),
                // widthClass: "!border-b !border-r !border-slate-200",
            },
            {
                content: (
                    <div
                        className={`py-1.5 pr-3 flex items-center h-full w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        <div className="text-center">
                            <TextInput
                                name="price"
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                value={item?.price}
                                onChange={(e) => {
                                    handleNewRawMaterialFormChange(
                                        idx,
                                        e.target.name,
                                        e.target.value
                                    );
                                }}
                                inputPlaceholder="Enter..."
                                inputClassName={" "}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-white !min-w-[84px] !w-full  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                                required={true}
                            />
                        </div>
                    </div>
                ),
                // widthClass: "!border-b  !border-slate-200",
            },
            {
                content: (
                    <div
                        className={`py-1.5 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        <div className="flex items-center w-full">
                            <div className="w-[60%] pr-1 border-r border-slate-200">
                                <TextInput
                                    name="quantity"
                                    type="number"
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    value={item?.quantity}
                                    onChange={(e) => {
                                        handleNewRawMaterialFormChange(
                                            idx,
                                            e.target.name,
                                            e.target.value
                                        );
                                    }}
                                    inputPlaceholder="Enter..."
                                    inputClassName={" "}
                                    inputContainerClassname={
                                        "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-white !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                    }
                                    required={true}
                                />
                            </div>
                            <div className="w-[40%]">
                                <SelectInput
                                    value={item?.uom}
                                    name="uom"
                                    inputPlaceholder={"UOM"}
                                    handleChange={(name, value) => {
                                        handleNewRawMaterialFormChange(idx, name, value);
                                    }}
                                    isRequired={true}
                                    dropdownOptions={unitMeasure}
                                    dropdownClassName="!w-[180px] left-[-150px] !pb-0"
                                    className={"!border-none"}
                                    rightDropdownContainerClassName="!sticky bottom-0"
                                    inputContainerClassName="!mt-[-9px]"
                                    inputClassName="!pl-2"
                                    customRightDropdownContainer={
                                        <div className="py-2 px-3  flex items-center bg-white border-t-slate-200 border-t !h-[48px] sticky w-full bottom-0">
                                            {!addNew ? (
                                                <div
                                                    className="flex items-center gap-3 w-fit cursor-pointer"
                                                    onClick={() => setAddNew(true)}
                                                >
                                                    <div className="w-8 h-8 bg-[#FFF5DA] rounded-full flex items-center justify-center">
                                                        <i className="ri-add-fill text-[#FFB902]"></i>
                                                    </div>

                                                    <span className="text-sm font-rocGroteskMedium text-[#D39901] underline">
                                                        Add new
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="w-full overflow-x-none flex items-center">
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        ref={inputRef}
                                                        className="caret-gm-50 !w-[80%] !outline-none !border-none text-sm !pr-[40px]  text-gm-50"
                                                        value={newUnit}
                                                        onChange={(e) => setNewUnit(e.target.value)}
                                                    />

                                                    <div className="flex justify-end gap-4 items-center">
                                                        <div
                                                            className={`w-6 h-6 bg-[#fff] rounded-full flex items-center justify-center  shadow-buttonShadow2  ${
                                                                newUnit
                                                                    ? "cursor-pointer"
                                                                    : "cursor-default"
                                                            }`}
                                                            onClick={() => {
                                                                if (newUnit) {
                                                                    unitMeasure.push({
                                                                        label: newUnit,
                                                                        value: newUnit,
                                                                    });
                                                                    handleChange(
                                                                        "unitOfMeasurement",
                                                                        newUnit
                                                                    );
                                                                    setNewUnit("");
                                                                    setAddNew(false);
                                                                }
                                                            }}
                                                        >
                                                            <i className="ri-check-fill text-[#097F54]"></i>
                                                        </div>
                                                        <div
                                                            className="w-6 h-6 bg-[#fff] rounded-full flex items-center justify-center
                                                        shadow-buttonShadow2 cursor-pointer"
                                                            onClick={() => {
                                                                setAddNew(false);
                                                                setNewUnit("");
                                                            }}
                                                        >
                                                            <i className="ri-close-line text-[#E4281D]"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ),
                // widthClass: "!border-b  !border-slate-200",
            },
            {
                content: (
                    <div
                        className={`py-1.5 pr-3 flex items-center h-full w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        <div className=" w-full">
                            <MultiselectInput
                                value={item?.supplier}
                                name="supplier"
                                placeholder={"Select..."}
                                // inputPlaceholder="Supplier"
                                handleChange={(name, value, selectedItems) => {
                                    handleNewRawMaterialFormChange(idx, name, selectedItems);
                                }}
                                searchLoading={fetchingLdbVendors}
                                handleInputChange={(e) => handleDebouncedChange(e)}
                                onClose={() => setDebouncedSearch("")}
                                isRequired={true}
                                className={"!w-full !border-none"}
                                dropdownClassName="!w-[250%] left-[-170px] max-h-[170px]"
                                dropdownOptions={[
                                    {
                                        label: "Self",
                                        value: onboardedVendors?.find(
                                            (item) =>
                                                item?.vendorData?.profile?.businessName === "Self"
                                        )
                                            ? onboardedVendors?.find(
                                                  (item) =>
                                                      item?.vendorData?.profile?.businessName ===
                                                      "Self"
                                              )?.vendorId
                                            : "self",
                                        subText: "My profile",
                                        icon: (
                                            <VendorAvatar
                                                size={30}
                                                imageSrc={profile?.profile?.avatar}
                                                name={profile?.profile?.businessName}
                                            />
                                        ),
                                    },
                                    ...onboardedVendors
                                        ?.filter(
                                            (item) =>
                                                item?.vendorData?.profile?.businessName !== "Self"
                                        )
                                        ?.map((vendor) => ({
                                            label: vendor?.vendorData?.profile?.businessName,
                                            value: vendor.vendorId,
                                            subText: vendor?.category,
                                            icon: (
                                                <VendorAvatar
                                                    size={30}
                                                    imageSrc={vendor?.vendorData?.profile?.avatar}
                                                    name={vendor?.vendorData?.profile?.businessName}
                                                />
                                            ),
                                        })),
                                ]?.filter((item) => Boolean(item?.label))}
                                customDropdownContainer={
                                    <div
                                        onClick={() => {
                                            sessionStorage.setItem(
                                                "scInventory",
                                                JSON.stringify(productData)
                                            );
                                            navigate(`/dashboard/suppliers/create-supplier`, {
                                                state: { internalNavigation: true },
                                            });
                                        }}
                                        className="pt-3 pb-1 px-4 border-t flex items-center space-x-1.5 cursor-pointer"
                                    >
                                        <i className="ri-add-fill text-carribean-green"></i>
                                        <span className="text-sm font-rocGroteskMedium text-carribean-green">
                                            Add new supplier
                                        </span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                ),
                // widthClass: "!border-b  !border-slate-200",
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`py-1.5 pr-3 flex items-center h-full w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        <div className="w-full">
                            <SelectInput
                                value={item?.warehouse}
                                name="warehouse"
                                inputPlaceholder={"Select..."}
                                handleChange={(name, value) => {
                                    handleNewRawMaterialFormChange(idx, name, value);
                                }}
                                searchLoading={fetchingWarehouseList}
                                handleInputChange={(e) => handleWarehouseDebouncedChange(e)}
                                isRequired={true}
                                optionItemContainerClassName="border-b border-slate-200"
                                dropdownClassName="!w-[250%] left-[-170px] max-h-[170px] !pb-0"
                                inputContainerClassName="!mt-[-9px]"
                                className={"!border-none"}
                                dropdownOptions={warehouses?.map((warehouse) => ({
                                    label: warehouse?.warehouseName,
                                    value: warehouse?._id,
                                }))}
                                onClose={() => setDebouncedWarehouseSearch("")}
                                rightDropdownContainerClassName="sticky bottom-0 bg-white"
                                customRightDropdownContainer={
                                    <div
                                        className="flex items-center gap-3 w-fit cursor-pointer px-4 py-1.5 border-t border-slate-200"
                                        onClick={() => setAddWarehouseModal(true)}
                                    >
                                        <div className="w-8 h-8 bg-[#FFF5DA] rounded-full flex items-center justify-center">
                                            <i className="ri-add-fill text-[#FFB902]"></i>
                                        </div>

                                        <span className="text-sm font-rocGroteskMedium text-[#D39901] underline">
                                            Add new
                                        </span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                ),
                // widthClass: "!border-b  !border-slate-200",
            },
            {
                content: (
                    <div
                        className={`py-1.5 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        <i
                            className="ri-delete-bin-line text-base text-[#64748B] cursor-pointer"
                            onClick={() =>
                                !addingNewRawMaterial && !addingVendor
                                    ? handleRemoveNewRawMaterialRow(idx)
                                    : null
                            }
                        ></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0 justify-center",
            },
        ];
    });

    const handleAddNewRawMaterialItem = () => {
        setNewRawMaterialForm((prev) => [
            ...prev,
            {
                productName: "",
                price: "",
                uom: "",
                quantity: "",
                supplier: "",
                warehouse: "",
            },
        ]);
    };

    const calcTotalQuantity = (quantity, scrap) => {
        const quantityNum = Number(quantity);
        const scrapNum = Number(scrap);
        const percentage = (quantityNum * scrapNum) / 100;
        const totalQuantity = percentage + Number(quantity);
        return Number.isInteger(totalQuantity) ? totalQuantity : totalQuantity.toFixed(2);
    };

    const recipesTableHeader = [
        { title: "Products", widthClass: "w-[20%]" },
        {
            title: (
                <div className="flex items-center space-x-1.5">
                    <span className="text-xs">Quantity</span>
                    <span className="text-[10px] text-p-50 px-1.5 flex items-center justify-center h-[20px] border border-p-50 rounded-xl">
                        Editable
                    </span>
                </div>
            ),
            widthClass: "w-[12.1%]",
        },
        {
            title: (
                <div className="flex items-center space-x-1.5">
                    <span className="text-xs">Scrap (%)</span>
                    <span className="text-[10px] text-p-50 px-1.5 flex items-center justify-center h-[20px] border border-p-50 rounded-xl">
                        Editable
                    </span>
                </div>
            ),
            widthClass: "w-[12.1%]",
        },
        { title: "Total Quantity", widthClass: "w-[10.5%]" },
        { title: "UOM", widthClass: "w-[10%]" },
        { title: "Unit cost", widthClass: "w-[10%]" },
        { title: "Total cost", widthClass: "w-[10%]" },
        { title: "", widthClass: "w-[4%]" },
    ];

    console.log(addSelectedProduct[0]?.unitCost?.amount);

    const recipesTableBody = addSelectedProduct?.map((product, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        {product?.productImageDetails?.productAvatar ||
                        product?.productImageDetails?.productImages?.[0] ? (
                            <VendorAvatar
                                imageSrc={
                                    product?.productImageDetails?.productAvatar ||
                                    product?.productImageDetails?.productImages?.[0]
                                }
                                size={32}
                                name={product?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-sm text-slate-500"></i>
                            </div>
                        )}

                        <div className="font-rocGroteskMedium">
                            <span className="block text-sm">
                                {truncate(product?.productName, { length: 25 })}
                            </span>
                            <span className="text-slate-500 text-sm break-all">{product?.sku}</span>
                        </div>
                    </div>
                </div>
            ),
            // tableDataDivClass: "!pl-0",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 w-full`}
                >
                    <TextInput
                        name="productQuantity"
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        value={product?.productQuantity ?? ""}
                        onChange={(e) => handleInputChange(e, idx)}
                        // onInput={handleDebouncedChange}
                        step="any"
                        inputClassName={"!text-center !min-w-[84px] !w-[84px] "}
                        min={0}
                        inputContainerClassname={
                            "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-white !border-g-60 !text-center !px-0 !py-0 !w-auto !h-[30px] "
                        }
                        required={true}
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 w-full`}
                >
                    <TextInput
                        name="scrap"
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        value={product?.scrap ?? ""}
                        required={true}
                        onChange={(e) => handleInputChange(e, idx)}
                        // onInput={handleDebouncedChange}
                        step="any"
                        inputClassName={"!text-center !min-w-[84px] !w-[84px] "}
                        min={0}
                        inputContainerClassname={
                            "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-white !border-g-60 !text-center !px-0 !py-0 !w-auto !h-[30px]"
                        }
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    {product?.productQuantity && (
                        <span>
                            {calcTotalQuantity(product?.productQuantity, product?.scrap || 0)}
                        </span>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{product?.productStockDetails?.unitOfMeasurement}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>
                        {product?.unitCost?.amount
                            ? `${getCurrencyFromCurrencyCode(product?.unitCost?.currency)}${
                                  product?.unitCost?.amount
                              }`
                            : "N/A"}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    {product?.productQuantity && product?.unitCost?.amount && (
                        <span>
                            {`${getCurrencyFromCurrencyCode(product?.unitCost?.currency)}${
                                Number.isInteger(
                                    product?.productQuantity * product?.unitCost?.amount
                                )
                                    ? product?.productQuantity * product?.unitCost?.amount
                                    : (
                                          product?.productQuantity * product?.unitCost?.amount
                                      ).toFixed(2)
                            }`}
                        </span>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <i
                        onClick={() => {
                            const addSelectedProductCopy = [...addSelectedProduct];
                            addSelectedProductCopy.splice(idx, 1);
                            setAddSelectedProduct(addSelectedProductCopy);
                        }}
                        className="ri-delete-bin-line text-red-500 cursor-pointer"
                    ></i>
                </div>
            ),
        },
    ]);

    useEffect(() => {
        if (Boolean(addVendorSuccess)) {
            dispatch(vendorActions?.resetAddVendorSuccess());

            let body: { [key: string]: any } = { ...bodyData };

            function replaceVendorIdWithSelf(products, newId) {
                products.forEach((product) => {
                    if (product.vendorIds && product.vendorIds.includes("self")) {
                        product.vendorIds = product.vendorIds.map((vendorId) =>
                            vendorId === "self" ? newId : vendorId
                        );
                    }
                });

                return products;
            }
            // vendorIds
            // body.vendorIds = [...body.vendorIds, addVendorSuccess?.vendor?._id];
            body.products = replaceVendorIdWithSelf(body.products, addVendorSuccess?.vendor?._id);

            dispatch(inventoryActions.addMultipleRawMaterials(body));
        }
    }, [addVendorSuccess, bodyData]);

    useEffect(() => {
        if (Boolean(addNewRawMaterialSuccess)) {
            setNewRawMaterialModal(false);
            setBodyData({});
            setNewRawMaterialForm([
                {
                    productName: "",
                    price: "",
                    uom: "",
                    quantity: "",
                    supplier: [],
                    warehouse: "",
                },
            ]);
            setProductModal(true);
            dispatch(inventoryActions.resetAddMultipleRawMaterialsSuccess());
        }
    }, [addNewRawMaterialSuccess]);

    return (
        <div className="">
            <div className="w-full space-y-4">
                <div className="border border-[#EBF5FF] rounded-[10px] p-6">
                    <h6 className="text-base font-rocGroteskMedium mb-6">General Info</h6>
                    <div className="flex space-x-10 max-lg:space-x-0 max-lg:flex-col max-lg:space-y-8">
                        <div className="w-fit">
                            {productImages.length > 0 ? (
                                <div className="w-[252px] max-sm:w-full">
                                    <div className="relative h-[297px] w-full border rounded border-slate-200 ">
                                        <div
                                            className="left-[220px] absolute justify-end cursor-pointer  mt-2"
                                            onClick={() => {
                                                setDeleteImageModal(true);
                                            }}
                                        >
                                            <img
                                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Delete_icon_brttke.svg"
                                                className="w-8 mr-2 object-contain "
                                                alt="delete icon"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src={productImage}
                                                className="bg-no-repeat h-[297px] w-[252px] object-cover"
                                                alt="product Avatar"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3 w-full flex items-center space-x-2">
                                        {productImages.map((image, idx) => (
                                            <div
                                                className="cursor-pointer"
                                                key={image}
                                                onClick={() => {
                                                    setProductImage(image);
                                                }}
                                            >
                                                <img
                                                    src={image}
                                                    className={`bg-no-repeat h-[44px] w-[44px]  ${
                                                        productImage === image
                                                            ? "border-[1.5px] border-carribean-green"
                                                            : "border-slate-200 border"
                                                    } rounded  object-contain`}
                                                    alt={`product avatar ${idx + 1}`}
                                                />
                                            </div>
                                        ))}
                                        {productImages?.length < 5 && (
                                            <div className="relative">
                                                <div
                                                    onClick={() => {
                                                        const elem =
                                                            document.getElementById(
                                                                "uploadProductImages"
                                                            );
                                                        elem?.click();
                                                    }}
                                                    className="cursor-pointer h-[44px] w-[44px] rounded border border-slate-200 border-dashed flex items-center justify-center"
                                                >
                                                    <i className="ri-add-fill text-2xl"></i>
                                                </div>

                                                <input
                                                    type="file"
                                                    id="uploadProductImages"
                                                    className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                                    accept="image/png, image/jpeg"
                                                    multiple
                                                    onChange={(e) => {
                                                        handleInputUploadAction(e);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="w-[252px] h-[297px] max-sm:w-full relative px-6 py-12 flex items-center justify-center rounded border border-dashed bg-slate-50">
                                    {uploadingFiles ? (
                                        <Loader size={5} />
                                    ) : (
                                        <div className="w-full h-full">
                                            <div className="font-rocGroteskMedium flex flex-col justify-center items-center">
                                                <i className="ri-upload-cloud-2-line text-[40px] text-slate-500"></i>
                                                <p className="underline text-sm mb-2 text-center">
                                                    Add product images
                                                </p>
                                                <p className="text-tradeally-neutral-200 text-sm text-center">
                                                    You can add up to 5 images, each not exceeding 5
                                                    MB.
                                                </p>
                                                <p className="text-tradeally-neutral-200 text-xs mt-8 text-center">
                                                    file format: JPEG, PNG
                                                </p>
                                            </div>
                                            <input
                                                type="file"
                                                id="uploadProductImages"
                                                className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                                accept="image/png, image/jpeg"
                                                multiple
                                                onChange={(e) => {
                                                    handleInputUploadAction(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="w-fill-available">
                            <div className="mb-6">
                                <div className="flex items-center w-full justify-between mb-4">
                                    <p className="text-sm font-rocGroteskMedium">
                                        Product name <span className="text-r-50">*</span>
                                    </p>
                                </div>
                                <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                    <input
                                        type="text"
                                        placeholder="Enter a product name"
                                        className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                                        name="productName"
                                        value={productData.productName}
                                        onChange={(e) =>
                                            handleChange(e.target.name, e.target.value)
                                        }
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 items-center gap-4">
                                <TextInput
                                    value={productData.sku}
                                    name={"sku"}
                                    type={"text"}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    placeholder={"SKU"}
                                    required={false}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={productData.inventoryCategory}
                                    name="inventoryCategory"
                                    placeholder={"Inventory categories"}
                                    handleChange={(name, value) => {
                                        handleChange(name, value);
                                    }}
                                    isRequired={true}
                                    dropdownOptions={productCategories?.map((category) => ({
                                        label: category.label,
                                        value: category.value,
                                    }))}
                                />
                                <MultiselectInput
                                    value={productData.vendors}
                                    name="vendors"
                                    placeholder={"Supplier"}
                                    handleChange={(name, value, selectedItems) => {
                                        handleChange(name, selectedItems);
                                    }}
                                    searchLoading={fetchingLdbVendors}
                                    handleInputChange={(e) => handleDebouncedChange(e)}
                                    isRequired={true}
                                    dropdownOptions={[
                                        // profile?.accountType !== "brand"
                                        //     ? {
                                        //           label: "Self",
                                        //           value: "self",
                                        //           subText: "My profile",
                                        //           icon: (
                                        //               <VendorAvatar
                                        //                   size={30}
                                        //                   imageSrc={profile?.profile?.avatar}
                                        //                   name={profile?.profile?.businessName}
                                        //               />
                                        //           ),
                                        //       }
                                        //     : { label: "", value: "" },

                                        {
                                            label: "Self",
                                            value: onboardedVendors?.find(
                                                (item) =>
                                                    item?.vendorData?.profile?.businessName ===
                                                    "Self"
                                            )
                                                ? onboardedVendors?.find(
                                                      (item) =>
                                                          item?.vendorData?.profile
                                                              ?.businessName === "Self"
                                                  )?.vendorId
                                                : "self",
                                            subText: "My profile",
                                            icon: (
                                                <VendorAvatar
                                                    size={30}
                                                    imageSrc={profile?.profile?.avatar}
                                                    name={profile?.profile?.businessName}
                                                />
                                            ),
                                        },
                                        ...onboardedVendors
                                            ?.filter(
                                                (item) =>
                                                    item?.vendorData?.profile?.businessName !==
                                                    "Self"
                                            )
                                            ?.map((vendor) => ({
                                                label: vendor?.vendorData?.profile?.businessName,
                                                value: vendor.vendorId,
                                                subText: vendor?.category,
                                                icon: (
                                                    <VendorAvatar
                                                        size={30}
                                                        imageSrc={
                                                            vendor?.vendorData?.profile?.avatar
                                                        }
                                                        name={
                                                            vendor?.vendorData?.profile
                                                                ?.businessName
                                                        }
                                                    />
                                                ),
                                            })),
                                    ]?.filter((item) => Boolean(item?.label))}
                                    customDropdownContainer={
                                        <div
                                            onClick={() => {
                                                sessionStorage.setItem(
                                                    "scInventory",
                                                    JSON.stringify(productData)
                                                );
                                                navigate(`/dashboard/suppliers/create-supplier`, {
                                                    state: { internalNavigation: true },
                                                });
                                            }}
                                            className="pt-3 pb-1 px-4 border-t flex items-center space-x-1.5 cursor-pointer"
                                        >
                                            <i className="ri-add-fill text-carribean-green"></i>
                                            <span className="text-sm font-rocGroteskMedium text-carribean-green">
                                                Add new supplier
                                            </span>
                                        </div>
                                    }
                                />
                                {/* <div className="relative"> */}
                                <div className="" ref={containerRef}>
                                    <SelectInput
                                        value={productData.unitOfMeasurement}
                                        name="unitOfMeasurement"
                                        placeholder={"Unit of measure"}
                                        handleChange={(name, value) => {
                                            handleChange(name, value);
                                        }}
                                        isRequired={true}
                                        dropdownOptions={unitMeasure}
                                        dropdownClassName="!pb-0"
                                        rightDropdownContainerClassName="!sticky bottom-0"
                                        customRightDropdownContainer={
                                            <div className="py-2 px-3  flex items-center bg-white border-t-slate-200 border-t !h-[48px] sticky w-full bottom-0">
                                                {!addNew ? (
                                                    <div
                                                        className="flex items-center gap-3 w-fit cursor-pointer"
                                                        onClick={() => setAddNew(true)}
                                                    >
                                                        <div className="w-8 h-8 bg-[#FFF5DA] rounded-full flex items-center justify-center">
                                                            <i className="ri-add-fill text-[#FFB902]"></i>
                                                        </div>

                                                        <span className="text-sm font-rocGroteskMedium text-[#D39901] underline">
                                                            Add new
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="w-full overflow-x-none flex items-center">
                                                        <input
                                                            type="text"
                                                            placeholder=""
                                                            ref={inputRef}
                                                            className="caret-gm-50 !w-[80%] !outline-none !border-none text-sm !pr-[40px]  text-gm-50"
                                                            value={newUnit}
                                                            onChange={(e) =>
                                                                setNewUnit(e.target.value)
                                                            }
                                                        />

                                                        <div className="flex justify-end gap-4 items-center">
                                                            <div
                                                                className={`w-6 h-6 bg-[#fff] rounded-full flex items-center justify-center  shadow-buttonShadow2  ${
                                                                    newUnit
                                                                        ? "cursor-pointer"
                                                                        : "cursor-default"
                                                                }`}
                                                                onClick={() => {
                                                                    if (newUnit) {
                                                                        unitMeasure.push({
                                                                            label: newUnit,
                                                                            value: newUnit,
                                                                        });
                                                                        handleChange(
                                                                            "unitOfMeasurement",
                                                                            newUnit
                                                                        );
                                                                        setNewUnit("");
                                                                        setAddNew(false);
                                                                    }
                                                                }}
                                                            >
                                                                <i className="ri-check-fill text-[#097F54]"></i>
                                                            </div>
                                                            <div
                                                                className="w-6 h-6 bg-[#fff] rounded-full flex items-center justify-center
                                                        shadow-buttonShadow2 cursor-pointer"
                                                                onClick={() => {
                                                                    setAddNew(false);
                                                                    setNewUnit("");
                                                                }}
                                                            >
                                                                <i className="ri-close-line text-[#E4281D]"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    />
                                </div>

                                <SelectInput
                                    value={productData.selectAttribute}
                                    name="selectAttribute"
                                    placeholder={"Select attribute"}
                                    handleChange={(name, value) => {
                                        handleChange(name, value);
                                    }}
                                    isRequired={false}
                                    dropdownOptions={[
                                        {
                                            label: "Color",
                                            value: "color",
                                        },
                                        {
                                            label: "Size",
                                            value: "size",
                                        },
                                    ]}
                                />
                                <TextInput
                                    value={productData.attributeValue}
                                    name={"attributeValue"}
                                    type={"text"}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    placeholder={"Attribute"}
                                    required={false}
                                    containerClassname=""
                                />
                                <TextInput
                                    value={productData.upc}
                                    name={"upc"}
                                    type={"text"}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    placeholder={"Universal Product Code (UPC)"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <details className={`border border-[#EBF5FF] rounded-[10px] `} open={true}>
                    <summary
                        className={`flex items-center justify-between w-full cursor-pointer bg-[#F9FCFF] p-6 rounded-[10px] ${
                            !productShow && "rounded-b-[10px]"
                        }`}
                        // onClick={() => setProductShow(!productShow)}
                    >
                        <h6 className={`text-base font-rocGroteskMedium `}>Details</h6>
                        <div>
                            <i
                                className={`cursor-pointer text-slate-500  ${
                                    productShow ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                                }`}
                            ></i>
                        </div>
                    </summary>

                    {/* {productShow && ( */}
                    <div className="p-6">
                        <h6 className={`text-sm font-rocGroteskMedium mb-4`}>
                            Product source <span className="text-r-50">*</span>
                        </h6>
                        <div className="flex items-center space-x-8 relative">
                            <div className=" cursor-pointer flex items-center">
                                {productData?.productSource === "buy_product" ? (
                                    <i className="ri-checkbox-fill text-xl text-carribean-green"></i>
                                ) : (
                                    <i
                                        onClick={() => handleChange("productSource", "buy_product")}
                                        className="ri-checkbox-blank-line text-xl text-slate-400"
                                    ></i>
                                )}

                                <span className="text-sm font-rocGroteskMedium ml-[5px]">
                                    I buy this product
                                </span>
                            </div>

                            <div className="cursor-pointer flex items-center">
                                {productData?.productSource === "make_product" ? (
                                    <i className="ri-checkbox-fill text-xl text-carribean-green"></i>
                                ) : (
                                    <i
                                        onClick={() =>
                                            handleChange("productSource", "make_product")
                                        }
                                        className="ri-checkbox-blank-line text-xl text-slate-400"
                                    ></i>
                                )}
                                <span className="text-sm font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                    I make this product
                                </span>
                            </div>

                            <input
                                type="text"
                                value={productData?.productSource}
                                required
                                className="absolute top-0 left-0 opacity-0 w-5"
                            />
                        </div>
                        <div className="mt-5">
                            <label className="text-sm font-rocGroteskMedium">
                                Is this a perishable goods?
                            </label>

                            <div className="flex items-center mt-4 space-x-[52px]">
                                <div
                                    className=" cursor-pointer flex items-center"
                                    onClick={() => handleChange("isProductPerishable", true)}
                                >
                                    {productData.isProductPerishable ? (
                                        <i
                                            className={`ri-radio-button-fill text-xl ${
                                                productData.isProductPerishable && "text-y-10"
                                            }`}
                                        ></i>
                                    ) : (
                                        <span className={`material-icons text-xl text-n-50`}>
                                            radio_button_unchecked
                                        </span>
                                    )}
                                    <span className="text-sm font-rocGroteskMedium ml-[5px]">
                                        Yes
                                    </span>
                                </div>

                                <div
                                    className="cursor-pointer flex items-center"
                                    onClick={() => handleChange("isProductPerishable", false)}
                                >
                                    {!productData.isProductPerishable ? (
                                        <i
                                            className={`ri-radio-button-fill text-xl ${
                                                !productData.isProductPerishable && "text-y-10"
                                            }`}
                                        ></i>
                                    ) : (
                                        <span className={`material-icons text-xl text-n-50`}>
                                            radio_button_unchecked
                                        </span>
                                    )}
                                    <span className="text-sm font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                        No
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5">
                            <label className="text-sm font-rocGroteskMedium">
                                Track this product for forecast simulation
                            </label>

                            <div className="flex items-center mt-4 ">
                                <ToggleInput
                                    checked={productData?.trackProduct}
                                    name={"trackProduct"}
                                    onChange={(e) => {
                                        handleChange("trackProduct", e.target.checked);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-5">
                            <TextareaInput
                                name={"description"}
                                value={productData.description}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                rows={5}
                                placeholder={"Description"}
                                containerClassname=""
                            />
                        </div>
                    </div>
                    {/* )} */}
                </details>

                <details className={`border border-[#EBF5FF] rounded-[10px]`}>
                    <summary
                        className={`flex items-center justify-between w-full cursor-pointer bg-[#F9FCFF] p-6 rounded-[10px] ${
                            !priceShow && "rounded-b-[10px]"
                        }`}
                        // onClick={() => setPriceShow(!priceShow)}
                    >
                        <h6 className={`text-base font-rocGroteskMedium  `}>Pricing</h6>
                        <div>
                            <i
                                className={`cursor-pointer text-slate-500  ${
                                    priceShow ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                                }`}
                            ></i>
                        </div>
                    </summary>

                    {/* {priceShow && ( */}
                    <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 items-center gap-4 p-6">
                        <div className="flex space-x-1">
                            <div className="w-[160px]">
                                <SelectInput
                                    value={productData?.salesPriceCurrency}
                                    name="salesPriceCurrency"
                                    placeholder="Currency"
                                    handleChange={(name, value) => {
                                        handleChange(name, value);
                                    }}
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "GBP (£)",
                                            value: "GBP",
                                        },
                                        {
                                            label: "USD ($)",
                                            value: "USD",
                                        },
                                        {
                                            label: "EUR (€)",
                                            value: "EUR",
                                        },
                                    ]}
                                />
                            </div>

                            <TextInput
                                value={productData?.salesPriceAmount}
                                name={"salesPriceAmount"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                placeholder={"Product price"}
                                containerClassname=""
                                required={true}
                            />
                        </div>
                        <div className="flex space-x-1">
                            <div className="w-[160px]">
                                <SelectInput
                                    value={productData?.costPriceCurrency}
                                    name="costPriceCurrency"
                                    placeholder="Currency"
                                    handleChange={(name, value) => {
                                        handleChange(name, value);
                                    }}
                                    isRequired={false}
                                    dropdownOptions={[
                                        {
                                            label: "GBP (£)",
                                            value: "GBP",
                                        },
                                        {
                                            label: "USD ($)",
                                            value: "USD",
                                        },
                                        {
                                            label: "EUR (€)",
                                            value: "EUR",
                                        },
                                    ]}
                                />
                            </div>

                            <TextInput
                                value={productData?.costPriceAmount}
                                name={"costPriceAmount"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                placeholder={"Cost"}
                                containerClassname=""
                                required={false}
                            />
                        </div>
                        {/* <div className="flex space-x-1">
                        <div className="w-[160px]">
                            <SelectInput
                                value={productData?.retailSalePriceCurrency}
                                name="retailSalePriceCurrency"
                                placeholder="Currency"
                                handleChange={(name, value) => {
                                    handleChange(name, value);
                                }}
                                isRequired={false}
                                dropdownOptions={[
                                    {
                                        label: "GBP (£)",
                                        value: "GBP",
                                    },
                                    {
                                        label: "USD ($)",
                                        value: "USD",
                                    },
                                    {
                                        label: "EUR (€)",
                                        value: "EUR",
                                    },
                                ]}
                            />
                        </div>

                        <TextInput
                            value={productData?.retailSalePriceAmount}
                            name={"retailSalePriceAmount"}
                            type={"number"}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            placeholder={"Retail price"}
                            containerClassname=""
                            required={false}
                        />
                    </div> */}
                    </div>
                    {/* )} */}
                </details>

                {productData?.inventoryCategory?.toLowerCase() === "finished goods" && (
                    <details className={`border border-[#EBF5FF] rounded-[10px]`}>
                        <summary
                            className={`flex items-center justify-between w-full cursor-pointer bg-[#F9FCFF] p-6 rounded-[10px] ${
                                !recipeShow && "rounded-b-[10px]"
                            }`}
                            // onClick={() => setRecipeShow(!recipeShow)}
                        >
                            <h6 className={`text-base font-rocGroteskMedium  `}>Recipes</h6>
                            <div>
                                <i
                                    className={`cursor-pointer  text-slate-500  ${
                                        recipeShow ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                                    }`}
                                ></i>
                            </div>
                        </summary>

                        {/* {recipeShow && ( */}
                        <div className="p-6">
                            {addSelectedProduct?.length < 1 ? (
                                <div className="py-8 rounded-md border border-slate-300 border-dashed flex items-center justify-center">
                                    <div className="flex flex-col items-center text-center w-[52%]">
                                        <i className="ri-file-list-2-line text-[40px] text-slate-500 mb-4"></i>
                                        <span className="block text-sm font-rocGroteskMedium mb-1">
                                            No Recipes found
                                        </span>
                                        <span className="block text-sm font-rocGroteskMedium mb-5 text-slate-500">
                                            No items have been added to the bill of materials yet.
                                            To get started, click the "Create a Recipes" button
                                            below and begin adding components, materials, or
                                            products to build your bill of materials.
                                        </span>
                                        <Button
                                            btnClassname="!px-4 !py-2 !border !border-slate-200 !w-fit"
                                            btnText="Create Recipes"
                                            onClick={() => setProductModal(true)}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="py-8">
                                    <CustomTable
                                        tableHeader={recipesTableHeader}
                                        tableBody={recipesTableBody}
                                        isScrollable={false}
                                        isCellBordered={true}
                                        isCellSelectable={false}
                                        isAllSelectable={false}
                                        headerContainerClass="!bg-slate-50"
                                        headerItemClass="font-rocGroteskMedium "
                                        tableClass="mb-6"
                                    />
                                    <Button
                                        btnText="Add More Recipe Components"
                                        btnClassname="!bg-white !py-3 !pl-4 !pl-5 border border-slate-200 !text-[#142837] 
                                !text-[13px] !w-auto !mt-6"
                                        icon={<i className="ri-add-fill text-base text-gm-50"></i>}
                                        onClick={() => {
                                            setProductModal(true);
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {/* )} */}
                    </details>
                )}

                <details className={`border border-[#EBF5FF] rounded-[10px] `}>
                    <summary
                        className={`flex items-center justify-between w-full cursor-pointer bg-[#F9FCFF] p-6 rounded-[10px] ${
                            !warehouseShow && "rounded-b-[10px]"
                        }`}
                        // onClick={() => setWarehouseShow(!warehouseShow)}
                    >
                        <h6 className={`text-base font-rocGroteskMedium  `}>Warehouse</h6>
                        <div>
                            <i
                                className={`cursor-pointer text-slate-500  ${
                                    warehouseShow ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                                }`}
                            ></i>
                        </div>
                    </summary>

                    {/* {warehouseShow && ( */}
                    <div className="p-6">
                        <div className="grid grid-cols-[33.8%_auto] max-lg:grid-cols-1 max-lg:space-x-0 max-lg:space-y-3 items-center space-x-4 mb-3 w-full">
                            <div className="w-full">
                                <SelectInput
                                    value={productData.warehouse}
                                    name="warehouse"
                                    placeholder={"Select location"}
                                    handleChange={(name, value) => {
                                        handleChange(name, value);
                                    }}
                                    searchLoading={fetchingWarehouseList}
                                    handleInputChange={(e) => handleWarehouseDebouncedChange(e)}
                                    isRequired={Boolean(
                                        productData.bufferStockLevel ||
                                            productData.maximumStockLevel ||
                                            productData.minimumStockLevel
                                    )}
                                    optionItemContainerClassName="border-b border-slate-200"
                                    dropdownOptions={warehouses?.map((warehouse) => ({
                                        label: warehouse?.warehouseName,
                                        value: warehouse?._id,
                                    }))}
                                />
                            </div>

                            <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-4">
                                <TextInput
                                    value={productData.minimumStockLevel}
                                    name={"minimumStockLevel"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    placeholder={"Minimum stock level"}
                                    required={false}
                                    containerClassname=""
                                />
                                <TextInput
                                    value={productData.maximumStockLevel}
                                    name={"maximumStockLevel"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    placeholder={"Maximum stock level"}
                                    required={false}
                                    disabled={productData.minimumStockLevel ? false : true}
                                    // min={productData.minimumStockLevel}
                                    containerClassname=""
                                    // onInvalid={(e) => {
                                    //     (e.target as any)?.setCustomValidity(
                                    //         "Maximum stock level should be greater than minimum stock level"
                                    //     );
                                    // }}
                                    // title="Maximum stock level should be greater than minimum stock level"
                                />
                                <TextInput
                                    value={productData.bufferStockLevel}
                                    name={"bufferStockLevel"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    placeholder={"Buffer stock level"}
                                    disabled={productData.minimumStockLevel ? false : true}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>

                        <div
                            onClick={() => setAddWarehouseModal(true)}
                            className="flex items-center gap-2 text-p-50 font-rocGroteskMedium w-fit cursor-pointer"
                        >
                            <i className="ri-add-fill text-xl"></i>
                            <span className="underline text-sm">Add new warehouse</span>
                        </div>
                    </div>
                    {/* )} */}
                </details>

                <details className={`border border-[#EBF5FF] rounded-[10px]`}>
                    <summary
                        className={`flex items-center justify-between w-full cursor-pointer bg-[#F9FCFF] p-6 rounded-[10px] ${
                            !documentShow && "rounded-b-[10px]"
                        }`}
                        // onClick={() => setDocumentShow(!documentShow)}
                    >
                        <h6 className={`text-base font-rocGroteskMedium `}>Documents</h6>
                        <div>
                            <i
                                className={`cursor-pointer text-slate-500  ${
                                    documentShow ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                                }`}
                            ></i>
                        </div>
                    </summary>

                    {/* {documentShow && ( */}
                    <div className="p-6">
                        {attachments?.length > 0 && (
                            <div className="mt-4 grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 w-full gap-4">
                                {attachments?.map((doc, idx) => (
                                    <div
                                        key={idx}
                                        className="flex items-center justify-between p-3 rounded-md border border-slate-200"
                                    >
                                        <div className="flex items-center gap-2">
                                            {displayFileIcon(getFileTypeFromUrl(doc?.path), false)}
                                            <div className="font-rocGroteskMedium">
                                                <p className="text-sm">
                                                    {truncate(doc?.filename, { length: 30 })}
                                                </p>
                                                {/* <p className="text-xs text-slate-500">4 MB</p> */}
                                            </div>
                                        </div>
                                        <i
                                            onClick={() => {
                                                setSelectedFilePath(doc?.path);
                                                setDeleteAttachmentModal(true);
                                            }}
                                            className="ri-delete-bin-line text-xl text-[#FF0000] cursor-pointer"
                                        ></i>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="mt-4 grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 w-full gap-4">
                            <div className="py-3 px-6 rounded border border-dashed border-slate-200">
                                <div
                                    className="flex items-center relative w-fit gap-4 cursor-pointer"
                                    onClick={() =>
                                        !uploadingAttachments &&
                                        document.getElementById("fileInput")?.click()
                                    }
                                >
                                    <i className="ri-file-add-line text-2xl text-g-75"></i>
                                    <p className="text-sm underline text-g-75 font-rocGroteskMedium">
                                        {uploadingAttachments
                                            ? "Uploading"
                                            : "Click to upload document"}
                                    </p>
                                    {uploadingAttachments && <Loader size={4} />}
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className={`absolute z-[-1]  opacity-0`}
                                        // accept="image/png, image/jpeg"
                                        multiple
                                        onChange={(evt) => {
                                            const files = Object.values(evt.target.files);
                                            handleUploadAttachments(files);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* )} */}
                </details>
            </div>

            <ModalContainer open={productModal} tailwindClassName="w-[90%] lg:w-[1000px]">
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className="px-6 pt-4 pb-[14px] border-b border-b-slate-200">
                        <div className="mb-6 flex gap-4 items-center justify-between">
                            <p className="text-lg text-gm-50 font-rocGroteskMedium">
                                Add Raw Material to Product
                            </p>
                            <i
                                onClick={() => setProductModal(false)}
                                className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                            ></i>
                        </div>

                        <div className="mb-4">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onInput={handleProductDebouncedChange}
                                inputPlaceholder="Search raw materials"
                                inputClassName={
                                    "!h-[50px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-md !h-[50px] !pl-[30px] !bg-white !border !border-slate-200 !border-b-[0.5px]"
                                }
                            />
                        </div>

                        <p className="text-sm text-slate-500 font-rocGroteskMedium">
                            Select raw materials
                        </p>
                    </div>
                    <div className="my-8 mx-8  ">
                        {product?.length > 0 ? (
                            <div className="">
                                <div className="max-h-[300px] overflow-y-auto" id="scrollableTable">
                                    <CustomTable
                                        tableBody={tableModalBody}
                                        tableHeader={tableModalHeader}
                                        isAllSelectable={true}
                                        headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                        headerContainerClass="!bg-slate-50"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />

                                    {loadMore && fetchingInventoryList && (
                                        <div className="flex my-4 justify-center">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                    {paginate?.current === paginate?.number_of_pages && (
                                        <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                            End of list
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`flex justify-center w-full my-12 pb-[50px] pt-[40px] border border-dashed rounded-md`}
                            >
                                <div
                                    className={`flex flex-col items-center w-[79%] max-sm:w-full max-sm:p-5`}
                                >
                                    <i className="ri-file-list-2-line text-[40px] text-[#64748B]"></i>
                                    <div className={``}>
                                        <h6 className="mb-2 text-center text-base font-rocGroteskMedium">
                                            No raw materials added
                                        </h6>
                                        <p className={`text-sm text-slate-500 mb-4 text-center`}>
                                            You have not added any raw materials to your inventory
                                            yet. This means that you can't add recipes for this
                                            product right now. Click on the button below to add your
                                            raw materials first (they will also be saved to your
                                            inventory).
                                        </p>
                                        <div className={`flex justify-center gap-4`}>
                                            <Button
                                                btnClassname="!py-2 !px-4 !w-fit !h-10"
                                                btnText={"Add raw materials"}
                                                onClick={() => {
                                                    setProductModal(false);
                                                    setNewRawMaterialModal(true);
                                                }}
                                                icon={<i className="ri-add-fill"></i>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {product?.length !== 0 && (
                        <div
                            className={`px-8 py-4 flex gap-4 items-center justify-between border-t border-t-slate-200`}
                        >
                            <Button
                                btnClassname="!py-2 !px-4 !w-fit !h-10"
                                btnText={"Add raw materials"}
                                onClick={() => {
                                    setProductModal(false);
                                    setNewRawMaterialModal(true);
                                }}
                            />
                            <div className="flex justify-end gap-3">
                                <Button
                                    btnText="Cancel"
                                    btnClassname="!px-6 !py-3 !bg-tradeally-neutral-20 !w-fit"
                                    onClick={() => setProductModal(false)}
                                    // disabled={addingNewStockAdjustment}
                                />
                                <Button
                                    btnText="Add recipes"
                                    btnClassname="!px-6 !py-3 !w-fit"
                                    onClick={() => {
                                        handleAddButton();
                                    }}
                                    // disabled={addingNewStockAdjustment}
                                    // isLoading={addingNewStockAdjustment}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ModalContainer>

            <ModalContainer open={newRawMaterialModal} tailwindClassName="w-[90%] lg:w-[1000px]">
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className="px-6 pt-4 pb-[14px] border-b border-b-slate-200">
                        <div className=" flex gap-4 items-center justify-between">
                            <p className="text-lg text-gm-50 font-rocGroteskMedium">
                                Add Raw Material to Product
                            </p>
                            <i
                                onClick={() => setNewRawMaterialModal(false)}
                                className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                            ></i>
                        </div>
                    </div>
                    <div
                    // onSubmit={(e) => {
                    //     e.preventDefault();
                    //     // handleSubmitNewMaterialForm();
                    // }}
                    >
                        <div className="my-[54px] mx-8  ">
                            <div className="">
                                <div>
                                    <CustomTable
                                        tableBody={newRawMaterialModalBody}
                                        tableHeader={newRawMaterialModalHeader}
                                        isAllSelectable={false}
                                        headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                        headerContainerClass="!bg-slate-50"
                                        isScrollable={false}
                                        isCellBordered={true}
                                        isCellSelectable={false}
                                        scrollWithin={false}
                                    />
                                </div>

                                <div className="my-4">
                                    <Button
                                        btnText="Add new item"
                                        btnClassname="!px-6 !bg-tradeally-neutral-20 !w-fit !h-[40px]"
                                        onClick={() => handleAddNewRawMaterialItem()}
                                        disabled={addingNewRawMaterial || addingVendor}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className=" px-8 py-4 flex gap-4 items-center justify-end border-t border-t-slate-200">
                            <div className="flex justify-end gap-3">
                                <Button
                                    btnText="Cancel"
                                    btnClassname="!px-6 !py-3 !bg-tradeally-neutral-20 !w-fit"
                                    onClick={() => setNewRawMaterialModal(false)}
                                    disabled={addingNewRawMaterial || addingVendor}
                                />
                                <Button
                                    btnText="Save raw materials"
                                    btnClassname="!px-6 !py-3 !w-fit"
                                    isLoading={addingNewRawMaterial || addingVendor}
                                    onClick={() => {
                                        handleSubmitNewMaterialForm();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default SingleProduct;
