import React, { useState } from "react";
import Checkbox from "./InputField/Checkbox";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "react-tooltip";

type CustomTableProps = {
    tableBody?: {
        content: React.ReactNode;
        shouldRowSpan?: boolean;
        widthClass?: string;
        tableDataDivClass?: string;
        cellClickAction?: (e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
        isItemChecked?:
            | boolean
            | {
                  [key: string]: any;
              };
        checkItemIsDisabled?: boolean;
        onCheckBoxClick?: () => void;
        expandedRowAction?: () => void;
    }[][];
    tableHeader: {
        title: React.ReactNode | string;
        tooltipIcon?: string;
        tooltipPlace?: "top" | "bottom" | "left" | "right";
        tooltipContent?: string;
        tooltipStyle?: React.CSSProperties;
        titleClass?: string;
        widthClass?: string;
        itemClass?: string;
        isAllChecked?: boolean;
        checkAllIsDisabled?: boolean;
        onSelectAll?: () => void;
        sortIcon?: React.ReactNode;
    }[];
    tbodyClass?: string;
    isAllSelectable?: boolean;
    isCellSelectable?: boolean;
    isScrollable?: boolean;
    headerContainerClass?: string;
    headerItemClass?: string;
    bodyItemClass?: string;
    tableClass?: string;
    isCellBordered?: boolean;
    isScrolled?: boolean;
    inventoryHistoryTable?: boolean;
    leftFixedColumnCount?: number;
    rightFixedColumnCount?: number;
    leftFixedColumnClass?: string[];
    rightFixedColumnClass?: string[];
    noTableBody?: React.ReactElement | null;
    stickyHeader?: boolean;
    sort?: (idx: number, value: number) => void;
    scrollWithin?: boolean;
    isInifiniteScroll?: boolean;
    tableContainerClass?: string;
    isGrouped?: boolean;
    groupedData?: {
        groupContent: JSX.Element;
        cellClickAction?: () => void;
        groupContentClass?: string;
        associatedSKUs: any[];
    }[];
    isCollapseable?: boolean;
    rowExpanded?: React.ReactNode;
    expandedRowTClass?: string;
    expandedRowIdx?: number;
    allCellClick?: boolean;
    scrollContentId?: string;
};

const CustomTable = ({
    tableBody,
    tableHeader,
    tbodyClass,
    isAllSelectable,
    isCellSelectable,
    isScrollable,
    headerContainerClass,
    headerItemClass,
    bodyItemClass,
    tableClass,
    isCellBordered,
    isScrolled,
    inventoryHistoryTable,
    leftFixedColumnCount,
    rightFixedColumnCount,
    leftFixedColumnClass,
    rightFixedColumnClass,
    noTableBody,
    stickyHeader,
    sort,
    scrollWithin = true,
    isInifiniteScroll = true,
    tableContainerClass,
    isGrouped,
    groupedData,
    isCollapseable,
    allCellClick,
    rowExpanded,
    expandedRowTClass,
    expandedRowIdx,
    scrollContentId,
}: CustomTableProps) => {
    return (
        <div
            id={
                isInifiniteScroll && !scrollContentId
                    ? "tableContent"
                    : isInifiniteScroll && scrollContentId
                    ? scrollContentId
                    : ""
            }
            className={`${
                isScrollable ? "overflow-x-scroll custom-x-scrollbar" : "overflow-x-hidden"
            } w-full h-full relative  ${
                scrollWithin
                    ? `max-h-[600px] overflow-y-scroll custom-y-scrollbar  ${tableContainerClass}`
                    : "contents"
            }`}
        >
            <table
                className={`table-fixed  relative ${
                    isScrollable && "border-separate border-spacing-0"
                } bg-white w-full ${tableClass} rounded-[6px]  ${
                    inventoryHistoryTable ? "border-none" : "border border-slate-100"
                }`}
            >
                <thead className={`sticky top-[-3px] left-0 right-0 z-[1100] ${expandedRowTClass}`}>
                    <tr className={`text-left bg-white ${headerContainerClass} `}>
                        {tableHeader?.map((header, idx) => (
                            <th
                                key={(header?.title as string) + idx}
                                className={`${header?.widthClass} ${
                                    isCellBordered && "border-r border-b"
                                } border-slate-100 ${
                                    (leftFixedColumnCount
                                        ? idx <= leftFixedColumnCount - 1
                                        : idx === 0) &&
                                    isScrollable &&
                                    "sticky left-0 top-0 bg-inherit z-[1000]" +
                                        " " +
                                        (idx <= (leftFixedColumnCount as number) - 1 &&
                                            (leftFixedColumnClass as string[])?.length > 0 &&
                                            (leftFixedColumnClass as string[])[idx])
                                } ${
                                    (rightFixedColumnCount
                                        ? idx >= tableHeader?.length - rightFixedColumnCount
                                        : idx === 0) &&
                                    isScrollable &&
                                    "sticky right-0 top-0 bg-inherit z-[1000]" +
                                        " " +
                                        (idx >=
                                            tableHeader?.length -
                                                (rightFixedColumnCount as number) &&
                                            (rightFixedColumnClass as string[])?.length > 0 &&
                                            (rightFixedColumnClass as string[])[idx])
                                } ${
                                    (leftFixedColumnCount
                                        ? idx <= leftFixedColumnCount - 1
                                        : idx === 0) &&
                                    isScrollable &&
                                    isScrolled &&
                                    "shadow-[3px_0px_3px_0px_rgba(122,122,122,0.1)]"
                                }  `}
                            >
                                <div
                                    className={`p-3 text-xs text-slate-800 ${
                                        header?.title === "price" &&
                                        inventoryHistoryTable &&
                                        idx === 2 &&
                                        "justify-end"
                                    }  flex items-center gap-3 ${headerItemClass} ${
                                        header?.itemClass
                                    } `}
                                >
                                    {isAllSelectable && idx === 0 && (
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <Checkbox
                                                name="selectAll"
                                                isChecked={tableHeader?.[0]?.isAllChecked}
                                                onChange={() => {
                                                    tableHeader?.[0]?.onSelectAll?.();
                                                }}
                                                disabled={tableHeader?.[0]?.checkAllIsDisabled}
                                                className={"!h-4 !w-4 !rounded-[4px] "}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={`flex justify-between w-full items-center ${header?.titleClass}`}
                                    >
                                        <div className="flex gap-2 items-center">
                                            {header?.title}
                                            {header?.tooltipIcon && (
                                                <div className="font-rocGroteskMedium">
                                                    <a id={header?.tooltipIcon}>
                                                        <i className="ri-information-fill text-slate-300 text-base"></i>
                                                    </a>

                                                    <Tooltip
                                                        anchorSelect={`#${header?.tooltipIcon}`}
                                                        place={header?.tooltipPlace || "top"}
                                                        content={header?.tooltipContent}
                                                        style={{
                                                            width: "300px",
                                                            zIndex: 9999,
                                                            backgroundColor: "#142837",
                                                            color: "#fff",
                                                            fontSize: "13px",
                                                            fontWeight: "400 !important",
                                                            ...header?.tooltipStyle,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {header?.sortIcon && (
                                            <div className="flex flex-col">
                                                <i
                                                    className="ri-arrow-up-s-fill text-slate-700 cursor-pointer -mt-1"
                                                    onClick={() => sort(idx, 1)}
                                                ></i>
                                                <i
                                                    className="ri-arrow-down-s-fill text-slate-700 cursor-pointer -mt-1.5"
                                                    onClick={() => sort(idx, -1)}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                {!noTableBody ? (
                    <tbody className={`${tbodyClass} `}>
                        {isGrouped
                            ? groupedData.map((group, index) =>
                                  group.associatedSKUs.map((sku, skuIndex) => (
                                      <tr
                                          key={skuIndex}
                                          className={`text-left bg-white border-t border-slate-100 cursor-pointer ellipsis ${bodyItemClass}`}
                                          onClick={(e) => group?.cellClickAction?.()}
                                      >
                                          {skuIndex === 0 && (
                                              <td
                                                  rowSpan={group.associatedSKUs.length}
                                                  className={`${sku?.widthClass} ${
                                                      isCellBordered &&
                                                      skuIndex !==
                                                          group.associatedSKUs?.length - 1 &&
                                                      "border-r border-b"
                                                  } border-slate-100 ${
                                                      isScrollable &&
                                                      "sticky left-0 bg-inherit z-[1000]"
                                                  }`}
                                              >
                                                  <div
                                                      className={`flex pl-3 text-slate-800 items-center gap-3 ${group?.groupContentClass} `}
                                                  >
                                                      {group.groupContent}
                                                  </div>
                                              </td>
                                          )}
                                          {sku?.map((col, colIdx) => {
                                              if (col?.shouldRowSpan && skuIndex === 0) {
                                                  return (
                                                      <td
                                                          key={colIdx}
                                                          rowSpan={group.associatedSKUs.length}
                                                          className={`${col?.widthClass} ${
                                                              isCellBordered &&
                                                              colIdx !== sku?.length - 1 &&
                                                              "border-r border-b"
                                                          } border-slate-100 `}
                                                      >
                                                          <div
                                                              className={`flex pl-3 text-slate-800  items-center gap-3 ${col?.tableDataDivClass} `}
                                                          >
                                                              {col?.content}
                                                          </div>
                                                      </td>
                                                  );
                                              }

                                              if (!col?.shouldRowSpan) {
                                                  return (
                                                      <td
                                                          key={colIdx}
                                                          className={`${col?.widthClass} ${
                                                              isCellBordered &&
                                                              colIdx !== sku?.length - 1 &&
                                                              "border-r border-b"
                                                          } border-slate-100 `}
                                                      >
                                                          <div
                                                              className={`flex pl-3 text-slate-800  items-center gap-3 ${col?.tableDataDivClass} `}
                                                          >
                                                              {col?.content}
                                                          </div>
                                                      </td>
                                                  );
                                              }
                                          })}
                                      </tr>
                                  ))
                              )
                            : tableBody?.map((item, index) => {
                                  return (
                                      <>
                                          <tr
                                              key={index}
                                              className={`text-left bg-white border-t  border-slate-100 cursor-pointer ellipsis hover:bg-slate-50 ${bodyItemClass}`}
                                              onClick={(e) => {
                                                  if (isCollapseable && allCellClick) {
                                                      item?.[0]?.expandedRowAction?.();
                                                  } else {
                                                      item?.[0]?.cellClickAction?.(e);
                                                  }
                                              }}
                                          >
                                              {item?.map((col, idx) => (
                                                  <td
                                                      key={index + "-" + idx}
                                                      className={`${col?.widthClass} ${
                                                          isCellBordered &&
                                                          idx !== item?.length - 1 &&
                                                          "border-r border-b"
                                                      } border-slate-100 ${
                                                          (leftFixedColumnCount
                                                              ? idx <= leftFixedColumnCount - 1
                                                              : idx === 0) &&
                                                          isScrollable &&
                                                          "sticky left-0 bg-inherit z-[1000]" +
                                                              " " +
                                                              (idx <=
                                                                  (leftFixedColumnCount as number) -
                                                                      1 &&
                                                                  (leftFixedColumnClass as string[])
                                                                      ?.length > 0 &&
                                                                  (
                                                                      leftFixedColumnClass as string[]
                                                                  )[idx])
                                                      } ${
                                                          (rightFixedColumnCount
                                                              ? idx >=
                                                                item?.length - rightFixedColumnCount
                                                              : idx === 0) &&
                                                          isScrollable &&
                                                          "sticky right-0 bg-inherit z-[1000]" +
                                                              " " +
                                                              (idx >=
                                                                  tableHeader?.length -
                                                                      (rightFixedColumnCount as number) &&
                                                                  (
                                                                      rightFixedColumnClass as string[]
                                                                  )?.length > 0 &&
                                                                  (
                                                                      rightFixedColumnClass as string[]
                                                                  )[idx])
                                                      } ${
                                                          (leftFixedColumnCount
                                                              ? idx <= leftFixedColumnCount - 1
                                                              : idx === 0) &&
                                                          isScrollable &&
                                                          isScrolled &&
                                                          "shadow-[3px_0px_3px_0px_rgba(122,122,122,0.1)]"
                                                      } ${inventoryHistoryTable && "border-b"}`}
                                                  >
                                                      <div
                                                          className={`flex ${
                                                              item?.[2]?.content &&
                                                              idx === 2 &&
                                                              inventoryHistoryTable &&
                                                              "justify-end"
                                                          } pl-3 text-slate-800  items-center gap-3 ${
                                                              col?.tableDataDivClass
                                                          } `}
                                                      >
                                                          {idx === 0 &&
                                                              (isAllSelectable ||
                                                                  isCellSelectable) && (
                                                                  <div
                                                                      onClick={(e) =>
                                                                          e.stopPropagation()
                                                                      }
                                                                      className={`${
                                                                          isCollapseable &&
                                                                          "mb-[-6px]"
                                                                      } `}
                                                                  >
                                                                      <Checkbox
                                                                          name="select"
                                                                          isChecked={
                                                                              item?.[0]
                                                                                  ?.isItemChecked as boolean
                                                                          }
                                                                          disabled={
                                                                              item?.[0]
                                                                                  ?.checkItemIsDisabled
                                                                          }
                                                                          onChange={() => {
                                                                              item?.[0]?.onCheckBoxClick?.();
                                                                          }}
                                                                          className={
                                                                              "!h-4 !ml-3 !w-4 !rounded-[4px] "
                                                                          }
                                                                      />
                                                                  </div>
                                                              )}

                                                          {isCollapseable && idx === 0 && (
                                                              <i
                                                                  className={`${
                                                                      expandedRowIdx === index
                                                                          ? "ri-arrow-drop-down-line"
                                                                          : "ri-arrow-drop-right-line"
                                                                  }  text-gm-50 text-2xl cursor-pointer`}
                                                                  onClick={() => {
                                                                      !allCellClick &&
                                                                          item?.[0]?.expandedRowAction?.();
                                                                  }}
                                                              ></i>
                                                          )}
                                                          {col?.content}
                                                      </div>
                                                  </td>
                                              ))}
                                          </tr>
                                          {isCollapseable && expandedRowIdx === index && (
                                              <tr className="w-full">
                                                  <td
                                                      className="w-full"
                                                      colSpan={tableHeader?.length}
                                                  >
                                                      <div className="">{rowExpanded}</div>
                                                  </td>
                                              </tr>
                                          )}
                                      </>
                                  );
                              })}
                    </tbody>
                ) : (
                    <tbody className="w-[100%]">{noTableBody}</tbody>
                )}
            </table>
        </div>
    );
};

export default CustomTable;
