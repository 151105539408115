import React, { useEffect, useMemo, useRef, useState } from "react";
import Dropdown from "../Dropdown";
import {
    authActions,
    inventoryActions,
    multibrandActions,
    settingsActions,
} from "redux/Ldb/actions";
import _, { cloneDeep, set, truncate } from "lodash";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import ProgressBar from "../ProgressBar";
import ModalContainer from "../ModalContainer";
import TextInput from "../InputField/TextInput";
import Button from "../Button";
import Loader from "../Loader";
import { transformString } from "helpers/transformStrings";
import { io } from "socket.io-client";
import config from "config/config";

import SpinnerLoader from "../SpinnerLoader";
import { useNav } from "./NavContext";
import useGetOngoingSyncConnections from "hooks/inventoryHooks/useGetOngoingSyncConnections";
import { useChannel } from "ably/react";
import useGetUserProfile from "hooks/useGetUserProfile";
import TabsContainer from "../TabsContainer";
import { MultipleDropdowns } from "../MultipleDropdown";

import { getFromStorage, saveToStorage } from "helpers";
import useGetBrandList from "hooks/multibrandHooks/useGetBrandList";
import useGetNavBrandList from "hooks/multibrandHooks/useGetNavBrandList";
import { isUserPermitted } from "helpers/userPermissions";
import useGetNavLinks from "hooks/navLinks";
import { AccountPermissions } from "types/permissions";
import { trackstarList } from "variables/trackstarList";
import PreviewSelectedProducts from "pages/Synth2.0/Inventory/ImportProducts/Steps/PreviewSelectedProducts";

type DashboardNavProps = {
    setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardNav = ({ setSidebarCollapsed }: DashboardNavProps) => {
    const { navName, showNavIcon, onClick } = useNav();
    const { allowedLinks } = useGetNavLinks();
    const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const [selectedBrandId, setSelectedBrandId] = useState<string>("");
    const [switchBrand, setSwitchBrand] = useState<boolean>(false);
    const [switchingBrand, setSwitchingBrand] = useState<boolean>(false);
    const [showCreditPurchase, setShowCreditPurchase] = useState(false);
    const [purchaseCreditModal, setPurchaseCreditModal] = useState(false);
    const [creditUsageHistory, setCreditUsageHistory] = useState([]);
    const [credit, setCredit] = useState<number | string>(0);
    const [purchaseCreditFailure, setPurchaseCreditFailure] = useState(false);
    const [purchaseCreditLimit, setPurchaseCreditLimit] = useState(false);
    const [showProductSync, setShowProductSync] = useState<boolean>(false);
    const [showCampaignSync, setShowCampaignSync] = useState<boolean>(false);
    const [showProductAndSalesSyncInProgress, setShowProductAndSalesSyncInProgress] =
        useState<boolean>(false);
    const [shopifySyncProgress, setShopifySyncProgress] = useState<number | undefined>(undefined);
    const [quickbookSyncProgress, setQuickbookSyncProgress] = useState<number | undefined>(
        undefined
    );
    const [qbDesktopSyncProgress, setQbDesktopSyncProgress] = useState<number | undefined>(
        undefined
    );
    const [amazonSyncProgress, setAmazonSyncProgress] = useState<number | undefined>(undefined);
    const [klaviyoSyncProgress, setKlaviyoSyncProgress] = useState<number | undefined>(undefined);
    const [googleAdsSyncProgress, setGoogleAdsSyncProgress] = useState<number | undefined>(
        undefined
    );
    const [tiktokSyncProgress, setTiktokSyncProgress] = useState<number | undefined>(undefined);
    const [trackstarSyncProgress, setTrackstarSyncProgress] = useState<
        { percentage: number | undefined; integrationName: string }[]
    >([]);

    const [trackstarSalesSyncProgress, setTrackstarSalesSyncProgress] = useState<
        { percentage: number | undefined; integrationName: string }[]
    >([]);

    const [shopifySalesSyncProgress, setShopifySalesSyncProgress] = useState<number | undefined>(
        undefined
    );
    const [amazonSalesSyncProgress, setAmazonSalesSyncProgress] = useState<number | undefined>(
        undefined
    );
    const [qbDesktopSalesSyncProgress, setQbDesktopSalesSyncProgress] = useState<
        number | undefined
    >(undefined);
    const [productSyncCompletedModal, setProductSyncCompletedModal] = useState<boolean>(false);
    const [trackstarProductSyncCompletedModal, setTrackstarProductSyncCompletedModal] = useState<
        { integrationName?: string; modal: boolean }[]
    >([]);

    const [salesSyncCompletedModal, setSalesSyncCompletedModal] = useState<boolean>(false);
    const [shopifyProductSyncCompletedModal, setShopifyProductSyncCompletedModal] =
        useState<boolean>(false);
    const [amazonProductSyncCompletedModal, setAmazonProductSyncCompletedModal] =
        useState<boolean>(false);
    const [quickbooksProductSyncCompletedModal, setQuickbooksProductSyncCompletedModal] =
        useState<boolean>(false);
    const [qbDesktopProductSyncCompletedModal, setQbDesktopProductSyncCompletedModal] =
        useState<boolean>(false);
    const [klaviyoProductSyncCompletedModal, setKlaviyoProductSyncCompletedModal] =
        useState<boolean>(false);
    const [googleAdsProductSyncCompletedModal, setGoogleAdsProductSyncCompletedModal] =
        useState<boolean>(false);
    const [tiktokProductSyncCompletedModal, setTiktokProductSyncCompletedModal] =
        useState<boolean>(false);

    const [trackstarSalesSyncCompletedModal, setTrackstarSalesSyncCompletedModal] = useState<
        { integrationName?: string; modal: boolean }[]
    >([]);
    const [shopifySalesSyncCompletedModal, setShopifySalesSyncCompletedModal] =
        useState<boolean>(false);
    const [amazonSalesSyncCompletedModal, setAmazonSalesSyncCompletedModal] =
        useState<boolean>(false);
    const [quickbooksSalesSyncCompletedModal, setQuickbooksSalesSyncCompletedModal] =
        useState<boolean>(false);
    const [qbDesktopSalesSyncCompletedModal, setQbDesktopSalesSyncCompletedModal] =
        useState<boolean>(false);
    const [activeInProgressTab, setActiveInProgressTab] = useState<number>(0);
    const [activeCompletedSyncTab, setActiveCompletedSyncTab] = useState<number>(0);
    const [klaviyoCampaignNo, setKlaviyoCampaignNo] = useState<number>(0);
    const [googleAdsCampaignNo, setGoogleAdsCampaignNo] = useState<number>(0);
    const [tiktokCampaignNo, setTiktokCampaignNo] = useState<number>(0);
    const syncTabs = ["Product Sync", "Sales Sync"];
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const switchBrandRef = useRef<HTMLDivElement>(null);
    const storedProfile = getFromStorage("ally-user");
    const storedData = { ...storedProfile };

    const {
        data: brandListSearch,
        setUpdatedPage,
        search: searchTwo,
        handleSearch: handleSearchTwo,
        handleDebouncedChange: handleDebouncedChangeTwo,
        // brandListSearch,
    } = useGetNavBrandList(profile?.accountType === "brand" ? storedData.mainToken : "");

    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);
    const {
        fetchCreditHistorySuccess,
        fetchingCreditHistory,
        fetchStripeSession,
        fetchStripeSessionSuccess,
    } = useAppSelector((state) => state.settings);
    const { fetchingBrandToken, fetchBrandTokenSuccess } = useAppSelector(
        (state) => state.multibrand
    );
    const permissions = fetchUserProfileSuccess?.permission;
    // const productPermissions = ["all_access","create_inventory", "update_inventory"];
    const productPermissions = [
        AccountPermissions.all_access,
        AccountPermissions.create_inventory,
        AccountPermissions.update_inventory,
    ];
    const [sync, setSync] = useState<boolean>(false);
    const { importedSyncedProductsSuccess } = useAppSelector((state) => state.inventory);
    const { manageConnectionSuccess } = useAppSelector((state) => state.vendor);
    const { data: syncingData } = useGetOngoingSyncConnections(
        shopifySalesSyncCompletedModal,
        amazonSalesSyncCompletedModal,
        trackstarSalesSyncCompletedModal,
        sync
    );

    const shopifySyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "shopify" &&
            syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
    );
    const quickbooksSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "quickbooks" &&
            syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
    );
    const qbDesktopSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "quickbooks-desktop" &&
            syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
    );
    const amazonSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "amazon" &&
            syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
    );
    const klaviyoSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "klaviyo" &&
            syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
    );
    const googleAdsSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "google_ads" &&
            syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
    );
    const tiktokSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "tiktok_ads" &&
            syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
    );

    const shopifySalesSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "shopify" &&
            syncData?.currentSyncStep?.name?.toLowerCase() !== "inventory_sync"
    );
    const amazonSalesSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "amazon" &&
            syncData?.currentSyncStep?.name?.toLowerCase() !== "inventory_sync"
    );

    const qbDesktopSalesSyncData = syncingData?.find(
        (syncData) =>
            syncData?.channel?.toLowerCase() === "quickbooks-desktop" &&
            syncData?.currentSyncStep?.name?.toLowerCase() !== "inventory_sync"
    );

    const shopifySalesPercentage =
        shopifySalesSyncProgress?.toFixed(0) ||
        shopifySalesSyncData?.syncProgress?.toFixed(0) ||
        undefined;

    const amazonSalesPercentage =
        amazonSalesSyncProgress?.toFixed(0) ||
        amazonSalesSyncData?.syncProgress?.toFixed(0) ||
        undefined;

    const qbDesktopSalesPercentage =
        qbDesktopSalesSyncProgress?.toFixed(0) ||
        qbDesktopSalesSyncData?.syncProgress?.toFixed(0) ||
        undefined;

    const shopifyPercentage =
        shopifySyncProgress?.toFixed(0) || shopifySyncData?.syncProgress?.toFixed(0);
    const quickbooksPercentage =
        quickbookSyncProgress?.toFixed(0) || quickbooksSyncData?.syncProgress?.toFixed(0);
    const qbDesktopPercentage =
        qbDesktopSyncProgress?.toFixed(0) || qbDesktopSyncData?.syncProgress?.toFixed(0);
    const amazonPercentage =
        amazonSyncProgress?.toFixed(0) || amazonSyncData?.syncProgress?.toFixed(0);
    const klaviyoPercentage =
        klaviyoSyncProgress?.toFixed(0) || klaviyoSyncData?.syncProgress?.toFixed(0);
    const googleAdsPercentage =
        googleAdsSyncProgress?.toFixed(0) || googleAdsSyncData?.syncProgress?.toFixed(0);
    const tiktokPercentage =
        tiktokSyncProgress?.toFixed(0) || tiktokSyncData?.syncProgress?.toFixed(0);

    const trackstarSyncData = useMemo(() => {
        return syncingData
            ?.filter(
                (syncData) =>
                    syncData?.channel?.toLowerCase()?.includes("trackstar") &&
                    syncData?.currentSyncStep?.name?.toLowerCase() === "inventory_sync"
            )
            ?.map((syncData) => {
                let name = syncData?.trackstar?.integrationName;

                return {
                    integrationName: name,
                    percentage: syncData?.syncProgress?.toFixed(0) || 0,
                };
            });
    }, [syncingData]);

    const trackstarSalesSyncData = useMemo(() => {
        return syncingData
            ?.filter(
                (syncData) =>
                    syncData?.channel?.toLowerCase()?.includes("trackstar") &&
                    syncData?.currentSyncStep?.name?.toLowerCase() !== "inventory_sync"
            )
            ?.map((syncData) => {
                let name = syncData?.trackstar?.integrationName;
                return {
                    integrationName: name,
                    percentage: syncData?.syncProgress?.toFixed(0) || 0,
                };
            });
    }, [syncingData]);

    const trackstarDataPercentage = useMemo(() => {
        return trackstarList.map((item) => {

            const trackSyncProgress =
                trackstarSyncProgress?.find(
                    (int) => int?.integrationName === item?.trackstarIntegration
                )?.percentage || 0;
            const trackSyncValue =
                trackstarSyncData?.find(
                    (int) => int?.integrationName === item?.trackstarIntegration
                )?.percentage || 0;
            const percentage =
                trackSyncProgress > 0
                    ? trackSyncProgress?.toFixed(0)
                    : Number(trackSyncValue)?.toFixed(0);
            return {
                title: item.title,
                percentageValue: percentage || 0,
                logo: item.imageSrc,
                type: "inventory",
                integrationName: item?.trackstarIntegration,
            };
        });
    }, [trackstarSyncProgress, trackstarSyncData]);

    const trackstarSalesDataPercentage = useMemo(() => {
        return trackstarList.map((item) => {

            const trackSalesSyncProgress =
                trackstarSalesSyncProgress?.find(
                    (int) => int?.integrationName === item?.trackstarIntegration
                )?.percentage || 0;
            const trackSalesSyncValue =
                trackstarSalesSyncData?.find(
                    (int) => int?.integrationName === item?.trackstarIntegration
                )?.percentage || 0;
            const percentage =
                trackSalesSyncProgress > 0
                    ? trackSalesSyncProgress?.toFixed(0)
                    : Number(trackSalesSyncValue)?.toFixed(0);
            return {
                title: item.title,
                percentageValue: percentage || 0,
                logo: item.imageSrc,
                integrationName: item?.trackstarIntegration,
            };
        });
    }, [trackstarSalesSyncProgress, trackstarSalesSyncData]);

    

    const dataConnections: {
        title: string;
        percentageValue: number | string;
        logo: string;
        type: string;
        campaignNumer?: number;
    }[] = [
        {
            title: "Amazon",
            percentageValue: amazonPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/amazon_ciygng.svg",
            type: "inventory",
        },
        {
            title: "Shopify",
            percentageValue: shopifyPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/shopifyIcon_qelbea.svg",
            type: "inventory",
        },
        {
            title: "Quickbooks",
            percentageValue: quickbooksPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/quickbooksIcon_poqahg.svg",
            type: "inventory",
        },
        {
            title: "Quickbooks Desktop",
            percentageValue: qbDesktopPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/quickbooksIcon_poqahg.svg",
            type: "inventory",
        },
        {
            title: "Klaviyo",
            percentageValue: klaviyoPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp",
            type: "campaign",
            campaignNumer: klaviyoCampaignNo || klaviyoSyncData?.currentSyncStep?.totalSynced,
        },
        {
            title: "Google-Ads",
            percentageValue: googleAdsPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/google-ads_syiiti.svg",
            type: "campaign",
            campaignNumer: googleAdsCampaignNo || googleAdsSyncData?.currentSyncStep?.totalSynced,
        },
        {
            title: "Tiktok",
            percentageValue: tiktokPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/tiktok_logo.webp",
            type: "campaign",
            campaignNumer: tiktokCampaignNo || tiktokSyncData?.currentSyncStep?.totalSynced,
        },
        ...trackstarDataPercentage,
    ];

    const salesConnections = [
        {
            title: "Amazon",
            percentageValue: amazonSalesPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/amazon_ciygng.svg",
        },
        {
            title: "Shopify",
            percentageValue: shopifySalesPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/shopifyIcon_qelbea.svg",
        },
        {
            title: "Quickbooks Desktop",
            percentageValue: qbDesktopSalesPercentage || 0,
            logo: "https://storage.googleapis.com/heysynth_files/synth_webapp/quickbooksIcon_poqahg.svg",
        },
        ...trackstarSalesDataPercentage,
    ];

    const nonZeroDataArr = dataConnections.filter((e) => Number(e.percentageValue) > 0);
    const nonZeroSalesArr = salesConnections.filter((e) => Number(e.percentageValue) > 0);

    const productArr = nonZeroDataArr.filter((item) => item.type === "inventory");
    const campaignArr = nonZeroDataArr.filter((item) => item.type === "campaign");
    const allDataSyncPercentages = productArr.reduce(
        (acc, curr) => acc + Number(curr.percentageValue),
        0
    );

    const allCampaignSyncPercentages = campaignArr.reduce(
        (acc, curr) => acc + Number(curr.percentageValue),
        0
    );

    const allSalesSyncPercentages = salesConnections.reduce(
        (acc, curr) => acc + Number(curr.percentageValue),
        0
    );

    const navDataPercentage = allDataSyncPercentages / productArr?.length;
    const navSalesPercentage = allSalesSyncPercentages / nonZeroSalesArr?.length;

    const navCampaignPercentage = allCampaignSyncPercentages / campaignArr?.length;

    const isSyncingCompleted = allDataSyncPercentages >= 100;
    const isCampaignSyncCompleted = allCampaignSyncPercentages >= 100;

    const isSalesSyncCompleted = allSalesSyncPercentages >= 100;

    const nameArr = profile?.profile?.businessName
        ? profile?.profile?.businessName?.split(" ")
        : [];
    const initials = nameArr
        ?.map((name: string) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess) || fetchBrandTokenSuccess || switchingBrand) {
            dispatch(authActions.getLdbProfile());
            setSwitchingBrand(false);
        }
    }, [dispatch, fetchUserProfileSuccess, fetchBrandTokenSuccess, switchingBrand]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess, dispatch]);
    // useEffect(() => {
    //     dispatch(settingsActions.getCreditHistory());
    // }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchCreditHistorySuccess)) {
            setCreditUsageHistory(fetchCreditHistorySuccess?.creditUsageHistory);
        } else {
        }
    }, [fetchCreditHistorySuccess]);

    const OnSubmitPurchase = () => {
        if (Number(credit) > 0) {
            dispatch(settingsActions.getStripeSession(credit));
        }
    };

    useEffect(() => {
        if (Boolean(fetchStripeSessionSuccess)) {
            localStorage.setItem("credit", String(credit));
            setPurchaseCreditFailure(false);
            setPurchaseCreditModal(false);
            window.location.href = fetchStripeSessionSuccess?.session?.checkoutUrl;
        }
    }, [fetchStripeSessionSuccess]);

    useEffect(() => {
        const shopifySuccessModalCount = sessionStorage.getItem("shopifySuccessModalCount");
        const amazonSuccessModalCount = sessionStorage.getItem("amazonSuccessModalCount");
        const quickbooksSuccessModalCount = sessionStorage.getItem("quickbooksSuccessModalCount");
        const qbDesktopSuccessModalCount = sessionStorage.getItem("qbDesktopSuccessModalCount");
        const klaviyoSuccessModalCount = sessionStorage.getItem("klaviyoSuccessModalCount");
        const googleAdsSuccessModalCount = sessionStorage.getItem("googleAdsSuccessModalCount");
        const tiktokSuccessModalCount = sessionStorage.getItem("tiktokSuccessModalCount");

        if (
            profile?.role?.toLowerCase() === "admin" ||
            profile?.role?.toLowerCase() === "owner" ||
            profile?.role?.toLowerCase() === "manager"
        ) {
            if (
                (shopifySyncProgress === 100 || shopifySyncData?.syncProgress === 100) &&
                Number(shopifySuccessModalCount) < 1
            ) {
                setShopifyProductSyncCompletedModal(true);
            }

            if (
                (quickbookSyncProgress === 100 || quickbooksSyncData?.syncProgress === 100) &&
                Number(quickbooksSuccessModalCount) < 1
            ) {
                setQuickbooksProductSyncCompletedModal(true);
            }

            if (
                (qbDesktopSyncProgress === 100 || qbDesktopSyncData?.syncProgress === 100) &&
                Number(qbDesktopSuccessModalCount) < 1
            ) {
                setQbDesktopProductSyncCompletedModal(true);
            }

            if (
                (amazonSyncProgress === 100 || amazonSyncData?.syncProgress === 100) &&
                Number(amazonSuccessModalCount) < 1
            ) {
                setAmazonProductSyncCompletedModal(true);
            }

            if (klaviyoPercentage >= 100 && Number(klaviyoSuccessModalCount) < 1) {
                setKlaviyoProductSyncCompletedModal(true);
            }

            if (googleAdsPercentage >= 100 && Number(googleAdsSuccessModalCount) < 1) {
                setGoogleAdsProductSyncCompletedModal(true);
            }

            if (tiktokPercentage >= 100 && Number(tiktokSuccessModalCount) < 1) {
                setTiktokProductSyncCompletedModal(true);
            }
            // end product side

            if (Number(shopifySalesPercentage) === 100) {
                setShopifySalesSyncCompletedModal(true);
            }

            if (Number(amazonSalesPercentage) === 100) {
                setAmazonSalesSyncCompletedModal(true);
            }

            if (Number(qbDesktopSalesPercentage) === 100) {
                setQbDesktopSalesSyncCompletedModal(true);
            }
        }
    }, [
        shopifySyncData?.syncProgress,
        shopifySyncProgress,
        quickbooksSyncData?.syncProgress,
        quickbookSyncProgress,
        qbDesktopSyncData?.syncProgress,
        qbDesktopSyncProgress,
        amazonSyncProgress,
        amazonSyncData?.syncProgress,
        shopifySalesPercentage,
        amazonSalesPercentage,
        qbDesktopSalesPercentage,
        profile?.role,
        klaviyoPercentage,
    ]);

    useEffect(() => {
        if (
            profile?.role?.toLowerCase() === "admin" ||
            profile?.role?.toLowerCase() === "owner" ||
            profile?.role?.toLowerCase() === "manager"
        ) {
            setTrackstarProductSyncCompletedModal((prev = []) => {
                const updatedModals = [...prev];
                trackstarDataPercentage?.forEach((product) => {
                   
                    const setModalToTrue =
                        Number(product.percentageValue) === 100 &&
                        Number(
                            sessionStorage.getItem(`trackstar.${product.integrationName}ModalCount`)
                        ) < 1;
                    if (setModalToTrue) {
                        const existingIndex = updatedModals.findIndex(
                            (sync) => sync.integrationName === product.integrationName
                        );
                        if (existingIndex === -1) {
                            updatedModals.push({
                                integrationName: product.integrationName,
                                modal: true,
                            });
                        }
                    }
                });
                return _.isEqual(prev, updatedModals) ? prev : updatedModals;
            });

            setTrackstarSalesSyncCompletedModal((prev = []) => {
                const updatedModals = [...prev];
                trackstarSalesDataPercentage?.forEach((product) => {
                    if (Number(product.percentageValue) === 100) {
                        const existingIndex = updatedModals.findIndex(
                            (sync) => sync.integrationName === product.integrationName
                        );

                        if (existingIndex === -1) {
                            updatedModals.push({
                                integrationName: product.integrationName,
                                modal: true,
                            });
                        }
                    }
                });

                return _.isEqual(prev, updatedModals) ? prev : updatedModals;
            });
        }
    }, [trackstarSalesDataPercentage, trackstarDataPercentage, profile?.role]);

    useEffect(() => {
        if (Boolean(importedSyncedProductsSuccess)) {
            if (shopifyPercentage) {
                setShopifySyncProgress(undefined);
            }

            const trackSync = sessionStorage?.getItem("trackSync");
            setTrackstarSyncProgress((prev) =>
                prev.map((syncProgress) =>
                    trackSync === syncProgress?.integrationName && syncProgress?.percentage
                        ? { ...syncProgress, percentage: undefined }
                        : syncProgress
                )
            );
            sessionStorage.removeItem("trackSync");

            if (quickbooksPercentage) {
                setQuickbookSyncProgress(undefined);
            }

            if (qbDesktopPercentage) {
                setQbDesktopSyncProgress(undefined);
            }

            if (amazonPercentage) {
                setAmazonSyncProgress(undefined);
            }

            dispatch(inventoryActions.resetImportSyncedProducts());
        }
    }, [
        dispatch,
        importedSyncedProductsSuccess,
        shopifyPercentage,
        quickbooksPercentage,
        qbDesktopPercentage,
        amazonPercentage,
        trackstarDataPercentage,
    ]);

    useEffect(() => {
        if (manageConnectionSuccess) {
            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() === "shopify" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setShopifySyncProgress(undefined);
            }

            //check this
            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() === "trackstar" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setTrackstarSyncProgress((prev) =>
                    prev.map((syncProgress) =>
                        manageConnectionSuccess?.socialAccount?.trackstar?.integrationName ===
                            syncProgress?.integrationName && syncProgress?.percentage
                            ? { ...syncProgress, percentage: undefined }
                            : syncProgress
                    )
                );
            }

            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() === "amazon" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setAmazonSyncProgress(undefined);
            }

            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() === "quickbooks" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setQuickbookSyncProgress(undefined);
            }

            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() ===
                    "quickbooks-desktop" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setQbDesktopSyncProgress(undefined);
            }

            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() === "klaviyo" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setKlaviyoSyncProgress(undefined);
            }

            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() === "google_ads" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setGoogleAdsSyncProgress(undefined);
            }

            if (
                manageConnectionSuccess?.socialAccount?.channel?.toLowerCase() === "tiktok_ads" &&
                !manageConnectionSuccess?.socialAccount?.active
            ) {
                setTiktokSyncProgress(undefined);
            }
        }
    }, [manageConnectionSuccess]);

    const {} = useChannel(`data-sync`, (syncRes) => {
        // console.log("inventory data sync -->", syncRes);
        if (syncRes?.name === `shopify-${profile?.parentId || profile?._id}`) {
            setShopifySyncProgress(syncRes?.data?.progress);
        }

        if (syncRes?.name === `quickbooks-${profile?.parentId || profile?._id}`) {
            setQuickbookSyncProgress(syncRes?.data?.progress);
        }

        if (syncRes?.name === `quickbooks-desktop-${profile?.parentId || profile?._id}`) {
            setQbDesktopSyncProgress(syncRes?.data?.progress);
        }

        if (syncRes?.name === `amazon-${profile?.parentId || profile?._id}`) {
            setAmazonSyncProgress(syncRes?.data?.progress);
        }

        trackstarList?.forEach((item) => {
            if (
                syncRes?.name ===
                `trackstar.${item?.trackstarIntegration}-${profile?.parentId || profile?._id}`
            ) {
                setSync(false);

                setTrackstarSyncProgress((prev) => {
                    const currArr = [...prev];
                    const findArr = currArr?.find(
                        (arr) => arr?.integrationName === item?.trackstarIntegration
                    );
                    if (findArr) {
                        findArr.percentage = syncRes?.data?.progress || 0;
                        return currArr;
                    } else {
                        return [
                            ...currArr,
                            {
                                integrationName: item?.trackstarIntegration,
                                percentage: syncRes?.data?.progress || 0,
                            },
                        ];
                    }
                });
            }
        });
    });

    const {} = useChannel(`sales-data-sync`, (salesDataSync) => {
        // console.log("sales sync -->", salesDataSync);
        if (salesDataSync?.name === `shopify-${profile?.parentId || profile?._id}`) {
            setShopifySalesSyncProgress(salesDataSync?.data?.progress);
        }

        if (salesDataSync?.name === `amazon-${profile?.parentId || profile?._id}`) {
            setAmazonSalesSyncProgress(salesDataSync?.data?.progress);
        }

        if (salesDataSync?.name === `quickbooks-desktop-${profile?.parentId || profile?._id}`) {
            setQbDesktopSalesSyncProgress(salesDataSync?.data?.progress);
        }
        trackstarList?.forEach((item) => {
            if (
                salesDataSync?.name ===
                `trackstar.${item?.trackstarIntegration}-${profile?.parentId || profile?._id}`
            ) {
                setTrackstarSalesSyncProgress((prev) => {
                    const currArr = [...prev];
                    const findArr = currArr?.find(
                        (arr) => arr?.integrationName === item?.trackstarIntegration
                    );
                    if (findArr) {
                        findArr.percentage = salesDataSync?.data?.progress || 0;
                        return currArr;
                    } else {
                        return [
                            ...currArr,
                            {
                                integrationName: item?.trackstarIntegration,
                                percentage: salesDataSync?.data?.progress || 0,
                            },
                        ];
                    }
                });
            }
        });
    });

    const {} = useChannel(`campaign-data-sync`, (syncRes) => {
        // console.log(syncRes);
        if (syncRes?.name === `klaviyo-${profile?.parentId || profile?._id}`) {
            setKlaviyoSyncProgress(syncRes?.data?.progress);
        }

        if (syncRes?.name === `google-ads-${profile?.parentId || profile?._id}`) {
            setGoogleAdsSyncProgress(syncRes?.data?.progress);
        }

        if (syncRes?.name === `tiktok_ads-${profile?.parentId || profile?._id}`) {
            setTiktokSyncProgress(syncRes?.data?.progress);
        }
    });

    const handleTrackStarProductModalCount = (getIntegrationDetails, intModalIndex) => {
        const trackstarModalCount = sessionStorage.getItem(
            `trackstar.${getIntegrationDetails?.trackstarIntegration}ModalCount`
        );

        if (!trackstarModalCount) {
            sessionStorage.setItem(
                `trackstar.${getIntegrationDetails?.trackstarIntegration}ModalCount`,
                String(1)
            );
        }

        setTrackstarProductSyncCompletedModal((prev) =>
            prev?.map((modal, idx) => (idx === intModalIndex ? { ...modal, modal: false } : modal))
        );
    };

    const displaySyncProgressTab = () => {
        switch (activeInProgressTab) {
            case 0: {
                return (
                    <div className="bg-[#FFF9EB] space-y-3 rounded-[6px] px-4 py-5 mt-3">
                        {productArr?.map((connection) => {
                            return (
                                <div key={connection.title}>
                                    <div className="flex justify-between items-center">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            {connection.title} Products Synced
                                        </p>
                                        <div className="flex items-center">
                                            {" "}
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                {connection.percentageValue}%
                                            </p>{" "}
                                        </div>
                                    </div>
                                    <div className="">
                                        <ProgressBar
                                            containerClass="!bg-[#EADFC1]"
                                            className={`!bg-[#FFB902] mt-1 w-full !rounded-[5px] ${
                                                Number(connection.percentageValue) === 100
                                                    ? "!bg-[#00DB8F]"
                                                    : "!bg-[#FFB902]"
                                            }`}
                                            progress={Number(connection.percentageValue)}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        {allDataSyncPercentages === 0 && (
                            <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                No product sync data available
                            </p>
                        )}

                        {/* {!amazonPercentage &&
                            !shopifyPercentage &&
                            !quickbooksPercentage &&
                            !qbDesktopPercentage && (
                                <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                    No product sync data available
                                </p>
                            )} */}
                    </div>
                );
            }

            case 1: {
                return (
                    <div className="">
                        <p className="text-sm text-g-75 font-rocGroteskMedium py-3">
                            Your sales data from multiple platforms is currently being synced to
                            Synth. This process might take a few moments.
                        </p>
                        <div className="bg-[#FFF9EB] space-y-3 rounded-[6px] px-4 py-5">
                            {nonZeroSalesArr.map((salesConnection) => {
                                return (
                                    <div>
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                {salesConnection.title} Sales Data Synced
                                            </p>
                                            <div className="flex items-center">
                                                {" "}
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    {salesConnection.percentageValue}%
                                                </p>{" "}
                                            </div>
                                        </div>
                                        <div className="">
                                            <ProgressBar
                                                containerClass="!bg-[#EADFC1]"
                                                className={`!bg-[#FFB902] mt-1 w-full !rounded-[5px] ${
                                                    Number(salesConnection.percentageValue) === 100
                                                        ? "!bg-[#00DB8F]"
                                                        : "!bg-[#FFB902]"
                                                }`}
                                                progress={Number(salesConnection.percentageValue)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}

                            {allSalesSyncPercentages === 0 && (
                                <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                    No sales sync data available
                                </p>
                            )}

                            {/* {!shopifySalesPercentage &&
                                !amazonSalesPercentage &&
                                !qbDesktopSalesPercentage && (
                                    <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                        No sales sync data available
                                    </p>
                                )} */}
                        </div>
                    </div>
                );
            }

            default:
                break;
        }
    };

    const displayCompletedSyncTab = () => {
        switch (activeCompletedSyncTab) {
            case 0: {
                return (
                    <div
                        className={`${
                            allDataSyncPercentages === 0 ? "" : "bg-[#E9FFF7]"
                        } space-y-2 rounded-[6px] px-4 py-5`}
                    >
                        {productArr?.map((connection) => {
                            return (
                                <div key={connection.title}>
                                    <div className="flex justify-between items-center">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            {connection.title} Products Synced completed!
                                        </p>
                                        <div className="flex space-x-1 items-center">
                                            {" "}
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                {connection.percentageValue}%
                                            </p>{" "}
                                            <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                        </div>
                                    </div>
                                    <div className="">
                                        <ProgressBar
                                            className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                            progress={Number(connection.percentageValue)}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        {allDataSyncPercentages === 0 ? (
                            <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                No product sync data available
                            </p>
                        ) : (
                            <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                Next step: Select Product/SKU to Import to Inventory
                            </p>
                        )}

                        {/* <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                            {!shopifyPercentage &&
                            !quickbooksPercentage &&
                            !qbDesktopPercentage &&
                            !amazonPercentage
                                ? "No product sync data available"
                                : "Next step: Select Product/SKU to Import to Inventory"}
                        </p> */}
                    </div>
                );
            }

            case 1: {
                return (
                    <div
                        className={`${
                            allSalesSyncPercentages === 0 ? "" : "bg-[#E9FFF7]"
                        } space-y-2 rounded-[6px] px-4 py-5`}
                    >
                        {nonZeroSalesArr.map((salesConnection) => {
                            return (
                                <div>
                                    <div className="flex justify-between items-center">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            {salesConnection.title} Sales Data Synced
                                        </p>
                                        <div className="flex space-x-1 items-center">
                                            {" "}
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                {salesConnection.percentageValue}%
                                            </p>{" "}
                                            <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                        </div>
                                    </div>
                                    <div className="">
                                        <ProgressBar
                                            className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                            progress={salesConnection.percentageValue}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        {/* {amazonSalesPercentage && (
                            <div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-rocGroteskMedium text-g-75">
                                        Amazon Sales Data Synced
                                    </p>
                                    <div className="flex space-x-1 items-center">
                                        {" "}
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            100%
                                        </p>{" "}
                                        <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                    </div>
                                </div>
                                <div className="">
                                    <ProgressBar
                                        className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                        progress={100}
                                    />
                                </div>
                            </div>
                        )}

                        {shopifySalesPercentage && (
                            <div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-rocGroteskMedium text-g-75">
                                        Shopify Sales Data Synced
                                    </p>
                                    <div className="flex space-x-1 items-center">
                                        {" "}
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            100%
                                        </p>{" "}
                                        <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                    </div>
                                </div>
                                <div className="">
                                    <ProgressBar
                                        className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                        progress={100}
                                    />
                                </div>
                            </div>
                        )}

                        {qbDesktopSalesPercentage && (
                            <div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-rocGroteskMedium text-g-75">
                                        Quickbooks Desktop Sales Data Synced
                                    </p>
                                    <div className="flex space-x-1 items-center">
                                        {" "}
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            100%
                                        </p>{" "}
                                        <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                    </div>
                                </div>
                                <div className="">
                                    <ProgressBar
                                        className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                        progress={100}
                                    />
                                </div>
                            </div>
                        )} */}

                        {allSalesSyncPercentages === 0 && (
                            <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                No sales sync data available
                            </p>
                        )}

                        {/* {!shopifySalesPercentage &&
                            !amazonSalesPercentage &&
                            !qbDesktopSalesPercentage && (
                                <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                    No sales sync data available
                                </p>
                            )} */}
                    </div>
                );
            }
            default:
                break;
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                switchBrandRef.current &&
                !switchBrandRef.current.contains(event.target as Node) &&
                switchBrand
            ) {
                setSwitchBrand(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [switchBrandRef, switchBrand]);
    // const firstLink = Navlinks()[0]?.link;
    const firstLink = allowedLinks[0]?.link;

    useEffect(() => {
        if (Boolean(fetchBrandTokenSuccess)) {
            const userProfile = getFromStorage("ally-user");

            const userData = { ...userProfile };
            if (!userData.user?.isSwitchedBrand) {
                userData.mainToken = userData.token;
            }

            userData.token = fetchBrandTokenSuccess.token;
            if (!userData?.user?.isSwitchedBrand) {
                userData.user = {
                    ...userData.user,
                    userId: userData.user?._id,
                };
            }
            userData.user = {
                ...userData.user,
                isMultiBrandAccount: false,
                isSwitchedBrand: true,
                _id: fetchBrandTokenSuccess?.brand?._id,
                parentId: fetchBrandTokenSuccess?.brand?.parentId,
            };

            saveToStorage("ally-user", userData, true);

            if (
                window.location.pathname === firstLink &&
                fetchUserProfileSuccess?._id === fetchBrandTokenSuccess?.brand?._id
            ) {
                // navigate(`/dashboard/home`, { replace: true });
                navigate(`${firstLink}`, { replace: true, state: { internalNavigation: true } });
                dispatch(multibrandActions?.resetGetBrandTokenSuccess());
                window.location.reload();
            }

            if (
                window.location.pathname !== firstLink &&
                fetchUserProfileSuccess?._id === fetchBrandTokenSuccess?.brand?._id
            ) {
                // navigate(`/dashboard/home`, { replace: true });

                navigate(`${firstLink}`, { replace: true, state: { internalNavigation: true } });
                dispatch(multibrandActions?.resetGetBrandTokenSuccess());
            }
        }
    }, [fetchBrandTokenSuccess, dispatch, firstLink, fetchUserProfileSuccess?._id]);

    return (
        <div className="w-full  z-[1000]">
            <nav className="w-full h-[84px] max-lg:h-[65px]  flex justify-between pr-[47px] pl-[34px] max-lg:px-6 items-center bg-white border-b border-slate-100">
                <div className="flex items-center space-x-3">
                    <div className="flex items-center">
                        <Link
                            to={
                                profile?.isMultiBrandAccount || profile?.parent?.isMultiBrandAccount
                                    ? "/dashboard/portfolio/home"
                                    : // : "/dashboard/home"
                                      firstLink
                            }
                        >
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/synthAlly_logo_skcywb.svg"
                                alt="synthAlly logo"
                                className="w-[110px] h-6 mt-[-5px] max-lg:w-fit max-lg:h-[28px]"
                            />
                        </Link>
                        <span className="text-sm font-rocGroteskMedium text-slate-300">/</span>
                    </div>

                    <div className="flex max-lg:hidden items-center space-x-6">
                        {allowedLinks?.map((navLink, index) => {
                            return (
                                <NavLink
                                    key={navLink?.title + index}
                                    to={navLink?.link}
                                    end={navLink?.link === "/dashboard"}
                                    className={({ isActive }) =>
                                        ` font-rocGroteskMedium hover:bg-slate-50 hover:text-slate-900  rounded-md ${
                                            isActive ? "!text-slate-900 " : "text-slate-500"
                                        }`
                                    }
                                >
                                    {({ isActive }) => (
                                        <div className={`flex items-center space-x-2`}>
                                            {/* {isActive && (
                                               <i className={`${navLink?.iconClass} text-base`}></i>
                                               )} */}
                                            <span className={`text-sm `}>{navLink?.title}</span>
                                        </div>
                                    )}
                                </NavLink>
                            );
                        })}
                    </div>
                </div>

                <div className="w-fit flex space-x-4 items-center justify-end pl-[37px]">
                    {Number(allDataSyncPercentages) > 0 && !profile?.isMultiBrandAccount && (
                        <div
                            onClick={() =>
                                isSyncingCompleted
                                    ? setShowProductSync(true)
                                    : setShowProductAndSalesSyncInProgress(true)
                            }
                            className="flex items-center space-x-2 bg-white py-2 px-3 h-[36px] rounded-[200px] border border-[#F4F5F780] shadow-[0px_1px_2px_0px_#5B687126] cursor-pointer"
                        >
                            <div className="flex items-center ">
                                {productArr?.slice(0, 3).map((dataConnection) => {
                                    return (
                                        <img
                                            key={dataConnection.title}
                                            src={dataConnection.logo}
                                            alt={dataConnection.title}
                                            className={`w-[18px] h-[18px] `}
                                        />
                                    );
                                })}
                                {productArr?.slice(3).length > 0 ? (
                                    <div className="w-[18px] h-[18px] rounded-full flex items-center justify-center bg-slate-100 text-[10px]">
                                        +{productArr?.slice(3).length}
                                    </div>
                                ) : null}
                            </div>

                            <span className="text-[13px] font-rocGroteskMedium">
                                {isSyncingCompleted
                                    ? "Product Sync Completed"
                                    : "Product Sync in Progress"}
                                ({Number(navDataPercentage)?.toFixed(0)}
                                %)
                            </span>

                            {isSyncingCompleted ? (
                                <i className="ri-checkbox-circle-fill text-base text-[#00DB8F]"></i>
                            ) : (
                                <SpinnerLoader size={16} />
                            )}
                        </div>
                    )}

                    {Number(allSalesSyncPercentages) > 0 && !profile?.isMultiBrandAccount && (
                        <div
                            onClick={() => {
                                if (isSalesSyncCompleted) {
                                    setActiveCompletedSyncTab(1);
                                    setShowProductSync(true);
                                } else {
                                    setActiveInProgressTab(1);
                                    setShowProductAndSalesSyncInProgress(true);
                                }
                            }}
                            className="flex items-center space-x-2 bg-white py-2 px-3 h-[36px] rounded-[200px] border border-[#F4F5F780] shadow-[0px_1px_2px_0px_#5B687126] cursor-pointer"
                        >
                            <div className="flex items-center ">
                                {/* <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/quickbooksIcon_poqahg.svg"
                                    alt="quickbooks"
                                    className={`w-[18px] h-[18px] `}
                                />
                             */}

                                {nonZeroSalesArr?.slice(0, 3).map((saleConnection) => {
                                    return (
                                        <img
                                            key={saleConnection.title}
                                            src={saleConnection.logo}
                                            alt={saleConnection.title}
                                            className={`w-[18px] h-[18px] `}
                                        />
                                    );
                                })}
                                {nonZeroSalesArr?.slice(3).length > 0 ? (
                                    <div className="w-[18px] h-[18px] rounded-full flex items-center justify-center bg-slate-100 text-[10px] ">
                                        +{nonZeroSalesArr?.slice(3).length}
                                    </div>
                                ) : null}
                            </div>

                            <span className="text-[13px] font-rocGroteskMedium">
                                {isSalesSyncCompleted
                                    ? "Sales Data Sync Completed"
                                    : "Sales Data Sync in Progress"}{" "}
                                ({navSalesPercentage}%)
                            </span>

                            {isSalesSyncCompleted ? (
                                <i className="ri-checkbox-circle-fill text-base text-[#00DB8F]"></i>
                            ) : (
                                <SpinnerLoader size={16} />
                            )}
                        </div>
                    )}

                    {Number(allCampaignSyncPercentages) > 0 && !profile?.isMultiBrandAccount && (
                        <div
                            onClick={() => setShowCampaignSync(true)}
                            className="flex items-center space-x-2 bg-white py-2 px-3 h-[36px] rounded-[200px] border border-[#F4F5F780] shadow-[0px_1px_2px_0px_#5B687126] cursor-pointer"
                        >
                            <div className="flex items-center ">
                                {campaignArr?.slice(0, 3).map((dataConnection) => {
                                    return (
                                        <img
                                            key={dataConnection.title}
                                            src={dataConnection.logo}
                                            alt={dataConnection.title}
                                            className={`w-[18px] h-[18px] `}
                                        />
                                    );
                                })}
                                {campaignArr?.slice(3).length > 0 ? (
                                    <div className="w-[18px] h-[18px] rounded-full flex items-center justify-center bg-slate-100 text-[10px]">
                                        +{campaignArr?.slice(3).length}
                                    </div>
                                ) : null}
                            </div>

                            <span className="text-[13px] font-rocGroteskMedium">
                                {isCampaignSyncCompleted
                                    ? "Campaign Sync Completed"
                                    : "Campaign Sync in Progress"}
                                ({Number(navCampaignPercentage)?.toFixed(0)}
                                %)
                            </span>

                            {isCampaignSyncCompleted ? (
                                <i className="ri-checkbox-circle-fill text-base text-[#00DB8F]"></i>
                            ) : (
                                <SpinnerLoader size={16} />
                            )}
                        </div>
                    )}

                    <div className="flex items-center gap-4">
                        <div className="relative">
                            <Dropdown
                                value={""}
                                dropdown={
                                    <div>
                                        <div className="flex items-center space-x-2  w-fit h-[48px] bg-slate-50 border border-slate-200 py-2 px-2.5 rounded-[100px]">
                                            {Boolean(profile?.profile?.avatar) ? (
                                                <img
                                                    src={profile?.profile?.avatar}
                                                    alt="logo"
                                                    className="rounded-full w-6 h-6 border-[1.5px] border-g-75"
                                                />
                                            ) : (
                                                <div className=" rounded-full w-[24px] h-[24px] border-[1.5px] border-g-75 flex items-center justify-center text-[10px]">
                                                    {initials}
                                                </div>
                                            )}
                                            <span className="text-sm font-rocGroteskMedium text-gm-50">
                                                {truncate(profile?.profile?.businessName, {
                                                    length: 10,
                                                })}
                                            </span>
                                            <div className="flex flex-col justify-center items-center gap-[1px] mt-[-21px]">
                                                <i className="ri-arrow-drop-up-fill h-[4px] before:content-['\ea55']"></i>
                                                <i className="ri-arrow-drop-down-fill h-[4px] before:content-['\ea4f']"></i>
                                            </div>
                                        </div>
                                    </div>
                                }
                                dropdownContainerClasses={
                                    "left-[-110px] top-[50px] shadow-faintShadow z-[999999] rounded !py-0 !border-0"
                                }
                                dropdownClassName={"!max-h-fit"}
                                dropdownItemsClasses={
                                    "border-b border-slate-100 text-slate-500 last:border-none !px-2.5 w-[150px]"
                                }
                                name={"quickAction"}
                                leaveOpen={true}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div className="bg-white py-2.5 rounded-[6px] w-[257px]">
                                                {!profile?.isMultiBrandAccount &&
                                                    !profile?.parent?.isMultiBrandAccount && (
                                                        <div className="pb-2.5 px-2.5 border-b border-slate-100 space-y-2">
                                                            <Link
                                                                to={"/dashboard/track-trace"}
                                                                className="block text-sm font-rocGroteskMedium text-slate-500 p-1"
                                                            >
                                                                Track and trace
                                                            </Link>
                                                            <Link
                                                                to={"/dashboard/integrations"}
                                                                className="block text-sm font-rocGroteskMedium text-slate-500 p-1"
                                                            >
                                                                Integrations
                                                            </Link>
                                                            {/* <Link to={"#"} className="block text-sm font-rocGroteskMedium text-slate-500 p-1">
                                                    Playground
                                                </Link> */}
                                                        </div>
                                                    )}

                                                <div className="px-2.5 space-y-2">
                                                    {(profile?.isMultiBrandAccount ||
                                                        profile?.parent?.isMultiBrandAccount ||
                                                        profile?.accountType === "brand") && (
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSwitchBrand(true);
                                                            }}
                                                        >
                                                            <div className="flex items-center space-x-2.5 p-1 cursor-pointer">
                                                                <i className="ri-arrow-left-s-line text-slate-400"></i>
                                                                <span className="block text-sm font-rocGroteskMedium text-slate-400 ">
                                                                    Switch brands
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {profile?.accountType !== "brand" && (
                                                        <Link
                                                            to={
                                                                profile?.isMultiBrandAccount ||
                                                                profile?.parent?.isMultiBrandAccount
                                                                    ? "/dashboard/portfolio/team"
                                                                    : "/dashboard/teams"
                                                            }
                                                            className="flex items-center space-x-2.5 p-1"
                                                        >
                                                            <i className="ri-team-line text-base text-slate-400"></i>
                                                            <span className="block text-sm font-rocGroteskMedium text-slate-400 ">
                                                                Team management
                                                            </span>
                                                        </Link>
                                                    )}
                                                    <Link
                                                        to={"/dashboard/settings/0"}
                                                        className="flex items-center space-x-2.5 p-1"
                                                    >
                                                        <i className="ri-settings-3-line text-base text-slate-400"></i>
                                                        <span className="block text-sm font-rocGroteskMedium text-slate-400 ">
                                                            Settings
                                                        </span>
                                                    </Link>
                                                    <div
                                                        onClick={() =>
                                                            dispatch(authActions.logOut())
                                                        }
                                                        className="flex items-center space-x-2.5 p-1 cursor-pointer"
                                                    >
                                                        <i className="ri-arrow-right-up-line text-base text-r-55"></i>
                                                        <span className="block text-sm font-rocGroteskMedium text-r-55">
                                                            Logout
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />

                            {switchBrand && (
                                <div
                                    ref={switchBrandRef}
                                    // onClick={(e) => e.stopPropagation()}
                                    className="py-2 px-2.5 space-y-2 bg-white min-w-[200px] max-w-[215px] absolute top-[80px] left-[-320px] overflow-auto shadow-faintShadow z-[999999] rounded"
                                >
                                    <div>
                                        <TextInput
                                            value={searchTwo}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={(e) => handleSearchTwo(e)}
                                            onInput={handleDebouncedChangeTwo}
                                            inputPlaceholder="Search brands"
                                            // containerClassname=""
                                            inputContainerClassname={"!h-[40px]"}
                                            inputClassName="w-full !h-[40px]"
                                        />
                                    </div>

                                    <div className="space-y-2 overflow-y-auto max-h-[236px]">
                                        <div
                                            onClick={() => {
                                                setSwitchBrand(false);
                                                setSwitchingBrand(true);
                                                const userProfile = getFromStorage("ally-user");
                                                const userData = { ...userProfile };

                                                if (userData.mainToken) {
                                                    userData.token = userData.mainToken;
                                                }

                                                userData.user = {
                                                    ...userData.user,
                                                    isMultiBrandAccount: true,
                                                    isSwitchedBrand: false,
                                                };

                                                setSelectedBrandId("");
                                                saveToStorage("ally-user", userData, true);
                                                navigate(`/dashboard/portfolio/home`, {
                                                    replace: true,
                                                    state: { internalNavigation: true },
                                                });
                                            }}
                                            className={`flex items-center space-x-2 p-1 cursor-pointer group hover:bg-[#D0F4ED66] ${
                                                selectedBrandId === "" &&
                                                profile?.accountType !== "brand" &&
                                                "bg-[#D0F4ED66]"
                                            }`}
                                        >
                                            {selectedBrandId === "" &&
                                            profile?.accountType !== "brand" ? (
                                                <i className="ri-checkbox-circle-fill text-g-70"></i>
                                            ) : (
                                                <i className="ri-store-3-line "></i>
                                            )}
                                            <span
                                                className={`block text-sm font-rocGroteskMedium group-hover:text-g-70 ${
                                                    selectedBrandId === "" &&
                                                    profile?.accountType !== "brand"
                                                        ? "text-g-70"
                                                        : "text-slate-400"
                                                }`}
                                            >
                                                All brands
                                            </span>
                                        </div>
                                        {brandListSearch?.map((brand) => {
                                            return (
                                                <div
                                                    key={brand?._id}
                                                    onClick={() => {
                                                        setSwitchBrand(false);
                                                        setSelectedBrandId(brand?._id);
                                                        // dispatch(
                                                        //     authActions.resetGetUserProfileSuccess()
                                                        // );
                                                        dispatch(
                                                            multibrandActions.getBrandToken(
                                                                brand?._id
                                                            )
                                                        );
                                                    }}
                                                    className={`flex items-center space-x-2 p-1 cursor-pointer group hover:bg-[#D0F4ED66] rounded-sm ${
                                                        (selectedBrandId === brand?._id ||
                                                            profile?._id === brand?._id) &&
                                                        "bg-[#D0F4ED66]"
                                                    }`}
                                                >
                                                    {selectedBrandId === brand?._id ||
                                                    profile?._id === brand?._id ? (
                                                        <i className="ri-checkbox-circle-fill text-g-70"></i>
                                                    ) : (
                                                        <img
                                                            src={brand?.profile?.avatar}
                                                            alt={`${brand?.brandName} logo`}
                                                            className="w-4 h-4 rounded-full"
                                                        />
                                                    )}
                                                    {/* <i className="ri-checkbox-circle-fill text-g-70"></i> */}

                                                    <span
                                                        className={`block text-sm font-rocGroteskMedium text-slate-400 group-hover:text-g-70 ${
                                                            (selectedBrandId === brand?._id ||
                                                                profile?._id === brand?._id) &&
                                                            "text-g-70"
                                                        }`}
                                                    >
                                                        {brand?.brandName}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
            <div className="hidden max-lg:flex items-center justify-between h-[64px] px-6 bg-white border-b border-slate-100">
                <div className="flex items-center space-x-3">
                    {showNavIcon && (
                        <i
                            className="ri-arrow-left-line text-[#142837] text-lg"
                            onClick={onClick}
                        ></i>
                    )}

                    <p className="text-base font-rocGroteskMedium text-[#142837]">{navName}</p>
                </div>
                <div className="flex items-center gap-5">
                    {/* <div className="cursor-pointer">
                        <i className="ri-question-line text-lg text-slate-700"></i>
                    </div>

                    <div className="relative cursor-pointer">
                        <i className="ri-notification-3-fill text-xl text-slate-700"></i>
                        <p className="p-[2px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                            12
                        </p>
                    </div> */}
                    <div className="cursor-pointer">
                        <i
                            onClick={() => setSidebarCollapsed(false)}
                            className="ri-menu-fill text-lg text-slate-700"
                        ></i>
                    </div>
                </div>
            </div>
            <ModalContainer
                open={fetchingBrandToken || switchingBrand}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                    <p className="text-sm text-gm-50 font-rocGroteskMedium mt-2">
                        Switching brands...
                    </p>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showCampaignSync && !profile?.isMultiBrandAccount}
                showCloseIcon={false}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setShowCampaignSync(false);
                    setKlaviyoSyncProgress(undefined);
                    setGoogleAdsSyncProgress(undefined);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className=" pt-4">
                        <div className="px-6 flex justify-between pb-3 items-center ">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                {allCampaignSyncPercentages >= 100
                                    ? "Campaign data sync completed"
                                    : "Campaign data syncing in progress"}
                            </p>
                            <i
                                className="ri-close-fill text-xl text-gm-50 cursor-pointer"
                                onClick={() => {
                                    setShowCampaignSync(false);
                                    setKlaviyoSyncProgress(undefined);
                                    setGoogleAdsSyncProgress(undefined);
                                }}
                            ></i>
                        </div>
                        <div className="border-b border-slate-200 "></div>
                        <div className="border-b border-slate-200">
                            <div className="px-6 mt-3 mb-6">
                                <div
                                    className={`${
                                        allCampaignSyncPercentages >= 100
                                            ? "bg-[#E9FFF7]"
                                            : "bg-[#FFF9EB]"
                                    } space-y-2 rounded-[6px] px-4 py-5`}
                                >
                                    {campaignArr?.map((connection) => {
                                        return (
                                            <div key={connection.title}>
                                                <div className="flex justify-between items-center">
                                                    <div className="flex items-center space-x-2">
                                                        <div className="w-[26px] h-[26px] rounded-full bg-white flex items-center justify-center border border-[#FDFDFD] shadow-[0px_1px_2px_0px_#00000012]">
                                                            <img
                                                                src={connection.logo}
                                                                alt={connection.title}
                                                                className="w-[14px] h-[14px]"
                                                            />
                                                        </div>

                                                        <div>
                                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                                {connection.title}
                                                            </p>
                                                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                                                {connection?.campaignNumer}{" "}
                                                                campaigns synced
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="flex space-x-1 items-center">
                                                        {" "}
                                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                                            {connection.percentageValue}%
                                                        </p>{" "}
                                                        {Number(connection.percentageValue) >=
                                                            100 && (
                                                            <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <ProgressBar
                                                        className={`${
                                                            Number(connection.percentageValue) >=
                                                            100
                                                                ? "!bg-[#00DB8F]"
                                                                : "!bg-y-10"
                                                        } mt-1 w-full !rounded-[5px]`}
                                                        progress={Number(
                                                            connection.percentageValue
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="w-full space-x-3 px-6 flex  py-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setShowCampaignSync(false);
                                    setKlaviyoSyncProgress(undefined);
                                    setGoogleAdsSyncProgress(undefined);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showProductSync && !profile?.isMultiBrandAccount}
                showCloseIcon={false}
                tailwindClassName="w-[600px] max-h-[80vh]"
                closeModal={() => {
                    setShowProductSync(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className=" pt-4">
                        <div className="px-6 flex justify-between pb-3 items-center ">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Product sync completed. Select products for inventory
                            </p>
                            <i
                                className="ri-close-fill text-xl text-gm-50 cursor-pointer"
                                onClick={() => {
                                    setShowProductSync(false);
                                }}
                            ></i>
                        </div>
                        <div className="border-b border-slate-200 "></div>
                        <div className="border-b border-slate-200">
                            <div className="px-6 mt-3 mb-6">
                                <TabsContainer
                                    tabs={syncTabs}
                                    activeTab={activeCompletedSyncTab}
                                    setActiveTab={setActiveCompletedSyncTab}
                                    component={displayCompletedSyncTab()}
                                    className="!px-0"
                                    itemClassName="!pb-2"
                                    borderLineClase={"!text-slate-100 w-full max-lg:w-full"}
                                />
                            </div>
                        </div>
                        <div className="w-full space-x-3 px-6 flex  py-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setShowProductSync(false);
                                }}
                            />
                            <Button
                                btnText={
                                    activeCompletedSyncTab
                                        ? "View Sales Data"
                                        : "View synced products"
                                }
                                type={"button"}
                                btnPermission={productPermissions}
                                onClick={() => {
                                    setShowProductSync(false);
                                    if (!activeCompletedSyncTab) {
                                        navigate(`/dashboard/inventory/import-products`, {
                                            state: { internalNavigation: true },
                                        });
                                    }
                                    if (activeCompletedSyncTab) {
                                        navigate(`/dashboard/order?currTab=1`, {
                                            state: { internalNavigation: true },
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showProductAndSalesSyncInProgress && !profile?.isMultiBrandAccount}
                showCloseIcon={false}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setShowProductAndSalesSyncInProgress(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className=" pt-4">
                        <div className="px-6 flex justify-between pb-3 items-center ">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Product and Sales Data Sync in Progress
                            </p>
                            <i
                                className="ri-close-fill text-xl text-gm-50 cursor-pointer"
                                onClick={() => {
                                    setShowProductAndSalesSyncInProgress(false);
                                }}
                            ></i>
                        </div>
                        <div className="border-b border-slate-200 "></div>
                        <div className="px-6 mt-3 mb-6">
                            <TabsContainer
                                tabs={syncTabs}
                                activeTab={activeInProgressTab}
                                setActiveTab={setActiveInProgressTab}
                                component={displaySyncProgressTab()}
                                className="!px-0"
                                itemClassName="!pb-2"
                                borderLineClase={"!text-slate-100 w-full max-lg:w-full"}
                            />
                        </div>
                        <div className="px-6 space-y-3">
                            <div className="flex space-x-3">
                                <i className="ri-window-2-line -mt-1 text-2xl text-gm-50"></i>
                                <div>
                                    <p className="text-g-75 mb-1 text-base font-rocGroteskMedium">
                                        Optional to keep this window open
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        This process might take a few moments.You can close this
                                        modal and continue using the platform. We will notify you
                                        once the sync is complete.
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-3">
                                <i className="ri-check-double-fill -mt-1 text-2xl text-gm-50"></i>
                                <div>
                                    <p className="text-g-75 mb-1 text-base font-rocGroteskMedium">
                                        Data Accuracy
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Ensure that your product data from all platforms is
                                        up-to-date to reflect accurate information in Synth.
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-3">
                                <i className="ri-customer-service-line -mt-1 text-2xl text-gm-50"></i>
                                <div>
                                    <p className="text-g-75 mb-1 text-base font-rocGroteskMedium">
                                        Need Help?
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        If you encounter any issues, please contact our support
                                        team:{" "}
                                        <span className="text-[#CB9300] underline">
                                            hello@heysynth.com
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-end space-x-3 px-6   py-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!py-3  !w-fit !px-4"
                                onClick={() => {
                                    setShowProductAndSalesSyncInProgress(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={productSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-[21px] text-white"></i>
                                </div>
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    All Product Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your products have been successfully updated and added to Synth.
                                    Please proceed to view and select products to add to your
                                    inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Remind me later"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setProductSyncCompletedModal(false);
                                }}
                            />
                            <Button
                                btnText={"Select products and import "}
                                btnPermission={productPermissions}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // const inventoryVisits = localStorage.getItem("inventoryVisits");
                                    // if (!inventoryVisits) {
                                    //     localStorage.setItem("inventoryVisits", String(1));
                                    // }
                                    setProductSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`, {
                                        state: { internalNavigation: true },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={shopifyProductSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setShopifyProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_u7frin.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="shopify_logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Shopify Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Shopify products have been successfully updated and added
                                    to Synth. Please proceed to view and select products to add to
                                    your inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Remind me later"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const shopifySuccessModalCount = sessionStorage.getItem(
                                        "shopifySuccessModalCount"
                                    );
                                    if (!shopifySuccessModalCount) {
                                        sessionStorage.setItem(
                                            "shopifySuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setShopifyProductSyncCompletedModal(false);
                                }}
                            />
                            <Button
                                btnText={"Select products and import"}
                                btnPermission={productPermissions}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const shopifySuccessModalCount = sessionStorage.getItem(
                                        "shopifySuccessModalCount"
                                    );
                                    if (!shopifySuccessModalCount) {
                                        sessionStorage.setItem(
                                            "shopifySuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setShopifyProductSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`, {
                                        state: { internalNavigation: true },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={amazonProductSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setAmazonProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/amazon_1_aeftdf.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="amazon_logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Amazon Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Amazon products have been successfully updated and added to
                                    Synth. Please proceed to view and select products to add to your
                                    inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Remind me later"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const amazonSuccessModalCount =
                                        sessionStorage.getItem("amazonSuccessModalCount");
                                    if (!amazonSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "amazonSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setAmazonProductSyncCompletedModal(false);
                                }}
                            />
                            <Button
                                btnText={"Select products and import"}
                                btnPermission={productPermissions}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const amazonSuccessModalCount =
                                        sessionStorage.getItem("amazonSuccessModalCount");
                                    if (!amazonSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "amazonSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setAmazonProductSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`, {
                                        state: { internalNavigation: true },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={quickbooksProductSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setQuickbooksProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Intuit_QuickBooks_logo_2_nhzc9h.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="quickbooks_logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Quickbooks Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Quickbooks products have been successfully updated and
                                    added to Synth. Please proceed to view and select products to
                                    add to your inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Remind me later"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const quickbooksSuccessModalCount = sessionStorage.getItem(
                                        "quickbooksSuccessModalCount"
                                    );
                                    if (!quickbooksSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "quickbooksSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setQuickbooksProductSyncCompletedModal(false);
                                }}
                            />
                            <Button
                                btnText={"Select products and import"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const quickbooksSuccessModalCount = sessionStorage.getItem(
                                        "quickbooksSuccessModalCount"
                                    );
                                    if (!quickbooksSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "quickbooksSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setQuickbooksProductSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`, {
                                        state: { internalNavigation: true },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={qbDesktopProductSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setQbDesktopProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Intuit_QuickBooks_logo_2_nhzc9h.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="quickbooks-desktop logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Quickbooks Desktop Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Quickbooks Desktop products have been successfully updated
                                    and added to Synth. Please proceed to view and select products
                                    to add to your inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Remind me later"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const qbDesktopSuccessModalCount = sessionStorage.getItem(
                                        "qbDesktopSuccessModalCount"
                                    );
                                    if (!qbDesktopSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "qbDesktopSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setQbDesktopProductSyncCompletedModal(false);
                                }}
                            />
                            <Button
                                btnText={"Select products and import"}
                                btnPermission={productPermissions}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const qbDesktopSuccessModalCount = sessionStorage.getItem(
                                        "qbDesktopSuccessModalCount"
                                    );
                                    if (!qbDesktopSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "qbDesktopSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setQbDesktopProductSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`, {
                                        state: { internalNavigation: true },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={klaviyoProductSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setKlaviyoProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp"
                                    className="w-[56px] h-[56px]"
                                    alt="klaviyo logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Klaviyo Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Klaviyo sync has been completed successfully and data added
                                    to Synth.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const klaviyoSuccessModalCount = sessionStorage.getItem(
                                        "klaviyoSuccessModalCount"
                                    );
                                    if (!klaviyoSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "klaviyoSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setKlaviyoProductSyncCompletedModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={googleAdsProductSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setGoogleAdsProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp"
                                    className="w-[56px] h-[56px]"
                                    alt="klaviyo logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Google Ads Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Google Ads sync has been completed successfully and data
                                    added to Synth.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const googleAdsSuccessModalCount = sessionStorage.getItem(
                                        "googleAdsSuccessModalCount"
                                    );
                                    if (!googleAdsSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "googleAdsSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setGoogleAdsProductSyncCompletedModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={tiktokProductSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setTiktokProductSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/tiktok_logo.webp"
                                    className="w-[56px] h-[56px]"
                                    alt="tiktok logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Tiktok Ads Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Tiktok Ads sync has been completed successfully and data
                                    added to Synth.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    const tiktokSuccessModalCount =
                                        sessionStorage.getItem("tiktokSuccessModalCount");
                                    if (!tiktokSuccessModalCount) {
                                        sessionStorage.setItem(
                                            "tiktokSuccessModalCount",
                                            String(1)
                                        );
                                    }
                                    setTiktokProductSyncCompletedModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            {/*  filter trackstarProductSyncCompletedModal to get trackstar integrations  with true modal */}
            {trackstarProductSyncCompletedModal
                ?.filter((eachModal) => eachModal?.modal)
                ?.map((item, index) => {
                    const getIntegrationDetails = trackstarList?.find(
                        (list) => list?.trackstarIntegration === item?.integrationName
                    );
                    const intModalIndex = trackstarProductSyncCompletedModal?.findIndex(
                        (modal) => modal?.integrationName === item?.integrationName
                    );
                    const findIntProgress = trackstarSyncProgress?.findIndex(
                        (int) => int?.integrationName === item?.integrationName
                    );

                    if (item?.modal === true) {
                        return (
                            <ModalContainer
                                key={index}
                                open={
                                    trackstarProductSyncCompletedModal[intModalIndex]?.modal &&
                                    !profile?.isMultiBrandAccount
                                }
                                modalClassName={"py-10 px-10"}
                                tailwindClassName="w-[600px]"
                                showCloseIcon={false}
                                // closeModal={() => {
                                //     setTrackstarProductSyncCompletedModal((prev) =>
                                //         prev.map((modal, idx) =>
                                //             idx === intModalIndex
                                //                 ? { ...modal, modal: false }
                                //                 : modal
                                //         )
                                //     );
                                // }}
                            >
                                <div className="bg-white rounded-[8px] relative">
                                    <div className="mt-4 ">
                                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                            <div className="mb-4">
                                                <img
                                                    src={getIntegrationDetails?.imageSrc}
                                                    className="w-[56px] h-[56px]"
                                                    alt={getIntegrationDetails?.title}
                                                />
                                            </div>
                                            <div className=" font-rocGroteskMedium">
                                                <span className="block mb-2 text-center text-[22px]">
                                                    {getIntegrationDetails?.title} Sync Complete!
                                                </span>
                                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                                    Your {getIntegrationDetails?.title} products
                                                    have been successfully updated and added to
                                                    Synth. Please proceed to view and select
                                                    products to add to your inventory.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                            <Button
                                                btnText={"Remind me later"}
                                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                                onClick={() =>
                                                    handleTrackStarProductModalCount(
                                                        getIntegrationDetails,
                                                        intModalIndex
                                                    )
                                                }
                                            />
                                            <Button
                                                btnText={"Select products and import"}
                                                btnPermission={productPermissions}
                                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                                onClick={() => {
                                                    handleTrackStarProductModalCount(
                                                        getIntegrationDetails,
                                                        intModalIndex
                                                    );
                                                    sessionStorage.setItem(
                                                        "trackSync",
                                                        getIntegrationDetails?.trackstarIntegration
                                                    );
                                                    navigate(
                                                        `/dashboard/inventory/import-products`,
                                                        {
                                                            state: { internalNavigation: true },
                                                        }
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ModalContainer>
                        );
                    }
                })}
            {/* product sync completed end */}
            {/* sales data sync completed modal start */}
            <ModalContainer
                open={salesSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setSalesSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-[21px] text-white"></i>
                                </div>
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    All Sales Data Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your Sales Data Sync have been successfully updated and added to
                                    Synth. Please proceed to view and select products to add to your
                                    inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setSalesSyncCompletedModal(false);
                                }}
                            />
                            {/* <Button
                                btnText={"Select products and import "}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // const inventoryVisits = localStorage.getItem("inventoryVisits");
                                    // if (!inventoryVisits) {
                                    //     localStorage.setItem("inventoryVisits", String(1));
                                    // }
                                    setSalesSyncCompletedModal(false);
                                    // navigate(`/dashboard/inventory/import-products`);
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={shopifySalesSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setShopifySalesSyncCompletedModal(false);
                    setShopifySalesSyncProgress(undefined);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_u7frin.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="shopify_logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Shopify Sales Data Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your sales data from Shopify has been successfully synced to
                                    Synth. You can now review the data for accuracy. We'll notify
                                    you when all syncs are complete.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setShopifySalesSyncCompletedModal(false);
                                    setShopifySalesSyncProgress(undefined);
                                }}
                            />
                            {/* <Button
                                btnText={"View Sales Data"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // const inventoryVisits = localStorage.getItem("inventoryVisits");
                                    // if (!inventoryVisits) {
                                    //     localStorage.setItem("inventoryVisits", String(1));
                                    // }
                                    setShopifySalesSyncCompletedModal(false);
                                    // navigate(`/dashboard/inventory/import-products`);
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={quickbooksSalesSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setQuickbooksSalesSyncCompletedModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Intuit_QuickBooks_logo_2_nhzc9h.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="quickbooks_logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Quickbooks Sales Data Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your sales data from Quickbook has been successfully synced to
                                    Synth. You can now review the data for accuracy. We'll notify
                                    you when all syncs are complete.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setQuickbooksSalesSyncCompletedModal(false);
                                }}
                            />
                            {/* <Button
                                btnText={"View Sales Data"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // const inventoryVisits = localStorage.getItem("inventoryVisits");
                                    // if (!inventoryVisits) {
                                    //     localStorage.setItem("inventoryVisits", String(1));
                                    // }
                                    setQuickbooksSalesSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`);
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={qbDesktopSalesSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setQbDesktopSalesSyncCompletedModal(false);
                    setQbDesktopSalesSyncProgress(undefined);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Intuit_QuickBooks_logo_2_nhzc9h.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="quickbooks-desktop logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Quickbooks Desktop Sales Data Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your sales data from Quickbooks Desktop has been successfully
                                    synced to Synth. You can now review the data for accuracy. We'll
                                    notify you when all syncs are complete.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setQbDesktopSalesSyncCompletedModal(false);
                                    setQbDesktopSalesSyncProgress(undefined);
                                }}
                            />
                            {/* <Button
                                btnText={"View Sales Data"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // const inventoryVisits = localStorage.getItem("inventoryVisits");
                                    // if (!inventoryVisits) {
                                    //     localStorage.setItem("inventoryVisits", String(1));
                                    // }
                                    setQbDesktopSalesSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`);
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
            </ModalContainer>
            {/* {trackstarSalesSyncCompletedModal
                ?.filter((eachModal) => eachModal?.modal)
                ?.forEach((item) => {
                    const getIntegrationDetails = trackstarList?.find(
                        (list) => list?.trackstarIntegration === item?.integrationName
                    );
                    const intModalIndex = trackstarSalesSyncCompletedModal?.findIndex(
                        (modal) => modal?.integrationName === item?.integrationName
                    );
                    const findIntProgress = trackstarSalesSyncProgress?.findIndex(
                        (int) => int?.integrationName === item?.integrationName
                    );
                    if (item?.modal === true)
                        return (
                            <ModalContainer
                                open={
                                    trackstarSalesSyncCompletedModal?.find((item) => item?.modal) &&
                                    !profile?.isMultiBrandAccount
                                }
                                modalClassName={"py-10 px-10"}
                                tailwindClassName="w-[600px]"
                                showCloseIcon={false}
                                closeModal={() => {
                                    setTrackstarSalesSyncCompletedModal[intModalIndex](false);

                                    setTrackstarSalesSyncProgress[findIntProgress]?.percentage(
                                        undefined
                                    );
                                }}
                            >
                                <div className="bg-white rounded-[8px] relative">
                                    <div className="mt-4 ">
                                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                            <div className="mb-4">
                                                {
                                                    <img
                                                        src={getIntegrationDetails?.imageSrc}
                                                        className="w-[56px] h-[56px]"
                                                        alt="amazon_logo"
                                                    />
                                                }
                                            </div>
                                            <div className=" font-rocGroteskMedium">
                                                <span className="block mb-2 text-center text-[22px]">
                                                    {getIntegrationDetails?.title} Data Sync
                                                    Complete!
                                                </span>
                                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                                    Your sales data from{" "}
                                                    {getIntegrationDetails?.title} has been
                                                    successfully synced to Synth. You can now review
                                                    the data for accuracy. We'll notify you when all
                                                    syncs are complete.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                            <Button
                                                btnText={"Close"}
                                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                                onClick={() => {
                                                    setTrackstarSalesSyncCompletedModal[intModalIndex](
                                                        false
                                                    );

                                                    setTrackstarSalesSyncProgress[
                                                        findIntProgress
                                                    ]?.percentage(undefined);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ModalContainer>
                        );
                })} */}
            {trackstarSalesSyncCompletedModal
                ?.filter((eachModal) => eachModal?.modal === true)
                ?.map((item, index) => {
                    const getIntegrationDetails = trackstarList?.find(
                        (list) => list?.trackstarIntegration === item?.integrationName
                    );
                    const intModalIndex = trackstarSalesSyncCompletedModal?.findIndex(
                        (modal) => modal?.integrationName === item?.integrationName
                    );
                    const findIntProgress = trackstarSalesSyncProgress?.findIndex(
                        (int) => int?.integrationName === item?.integrationName
                    );

                    // if (item?.modal === true) {
                    return (
                        <ModalContainer
                            key={index}
                            open={
                                trackstarSalesSyncCompletedModal[intModalIndex]?.modal &&
                                !profile?.isMultiBrandAccount
                            }
                            modalClassName={"py-10 px-10"}
                            tailwindClassName="w-[600px]"
                            showCloseIcon={false}
                            closeModal={() => {
                                setTrackstarSalesSyncCompletedModal((prev) =>
                                    prev.map((modal, idx) =>
                                        idx === intModalIndex ? { ...modal, modal: false } : modal
                                    )
                                );

                                setTrackstarSalesSyncProgress((prev) =>
                                    prev.map((syncProgress, idx) =>
                                        idx === findIntProgress
                                            ? { ...syncProgress, percentage: undefined }
                                            : syncProgress
                                    )
                                );
                            }}
                        >
                            <div className="bg-white rounded-[8px] relative">
                                <div className="mt-4 ">
                                    <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                        <div className="mb-4">
                                            {getIntegrationDetails?.imageSrc && (
                                                <img
                                                    src={getIntegrationDetails?.imageSrc}
                                                    className="w-[56px] h-[56px]"
                                                    alt="amazon_logo"
                                                />
                                            )}
                                        </div>
                                        <div className=" font-rocGroteskMedium">
                                            <span className="block mb-2 text-center text-[22px]">
                                                {getIntegrationDetails?.title} Sales Data Sync
                                                Complete!
                                            </span>
                                            <p className="mt-1 text-base text-slate-500 text-center px-6">
                                                Your sales data from {getIntegrationDetails?.title}{" "}
                                                has been successfully synced to Synth. You can now
                                                review the data for accuracy. We'll notify you when
                                                all syncs are complete.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                        <Button
                                            btnText={"Close"}
                                            btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                            onClick={() => {
                                                setTrackstarSalesSyncCompletedModal((prev) =>
                                                    prev.map((modal, idx) =>
                                                        idx === intModalIndex
                                                            ? { ...modal, modal: false }
                                                            : modal
                                                    )
                                                );

                                                setTrackstarSalesSyncProgress((prev) =>
                                                    prev.map((syncProgress, idx) =>
                                                        idx === findIntProgress
                                                            ? {
                                                                  ...syncProgress,
                                                                  percentage: undefined,
                                                              }
                                                            : syncProgress
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ModalContainer>
                    );
                    // }
                })}
            <ModalContainer
                open={amazonSalesSyncCompletedModal && !profile?.isMultiBrandAccount}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setAmazonSalesSyncCompletedModal(false);
                    setAmazonSalesSyncProgress(undefined);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/amazon_1_aeftdf.svg"
                                    className="w-[56px] h-[56px]"
                                    alt="amazon_logo"
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Amazon Sales Data Sync Complete!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center px-6">
                                    Your sales data from Amazon has been successfully synced to
                                    Synth. You can now review the data for accuracy. We'll notify
                                    you when all syncs are complete.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setAmazonSalesSyncCompletedModal(false);
                                    setAmazonSalesSyncProgress(undefined);
                                }}
                            />
                            {/* <Button
                                btnText={"View Sales Data"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // const inventoryVisits = localStorage.getItem("inventoryVisits");
                                    // if (!inventoryVisits) {
                                    //     localStorage.setItem("inventoryVisits", String(1));
                                    // }
                                    setAmazonSalesSyncCompletedModal(false);
                                    navigate(`/dashboard/inventory/import-products`);
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
            </ModalContainer>
            {/* sales data sync completed modal end */}
            <ModalContainer
                open={showCreditPurchase}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[41.6%] max-lg:w-[95%]"
                closeModal={() => {
                    setShowCreditPurchase(false);
                }}
            >
                <div
                    className={` mt-10 py-6 px-8 bg-[white] rounded-lg shadow-faintShadow font-rocGroteskMedium  text-gm-50`}
                >
                    <p className="text-base mb-8">Your business AI credit balance</p>
                    <div className="p-6 border mb-6 border-border-light shadow-sm rounded-md">
                        <p className="text-4xl font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                            {fetchCreditHistorySuccess?.creditBalance}
                        </p>
                        <p className="text-sm">
                            AI credit usage ({fetchCreditHistorySuccess?.percentageCreditUsed}
                            %)
                        </p>
                        <div className="mt-2 mb-6">
                            <ProgressBar
                                className={" !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"}
                                progress={fetchCreditHistorySuccess?.percentageCreditUsed}
                            />
                        </div>
                        <div className="w-fit ">
                            <Button
                                btnClassname={
                                    "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                }
                                onClick={() => {
                                    setPurchaseCreditModal(true);
                                    setShowCreditPurchase(false);
                                }}
                                btnText={"Purchase more credit"}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-lg">Usage history</p>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            Here is the list of items you have utilized your AI Credit for up to
                            this point.
                        </p>
                        {!fetchingCreditHistory ? (
                            <div className="mb-8">
                                {creditUsageHistory && creditUsageHistory.length > 0 && (
                                    <>
                                        {creditUsageHistory.slice(0, -1).map((history) => (
                                            <div className="py-3 border-slate-200 border-b flex justify-between text-gm-50 text-sm">
                                                <p>{transformString(history?.action)}</p>
                                                <p>{history?.totalAmount} used</p>
                                            </div>
                                        ))}
                                        <div className="py-3 flex justify-between text-gm-50 text-sm">
                                            <p>
                                                {transformString(
                                                    creditUsageHistory[
                                                        creditUsageHistory.length - 1
                                                    ]?.action
                                                )}
                                            </p>
                                            <p>
                                                {
                                                    creditUsageHistory[
                                                        creditUsageHistory.length - 1
                                                    ]?.totalAmount
                                                }{" "}
                                                used
                                            </p>
                                        </div>
                                    </>
                                )}
                                {creditUsageHistory.length === 0 && (
                                    <div className="h-[200px] flex space-y-6 flex-col items-center justify-center">
                                        <i className="ri-sparkling-fill text-3xl text-[#C1C7D0]"></i>
                                        <h1 className="text-center w-[70%] text-sm text-[#6B778C]">
                                            Your AI usage is currently empty. Your AI usage for this
                                            task will be displayed here once you start using it.
                                        </h1>
                                    </div>
                                )}
                                <div className="pt-4 pb-1 font-rocGroteskMedium flex justify-between text-slate-500 text-sm">
                                    <p>{"Total AI Credits bought this month"}</p>
                                    <p>{fetchCreditHistorySuccess?.creditBalance} Credits</p>
                                </div>
                            </div>
                        ) : (
                            <Loader />
                        )}
                        <div className="mb-2">
                            <Button
                                btnText={"Close"}
                                onClick={() => setShowCreditPurchase(false)}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={purchaseCreditModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[600px] w-[41.6%] max-lg:w-[95%]"
                closeModal={() => {
                    setPurchaseCreditModal(false);
                }}
            >
                <div className="bg-white p-8  shadow-cardShadow relative rounded-[8px]">
                    <h1 className="text-2xl font-rocGroteskMedium">
                        Get more done with SynthAlly AI
                    </h1>
                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                        Go unlimited with SynthAlly AI for all members in your organization
                        workspace.
                    </p>
                    <div className="my-8">
                        <div className="text-sm mb-3 font-rocGroteskMedium flex items-center justify-between">
                            <p className="text-gm-50">Amount of credit</p>
                            <p className=" font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                $2 Per 1 credit
                            </p>
                        </div>
                        <div className="flex space-x-2">
                            <div className="w-[80%]">
                                <TextInput
                                    value={credit as string}
                                    floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                    type={"text"}
                                    name="noOfCredit"
                                    inputContainerClassname={""}
                                    onChange={(e) => {
                                        if (!isNaN(Number(e.target.value))) {
                                            setCredit(e.target.value);
                                        }
                                    }}
                                    required={true}
                                />
                            </div>
                            <div className="flex space-x-2">
                                <div
                                    className="border cursor-pointer flex justify-center items-center border-slate-200 p-3 h-12 w-12 rounded "
                                    onClick={() => {
                                        if (Number(credit) > 0) {
                                            setCredit(Number(credit) - 1);
                                        }
                                    }}
                                >
                                    <i className="ri-subtract-line text-slate-500"></i>
                                </div>
                                <div
                                    onClick={() => setCredit(Number(credit) + 1)}
                                    className="border cursor-pointer flex justify-center items-center border-slate-200 p-3 h-12 w-12 rounded "
                                >
                                    <i className="ri-add-line text-slate-500"></i>
                                </div>
                            </div>
                        </div>
                        <div className="text-sm mt-5 font-rocGroteskMedium ">
                            <p className="text-gm-50">Order summary</p>
                            <div className="text-sm py-3 font-rocGroteskMedium flex items-center justify-between">
                                <p className="text-slate-500">Amount of credit</p>
                                <p className="text-slate-500">${Number(credit) * 2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <Button
                            btnText={"Close"}
                            type={"button"}
                            btnClassname={"!bg-n-20 !text-gm-50"}
                            isLoading={false}
                            disabled={fetchStripeSession}
                            onClick={() => {
                                setPurchaseCreditModal(false);
                                navigate("/dashboard/settings/2", {
                                    state: { internalNavigation: true },
                                });
                            }}
                        />
                        <Button
                            btnClassname={
                                "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                            }
                            onClick={OnSubmitPurchase}
                            btnText={"Purchase more credit"}
                            type={"button"}
                            isLoading={fetchStripeSession}
                        />
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={purchaseCreditFailure}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[34.7%] max-lg:w-[95%]"
                closeModal={() => {
                    setPurchaseCreditFailure(false);
                    navigate("/dashboard/settings/2", { state: { internalNavigation: true } });
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[12px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-14 h-14 flex justify-center items-center bg-r-50 shadow-icon-red rounded-full">
                                <i className="ri-close-fill text-[29px] text-white "></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Payment Failed
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    We've detected a problem with your recent payment. To resolve
                                    this issue and continue using our services, please update your
                                    payment information or reach out to our support team for
                                    assistance."
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex space-x-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setPurchaseCreditFailure(false);
                                    navigate("/dashboard/settings/2", {
                                        state: { internalNavigation: true },
                                    });
                                }}
                            />
                            <Button
                                onClick={() => OnSubmitPurchase()}
                                btnText={"Retry"}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={purchaseCreditLimit}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[34.7%] max-lg:w-[95%]"
                closeModal={() => {
                    setPurchaseCreditLimit(false);
                }}
            >
                <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-1">
                            <p className=" font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                <i className="ri-sparkling-2-fill text-[53px]"></i>
                            </p>
                            <div>
                                <h3 className="text-2xl text-black font-rocGroteskMedium">
                                    AI Credit Limit Reached
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    You've reached your AI credit limit for this feature. To
                                    continue, please consider upgrading your plan or managing your
                                    AI usage in your account settings. If you have any questions,
                                    feel free to contact our support team."
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 flex space-x-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => setPurchaseCreditLimit(false)}
                            />
                            <Button
                                btnClassname={
                                    "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                }
                                onClick={() => {
                                    setPurchaseCreditLimit(false);
                                    setPurchaseCreditModal(true);
                                }}
                                btnText={"Purchase more credit"}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default DashboardNav;
