import CustomToast from "components/common/CustomToast";

import { toast } from "react-hot-toast";
import { workforceTypes } from "../types";
import { workforceService } from "services/Ldb";
import { Dispatch } from "redux";

export const workforceActions = {
    getWorkforceActions,
    getAiTeammates,
    getSingleAiTeammate,
    resetGetSingleAiTeammateSuccess,
    createAiTeammate,
    resetCreateAiTeammateSuccess,
    listTasks,
    getSingleTask,
    resetGetSingleTaskSuccess,
    updateTaskSalesOrder,
    resetUpdateTaskSalesOrderSuccess,
    approveTaskStep,
    resetApproveTaskStepSuccess,
    retryTaskStep,
    resetRetryTaskStepSuccess,
    giveTaskStepFeedback,
    resetGiveeTaskStepFeedbackSuccess,
    getTaskAnalytics,
    updateAiTeammate,
    resetUpdateAiTeammateSuccess,
    testAiAgent,
    resetTestAiAgentSuccess,
    getSingleAiAgentTasks,
    getSingleAiAgentTaskStats,
    resetGetSingleAiAgentTasksSuccess,
    resetGetSingleAiAgentTaskStatsSuccess,
    createCampaignComment,
    resetCreateCompaignCommentSuccess,
    updateCampaignComment,
    resetUpdateCompaignCommentSuccess,
    getCampaignComments,
    resetGetCompaignCommentSuccess,
    getCampaignSectionComments,
    resetGetCampaignSectionCommentsSuccess,
    getCampaignCommentReplies,
    resetGetCampaignCommentRepliesSuccess,
    getSingleCampaign,
    resetGetSingleCampaignSuccess,
    getCampaignErrors,
    resetGetCampaignErrorsSuccess,
    correctCampaignError,
    resetCorrectCampaignErrorSuccess,
    getAiNotifications,
    resetGetAiNotificationsSuccess,
    createSpsCommerceConnection,
    resetCreateSpsCommerceConnectionSuccess,
    markAllAiNotificationsAsRead,
    resetMarkAllAiNotificationsAsReadSuccess,
};

function getWorkforceActions() {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_AI_AGENTS_REQUEST));

        workforceService.getAiAgents().then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_AI_AGENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_AI_AGENTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getAiTeammates(page: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_AI_TEAMMATES_REQUEST));

        workforceService.getAiTeammates(page, search).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_AI_TEAMMATES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_AI_TEAMMATES_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleAiTeammate(aiTeammateId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_REQUEST));

        workforceService.getSingleAiTeammate(aiTeammateId).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createAiTeammate(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.CREATE_AI_TEAMMATE_REQUEST));

        workforceService.createAiTeammate(data).then(
            (res) => {
                dispatch(success(workforceTypes.CREATE_AI_TEAMMATE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.CREATE_AI_TEAMMATE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateAiTeammate(
    data: Record<string, any> | Array<Record<string, any>>,
    aiTeammateId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.UPDATE_AI_TEAMMATE_REQUEST));

        workforceService.updateAiTeammate(data, aiTeammateId).then(
            (res) => {
                dispatch(success(workforceTypes.UPDATE_AI_TEAMMATE_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Update Successful"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.UPDATE_AI_TEAMMATE_FAILURE, error?.message));
                }
            }
        );
    };
}

function listTasks(
    page?: string | number,
    search?: string,
    taskStatus?: string,
    assignedTo?: string,
    taskType?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_TASK_LIST_REQUEST));

        workforceService.getTaskList(page, search, taskStatus, assignedTo, taskType).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleTask(taskId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_SINGLE_TASK_REQUEST));

        workforceService.getSingleTask(taskId).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_SINGLE_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(failure(workforceTypes.FETCH_SINGLE_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateTaskSalesOrder(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.UPDATE_TASK_SALES_ORDER_REQUEST));

        workforceService.updateTaskSalesOrder(data).then(
            (res) => {
                dispatch(success(workforceTypes.UPDATE_TASK_SALES_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.UPDATE_TASK_SALES_ORDER_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function approveTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.APPROVE_TASK_STEP_REQUEST));

        workforceService.approveTaskStep(data).then(
            (res) => {
                dispatch(success(workforceTypes.APPROVE_TASK_STEP_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.APPROVE_TASK_STEP_FAILURE, error?.message));
                }
            }
        );
    };
}

function retryTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.RETRY_TASK_STEP_REQUEST));

        workforceService.retryTaskStep(data).then(
            (res) => {
                dispatch(success(workforceTypes.RETRY_TASK_STEP_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.RETRY_TASK_STEP_FAILURE, error?.message));
                }
            }
        );
    };
}

function giveTaskStepFeedback(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GIVE_TASK_STEP_FEEDBACK_REQUEST));

        workforceService.giveTaskStepFeedback(data).then(
            (res) => {
                dispatch(success(workforceTypes.GIVE_TASK_STEP_FEEDBACK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.GIVE_TASK_STEP_FEEDBACK_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getTaskAnalytics() {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_TASK_ANALYTICS_REQUEST));

        workforceService.getTaskAnalytics().then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_TASK_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_TASK_ANALYTICS_FAILURE, error?.message));
                }
            }
        );
    };
}

function testAiAgent(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.TEST_AI_AGENT_REQUEST));

        workforceService.testAiAgent(data).then(
            (res) => {
                dispatch(success(workforceTypes.TEST_AI_AGENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.TEST_AI_AGENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleAiAgentTasks(
    testId?: string,
    page?: string | number,
    search?: string,
    status?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_SINGLE_AI_AGENT_TASKS_REQUEST));

        workforceService.getSingleAiAgentTasks(testId, page, search, status).then(
            (res) => {
                dispatch(success(workforceTypes.GET_SINGLE_AI_AGENT_TASKS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.GET_SINGLE_AI_AGENT_TASKS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getSingleAiAgentTaskStats(
    testId?: string,
    startDate?: string,
    endDate?: string,
    dateRange?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_SINGLE_AI_AGENT_TASK_STATS_REQUEST));

        workforceService.getSingleAiAgentTaskStats(testId, startDate, endDate, dateRange).then(
            (res) => {
                dispatch(success(workforceTypes.GET_SINGLE_AI_AGENT_TASK_STATS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            workforceTypes.GET_SINGLE_AI_AGENT_TASK_STATS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function createCampaignComment(
    campaignId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.CREATE_CAMPAIGN_COMMENT_REQUEST));

        workforceService.createCampaignComment(campaignId, data).then(
            (res) => {
                dispatch(success(workforceTypes.CREATE_CAMPAIGN_COMMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.CREATE_CAMPAIGN_COMMENT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateCampaignComment(
    campaignId: string,
    commentId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.UPDATE_CAMPAIGN_COMMENT_REQUEST));

        workforceService.updateCampaignComment(campaignId, commentId, data).then(
            (res) => {
                dispatch(success(workforceTypes.UPDATE_CAMPAIGN_COMMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.UPDATE_CAMPAIGN_COMMENT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getCampaignComments(campaignId: string, isResolvedTab?: boolean, isMentionTab?: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_CAMPAIGN_COMMENTS_REQUEST));

        workforceService.getCampaignComments(campaignId, null, isResolvedTab, isMentionTab).then(
            (res) => {
                dispatch(success(workforceTypes.GET_CAMPAIGN_COMMENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.GET_CAMPAIGN_COMMENTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getCampaignSectionComments(
    campaignId: string,
    section?: number,
    isResolvedTab?: boolean,
    isMentionTab?: boolean
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_CAMPAIGN_SECTION_COMMENTS_REQUEST));

        workforceService.getCampaignComments(campaignId, section, isResolvedTab, isMentionTab).then(
            (res) => {
                dispatch(success(workforceTypes.GET_CAMPAIGN_SECTION_COMMENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            workforceTypes.GET_CAMPAIGN_SECTION_COMMENTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getCampaignCommentReplies(campaignId: string, commentId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_CAMPAIGN_COMMENT_REPLY_REQUEST));

        workforceService.getCampaignCommentReplies(campaignId, commentId).then(
            (res) => {
                dispatch(success(workforceTypes.GET_CAMPAIGN_COMMENT_REPLY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.GET_CAMPAIGN_COMMENT_REPLY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createSpsCommerceConnection(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.CREATE_SPS_COMMERCE_CONNECTION_REQUEST));

        workforceService.createSpsCommerceConnection(data).then(
            (res) => {
                dispatch(success(workforceTypes.CREATE_SPS_COMMERCE_CONNECTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            workforceTypes.CREATE_SPS_COMMERCE_CONNECTION_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getSingleCampaign(campaignId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_SINGLE_CAMPAIGN_REQUEST));

        workforceService.getSingleCampaign(campaignId).then(
            (res) => {
                dispatch(success(workforceTypes.GET_SINGLE_CAMPAIGN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.GET_SINGLE_CAMPAIGN_FAILURE, error?.message));
                }
            }
        );
    };
}

function getCampaignErrors() {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_CAMPAIGN_ERRORS_REQUEST));

        workforceService.getCampaignErrors().then(
            (res) => {
                dispatch(success(workforceTypes.GET_CAMPAIGN_ERRORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.GET_CAMPAIGN_ERRORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function correctCampaignError(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.CORRECT_CAMPAIGN_ERROR_REQUEST));

        workforceService.correctCampaignError(data).then(
            (res) => {
                dispatch(success(workforceTypes.CORRECT_CAMPAIGN_ERROR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.CORRECT_CAMPAIGN_ERROR_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getAiNotifications(page?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GET_AI_NOTIFICATIONS_REQUEST));

        workforceService.getAiNotifications(page, search).then(
            (res) => {
                dispatch(success(workforceTypes.GET_AI_NOTIFICATIONS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.GET_AI_NOTIFICATIONS_FAILURE, error?.message));
                }
            }
        );
    };
}

function markAllAiNotificationsAsRead(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.MARK_ALL_AI_NOTIFICATIONS_AS_READ_REQUEST));

        workforceService.markAllAiNotificationsAsRead(data).then(
            (res) => {
                dispatch(
                    success(workforceTypes.MARK_ALL_AI_NOTIFICATIONS_AS_READ_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            workforceTypes.MARK_ALL_AI_NOTIFICATIONS_AS_READ_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function resetMarkAllAiNotificationsAsReadSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.MARK_ALL_AI_NOTIFICATIONS_AS_READ_SUCCESS, null));
    };
}

function resetGetAiNotificationsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_AI_NOTIFICATIONS_SUCCESS, null));
    };
}

function resetCreateSpsCommerceConnectionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.CREATE_SPS_COMMERCE_CONNECTION_SUCCESS, null));
    };
}

function resetCorrectCampaignErrorSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.CORRECT_CAMPAIGN_ERROR_SUCCESS, null));
    };
}

function resetGetCampaignErrorsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_CAMPAIGN_ERRORS_SUCCESS, null));
    };
}

function resetGetSingleCampaignSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_SINGLE_CAMPAIGN_SUCCESS, null));
    };
}

function resetGetCampaignCommentRepliesSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_CAMPAIGN_COMMENT_REPLY_SUCCESS, null));
    };
}

function resetGetCampaignSectionCommentsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_CAMPAIGN_SECTION_COMMENTS_SUCCESS, null));
    };
}

function resetCreateCompaignCommentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.CREATE_CAMPAIGN_COMMENT_SUCCESS, null));
    };
}

function resetUpdateCompaignCommentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.UPDATE_CAMPAIGN_COMMENT_SUCCESS, null));
    };
}

function resetGetCompaignCommentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_CAMPAIGN_COMMENTS_SUCCESS, null));
    };
}

function resetGetSingleAiAgentTasksSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_SINGLE_AI_AGENT_TASKS_SUCCESS, null));
    };
}

function resetGetSingleAiAgentTaskStatsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GET_SINGLE_AI_AGENT_TASK_STATS_SUCCESS, null));
    };
}

function resetTestAiAgentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.TEST_AI_AGENT_SUCCESS, null));
    };
}

function resetGetSingleAiTeammateSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_SUCCESS, null));
    };
}

function resetGetSingleTaskSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.FETCH_SINGLE_TASK_SUCCESS, null));
    };
}

function resetUpdateAiTeammateSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.UPDATE_AI_TEAMMATE_SUCCESS, null));
    };
}

function resetGiveeTaskStepFeedbackSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GIVE_TASK_STEP_FEEDBACK_SUCCESS, null));
    };
}

function resetRetryTaskStepSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.RETRY_TASK_STEP_SUCCESS, null));
    };
}

function resetApproveTaskStepSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.APPROVE_TASK_STEP_SUCCESS, null));
    };
}

function resetUpdateTaskSalesOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.UPDATE_TASK_SALES_ORDER_SUCCESS, null));
    };
}

function resetCreateAiTeammateSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.CREATE_AI_TEAMMATE_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}

function success(type, data) {
    return { type: type, payload: data };
}

function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
