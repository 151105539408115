import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

const useGetDashboardGWM = () => {
    const dispatch = useAppDispatch();
    const [gwmData, setGwmData] = useState<{ [key: string]: any }>({});
    const [productIds, setProductId] = useState<string[]>([]);
    const [channel, setChannel] = useState<string>("");
    const [category, setCategory] = useState<string>("Finished Goods");
    const [dateRange, setDateRange] = useState<any>("yearly");
    const [endDate, setEndDate] = useState<string>("");
    const [startDate, setStartDate] = useState<any>("");

    const handleCategoryChange = (value) => {
        setCategory(value);
    };

    const handleChannelChange = (value) => {
        setChannel(value);
    };
    const handleProductChange = (value) => {
        setProductId(value);
    };

    const handleDateRangeChange = (period) => {
        if (period !== "yearDropdown") {
            setStartDate("");
            setEndDate("");
        }
        setDateRange(period);
    };
    const handleStartAndEndDateChange = (customDate) => {
        setStartDate(customDate[0]);
        setEndDate(customDate[1]);
        setDateRange("custom");
    };
    const {
        fetchingDashboardGWM,
        fetchedDashboardGWMSuccess,
        fetchedDashboardGWMFailure,
        addForecastConfigSuccess,
    } = useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedDashboardGWMSuccess)) {
            return setGwmData(fetchedDashboardGWMSuccess);
        }
    }, [fetchedDashboardGWMSuccess]);

    useEffect(() => {
        dispatch(
            dashboardActions?.getDashboardGWM(
                category,
                productIds?.join(","),
                channel,
                startDate,
                endDate,
                dateRange
            )
        );
    }, [
        dispatch,
        category,
        productIds,
        channel,
        startDate,
        endDate,
        dateRange,
        addForecastConfigSuccess,
    ]);

    return {
        data: gwmData,
        isFetching: fetchingDashboardGWM,
        error: fetchedDashboardGWMFailure,
        handleCategoryChange,
        handleProductChange,
        handleDateRangeChange,
        handleChannelChange,
        handleStartAndEndDateChange,
        productIds,
    };
};

export default useGetDashboardGWM;
